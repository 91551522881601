import { IDefaultOffer } from 'lib/defaultOffers'
import { ActionsHeader } from '../../components/ActionsHeader'
import { OfferItem } from './OfferItem'
import {
  handleDeviceOfferClick,
  offerStatus,
  offerTypes,
  toggleDeviceOffer
} from 'store/callsession/callsessionSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/ConfigureStore'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { InstructionsRow } from './InstructionsRow'
import { Box } from '@mui/system'

interface DeviceOffersProps {
  generalInstruction?: string
  bulletInstructions: string[]

  /* eslint-disable-next-line no-unused-vars */
  onCheckOffer: (currentOffer: IDefaultOffer) => void
}

export const DeviceOffers: React.FC<DeviceOffersProps> = ({
  generalInstruction,
  bulletInstructions,
  onCheckOffer
}: DeviceOffersProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('offers')

  const deviceOffers = useAppSelector(
    (state) => state.agentCallSession.callsession_device_offers
  )
  if (!deviceOffers.length) return <></>

  const canDisplayOffers = deviceOffers.find((offer) => !!offer.isDisplayed)
  if (!canDisplayOffers) return <></>

  const getColumns = () => {
    return Object.values(offerStatus).map((offer) => t(offer.name))
  }

  const clickOffer = (offer: IDefaultOffer, accepted: boolean): void => {
    const currentOffer = {
      ...offer,
      accepted,
      rejected: !accepted,
      notOffered: false
    }
    onCheckOffer(currentOffer)
  }

  const handleAccept = (offer: IDefaultOffer) => {
    clickOffer(offer, true)
    dispatch(handleDeviceOfferClick({ id: offer.id, btn: 'accepted' }))
  }

  const handleReject = (offer: IDefaultOffer) => {
    clickOffer(offer, false)
    dispatch(handleDeviceOfferClick({ id: offer.id, btn: 'rejected' }))
  }

  const renderOfferItem = (offer: IDefaultOffer, key: number) => {
    return (
      <OfferItem
        key={key}
        id={offer.id}
        idx={key}
        type='deviceOffer'
        disabled={false}
        title={offer.name}
        script={offer.script}
        accepted={offer.accepted}
        rejected={offer.rejected}
        notOffered={offer.notOffered}
        expanded={offer.expanded}
        onAcceptClick={() => handleAccept(offer)}
        onRejectClick={() => handleReject(offer)}
        onNotOfferedClick={() =>
          dispatch(handleDeviceOfferClick({ id: offer.id, btn: 'notOffered' }))
        }
        onToggle={() => dispatch(toggleDeviceOffer(offer.id))}
      />
    )
  }

  return (
    <Box sx={{ textTransform: 'capitalize' }}>
      {deviceOffers.length && (
        <ActionsHeader
          name={t(offerTypes.device.name)}
          columns={getColumns()}
        />
      )}
      <InstructionsRow
        generalInstruction={generalInstruction}
        bulletInstructions={bulletInstructions}
      />
      {deviceOffers?.map((offer, idx) => {
        if (offer.isDisplayed) return renderOfferItem(offer, idx)
      })}
    </Box>
  )
}
