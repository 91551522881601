import React from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'

export interface IDropdownItem {
  id: string | number
  name: string
  label: string
}

interface DropdownProps {
  id: string
  value: string | undefined
  items: IDropdownItem[]
  // eslint-disable-next-line no-unused-vars
  onChange: (event: SelectChangeEvent) => void
  dividerIndex?: number
  error?: boolean
  disabled?: boolean
}

export function Dropdown({
  id,
  value,
  items,
  onChange,
  dividerIndex,
  error,
  disabled
}: Readonly<DropdownProps>) {
  return (
    <Select
      id={id}
      disabled={disabled}
      value={value ?? ''}
      onChange={onChange}
      sx={{ width: '100%', background: 'white', height: '36px' }}
      error={error}
    >
      {items.map((item, index) => (
        <MenuItem
          key={`${id}-${item.id}`}
          value={item.id}
          divider={!!(dividerIndex && dividerIndex === index)}
        >
          {item.label}
        </MenuItem>
      ))}
    </Select>
  )
}
