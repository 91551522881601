import { ICustomerAccountItem } from 'buddy-api/types/customer/CustomerAccountItem'
import { ICustomerServiceBilling } from 'buddy-api/types/customer/CustomerServiceBilling'
import {
  ICallSessionOptOffer,
  IOptOffer
} from 'store/callsession/callsessionSlice'
import { isProductDropped, isYoutubeTVOfferAccepted, roundPrice } from './utils'
import { IProduct } from '../buddy-api/types/Product'
import { ProductNamesEnum } from '../utils/enums'
import { ICallSessionOffer } from 'buddy-api/types/callsession/CallSessionOffer'

const VAS_ITEMS = [
  'Mlti-DvcScrty&BkupBdl50G',
  'CAN 50GB Storage Res',
  'Max Security',
  'Credit Code',
  'Identity Protection',
  'Identity Sec Credit/Debit',
  'Multi-Device Security',
  'Inside Wire Care Bus',
  'Nonpublished Listing',
  'Inside Wire Maintenance-R',
  'Wire Care 1 Yr Term',
  'B/R Non Published Num',
  'Verizon Complete Care',
  'Verizon HomeShield Bundl',
  'Inside Wire Maint w/ Pkg',
  'Verizon Phone Care',
  'FIBR Inside Wire Maint',
  'HSI Security Software-Res',
  'My Premium Tech Pro - Res',
  'Non Listed Service',
  'Verizon Secure Discount'
]

export const INTERNET_ITEMS = ['Whole-Home Wi-Fi', 'eero Secure']

export const VIDEO_ITEMS = [
  'QIP DVR HD',
  'FIBR Rgnl Sports Ntwrk Fe',
  'CT DVR HD',
  'QIP DVR HD',
  'QIP STB SD',
  'Quantum DVR Price',
  'Quantum Enhanced Svc Fee',
  'Quantum STB HD Wireless',
  'VMS DVR Enh Software',
  'Quantum HD STB',
  'FIBR Quantum Whole-Home',
  'Enhanced Experience',
  'DVR Hub',
  'HBO',
  'Showtime',
  'Cinemax',
  'Epix',
  'Quantum Set-Top Box',
  'Quantum HD STB Wired',
  'Quantum STB Client Wired',
  'Quantum STB HD',
  'Quantum DVR',
  'Quantum Whole-Home',
  'Italian (Rai & Mediaset)',
  'Quantum STB Client',
  'QIP DVR Price',
  'QIP Set-Top Box',
  'Multi Room DVR',
  'QIP STB HD',
  'QIP Whole-Home DVR',
  'QIP HD STB',
  'QIP DVR Home Med Software',
  'FTV Wired STB',
  'Multi Stream Cable Card',
  'Digital Adapter (DCT 700)',
  '1 TV Equipment Pkg',
  'EXP DVR Price',
  'DVR Service Fee',
  'Whole-Home DVR',
  'STB DVR',
  'Quantum Premium Svc',
  'Quantum Premium',
  'VMS DVR Prm Software',
  'CT STB HD Wireles'
]

// TODO: move optional offers logic to API

const getScript = (name: string): string => {
  return `I see that you have some of our other services in your account, we can lower your monthly bill by removing ${name}`
}

const getDropVasTitle = (name: string, price: number): string => {
  return `Drop VAS ${name} for $${price}`
}

const getDropVasOffers = (
  customerBilling: ICustomerServiceBilling
): IOptOffer[] => {
  const offers: IOptOffer[] = []

  for (const vasItem of customerBilling.vas_products) {
    if (vasItem.rate && vasItem.rate > 0 && VAS_ITEMS.includes(vasItem.name)) {
      offers.push({
        type: 'VAS',
        name: getDropVasTitle(vasItem.name, vasItem.rate),
        script: getScript(vasItem.name),
        amount: vasItem.rate * -1
      })
    }
  }

  return offers
}

const getDropVideoTitle = (name: string, price: number): string => {
  return `Drop video service ${name} for $${roundPrice(price)}`
}

const getDropInternetOffers = (
  customerBilling: ICustomerServiceBilling
): IOptOffer[] => {
  const offers: IOptOffer[] = []

  for (const internetItem of customerBilling.vas_products) {
    if (
      internetItem.rate &&
      internetItem.rate > 0 &&
      INTERNET_ITEMS.includes(internetItem.name)
    ) {
      offers.push({
        type: 'BB',
        name: `Drop internet service ${internetItem.name} for $${roundPrice(
          internetItem.rate
        )}`,
        script: getScript(internetItem.name),
        amount: internetItem.rate * -1
      })
    }
  }

  return offers
}

const getDropVideoItemOffers = (
  accountItems: ICustomerAccountItem[]
): IOptOffer[] => {
  const items = accountItems.filter(
    (item) =>
      item.product_class === 'VIDEO' &&
      item.description &&
      VIDEO_ITEMS.includes(item.description) &&
      item.is_core_product === false &&
      item.is_recurring &&
      item.is_recurring &&
      item.price_per_month &&
      item.price_per_month > 0
  )

  return items.map((item) => ({
    type: 'Video',
    script: getScript(item.description ?? ''),
    name: getDropVideoTitle(item.description ?? '', item.price_per_month ?? 0),
    amount: item.price_per_month !== undefined ? item.price_per_month * -1 : 0
  }))
}

export const getOptOffers = (
  customerBilling: ICustomerServiceBilling,
  accountItems: ICustomerAccountItem[],
  droppedProduct: IProduct
): IOptOffer[] => {
  let vasOffers = getDropVasOffers(customerBilling)
  if (droppedProduct.label.includes(ProductNamesEnum.video)) {
    vasOffers = vasOffers.concat(getDropVideoItemOffers(accountItems))
  }
  if (
    droppedProduct.label.includes(ProductNamesEnum.fiber) ||
    droppedProduct.label.includes(ProductNamesEnum.copper)
  ) {
    vasOffers = vasOffers.concat(getDropInternetOffers(customerBilling))
  }
  return vasOffers
}

const getCallSessionOptOffers = (
  offers: IOptOffer[]
): ICallSessionOptOffer[] => {
  return offers.map((offer, idx) => ({
    ...offer,
    id: idx,
    accepted: false,
    rejected: false,
    notOffered: false,
    expanded: false
  }))
}

export const buildCallSessionOptOffers = (
  offers: IOptOffer[]
): ICallSessionOptOffer[] => {
  const callSessionOptOffers = getCallSessionOptOffers(offers)

  if (callSessionOptOffers.length > 0) {
    callSessionOptOffers[0].expanded = true
  }

  return callSessionOptOffers
}

interface IDisabledOffer {
  disabled: boolean
  reason?: string
}

export const isOptOfferDisabled = (
  optOffer: ICallSessionOptOffer,
  offers: ICallSessionOffer[]
): IDisabledOffer => {
  if (optOffer.type === 'BB' && isProductDropped('BB', offers)) {
    return {
      disabled: true,
      reason:
        'This product is automatically removed when the customer drops broadband'
    }
  }

  const videoDropped =
    isProductDropped('Video', offers) || isYoutubeTVOfferAccepted(offers)
  if (optOffer.type === 'Video' && videoDropped) {
    return {
      disabled: true,
      reason:
        'This product is automatically removed when the customer drops VerizonTV'
    }
  }

  return { disabled: false }
}
