import React, { ReactNode } from 'react'
import { Box, List, ListItem, Typography } from '@mui/material'
import { ICallSessionRootCauseScript } from '../buddy-api/types/callsession/CallSessionRootCauseScript'
import Markdown from 'markdown-to-jsx'

export const renderScripts = (scripts: ICallSessionRootCauseScript[]) => {
  const getSentence = (value: string, key: number) => {
    return <Markdown key={key}>{value}</Markdown>
  }

  const getSuggestion = (value: string, key: number) => {
    return (
      <Typography key={key}>
        <i>{value}</i>
      </Typography>
    )
  }

  const getInstruction = (value: string, key: number) => {
    return (
      <Typography variant='h2' component='div' key={key}>
        {value}
      </Typography>
    )
  }

  const retval: ReactNode[] = []
  const instruction: ReactNode[] = []
  for (let i = 0; i < scripts.length; i++) {
    const script = scripts[i]
    if (script.type === 'sentence') {
      retval.push(getSentence(script.value, i))
    }
    if (script.type === 'suggestion') {
      retval.push(getSuggestion(script.value, i))
    }
    if (script.type === 'instruction') {
      instruction.push(getInstruction(script.value, i))
    }
  }

  return (
    <Box>
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2
        }}
      >
        {retval.map((item, index) => (
          <ListItem
            sx={{
              display: 'list-item'
            }}
            key={index}
          >
            {item}
          </ListItem>
        ))}
      </List>
      {instruction.length > 0 &&
        instruction.map((item, idx) => (
          <Typography variant='h2' key={idx}>
            {item}
          </Typography>
        ))}
    </Box>
  )
}
