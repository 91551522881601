import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IWinbackSpeed,
  IWinbackSpeedState
} from '../../types/callsession/WinbackSpeed'

const basePath = '/callsession-winback-speed-option/'

export class CallSessionWinbackSpeedsAPI {
  public list = async (
    params?: IAPIParams,
    signal?: AbortSignal | null
  ): Promise<IWinbackSpeed[]> => {
    const result = await ApiRequest.get<IWinbackSpeed[]>(
      basePath + 'list/',
      signal
    )
    return result.body
  }
}

const callSessionWinbackSpeedsAPI = new CallSessionWinbackSpeedsAPI()

export const fetchWinbackSpeeds = createAsyncThunk(
  'callSessionWinbackSpeeds/fetchWinbackSpeeds',
  async (): Promise<IWinbackSpeed[]> => {
    return await callSessionWinbackSpeedsAPI.list()
  }
)

const initialState: IWinbackSpeedState = {
  speeds: [],
  loading: false,
  error: null
}

const callSessionWinbackSpeedsAPISlice = createSlice({
  name: 'callSessionWinbackSpeeds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWinbackSpeeds.pending, (state: IWinbackSpeedState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        fetchWinbackSpeeds.fulfilled,
        (state: IWinbackSpeedState, action) => {
          state.loading = false
          state.error = null
          state.speeds = action.payload
        }
      )
      .addCase(
        fetchWinbackSpeeds.rejected,
        (state: IWinbackSpeedState, action) => {
          state.loading = true
          state.error = action.payload as string
          state.speeds = []
        }
      )
  }
})

export default callSessionWinbackSpeedsAPISlice
