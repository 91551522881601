import React from 'react'
import { Box } from '@mui/material'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../../../store/ConfigureStore'
import { updateOtherCompetitorInput } from '../../../../../../store/callsession/callsessionSlice'

function CompetitorInput() {
  const dispatch = useAppDispatch()
  const newCustomerAddress = useAppSelector(
    (state) => state.agentCallSession.other_competitor_input
  )

  // Inline styles to match the dropdown appearance
  const inputStyle = {
    font: 'inherit',
    color: 'inherit',
    padding: '8px',
    margin: '0 0 8px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'block',
    width: '100%', // This ensures that the input takes the full width of its container
    maxWidth: '600px' // Assuming the dropdowns have a max-width, we match it here.
  }

  const handleChange = (event: any) => {
    dispatch(updateOtherCompetitorInput(event.target.value))
  }

  return (
    <Box>
      <input
        type='text'
        id='competitor'
        name='competitor'
        style={inputStyle}
        value={newCustomerAddress ?? undefined}
        onChange={handleChange}
        placeholder='Enter new competitor'
      />
    </Box>
  )
}

export default CompetitorInput
