/* eslint-disable no-unused-vars */
export enum Languages {
  ENGLISH = 'en',
  SPANISH = 'es'
}

export const languagePosition = {
  [Languages.ENGLISH]: 1,
  [Languages.SPANISH]: 2
}

export const getRelatedLangItem = (
  initialId: number,
  listToSearch: any[],
  selectedLanguage: Languages
): any => {
  let relatedLangId = initialId
  const listLength = listToSearch.length

  switch (languagePosition[selectedLanguage]) {
    case languagePosition[Languages.ENGLISH]: {
      relatedLangId =
        relatedLangId >= listLength ? relatedLangId - listLength : relatedLangId
      break
    }
    case languagePosition[Languages.SPANISH]: {
      relatedLangId =
        relatedLangId + listLength * (languagePosition[selectedLanguage] - 1)
      break
    }
    default: {
      console.log('There is no language match')
      break
    }
  }

  const relatedItem = listToSearch.find((item) => item.id === relatedLangId)
  return relatedItem
}
