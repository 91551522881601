import { Box } from '@mui/material'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { userLandingPage } from 'routes/routes'
import OpportunitiesListIcon from '../../assets/images/users-list.svg'

import './BuddySideBar.scss'
import { SideBar } from '../../components/SideBar'
import { updateTopBarCollapse } from '../../store/topbar/topBarCollapse'
import { useAppDispatch, useAppSelector } from '../../store/ConfigureStore'
import { useTranslation } from 'react-i18next'

const BuddySideBar: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const scrollContainer = React.useRef<HTMLDivElement>(null)
  const { pinned } = useAppSelector((state) => state.topBarCollapse)
  const { t } = useTranslation('sideBar')

  // To-do: Optimize this
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollContainer?.current?.scrollTop ?? 0
      const thr = 400
      if (scrollTop >= thr) {
        if (!pinned) {
          dispatch(updateTopBarCollapse(true))
        }
      }
      if (scrollTop == 0) {
        dispatch(updateTopBarCollapse(false))
      }
    }

    const scroll = scrollContainer?.current
    if (scroll) {
      scroll.addEventListener('scroll', handleScroll)
      return () => {
        scroll.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  interface SideBarItemProps {
    title: string
    tooltip: string
    active?: boolean
    to?: string
    onClick?: () => void
    icon?: React.ReactNode
    visible?: boolean
    childElement?: SideBarItemProps[]
    open?: boolean
    url?: string
  }

  const items: SideBarItemProps[] = [
    {
      title: t('start'),
      icon: (
        <Box
          id='application-icon-customers'
          component='img'
          sx={{
            width: 25,
            objectFit: 'contain'
          }}
          src={OpportunitiesListIcon}
          alt='Equipment'
        />
      ),
      url: userLandingPage(),
      tooltip: t('start'),
      visible: true
    }
  ]

  const navigateTo = (url: string): void => {
    navigate(url)
  }
  const collectNextUrl = (url: string, navigateFlag: boolean): void => {
    if (navigateFlag) {
      navigateTo(url)
    }
  }

  return (
    <Box flex={1} display='flex' flexDirection='row' height='100%'>
      <SideBar
        items={items}
        sidebarType='buddy'
        collectNextUrl={collectNextUrl}
      />

      <Box
        id={'buddy-sidebar-content'}
        flex={1}
        display='flex'
        flexDirection='column'
        sx={{ overflowY: 'scroll' }}
        ref={scrollContainer}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default BuddySideBar
