import {
  ICustomerServiceBilling,
  ICustomerServiceBillingState
} from '../../types/customer/CustomerServiceBilling'
import { IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-service-billing/'

export interface GetCustomerServiceBillingParam extends IAPIParams {
  account_id: string
  repository?: Repository
}

export class CustomerServiceBillingAPI {
  public get = async (
    params: GetCustomerServiceBillingParam,
    signal?: AbortSignal | null
  ): Promise<ICustomerServiceBilling> => {
    const result = await ApiRequest.get<ICustomerServiceBilling>(
      basePath + params.account_id + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerServiceBillingAPI = new CustomerServiceBillingAPI()

export const getCustomerServiceBilling = createAsyncThunk(
  'customer/getCustomerServiceBilling',
  async (
    params: GetCustomerServiceBillingParam
  ): Promise<ICustomerServiceBilling> => {
    return await customerServiceBillingAPI.get(params)
  }
)

const initialState: ICustomerServiceBillingState = {
  customerServiceBilling: null,
  loading: false,
  error: null
}

export const customerServiceBillingAPISlice = createSlice({
  name: 'customerServiceBilling',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCustomerServiceBilling.pending,
        (state: ICustomerServiceBillingState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        getCustomerServiceBilling.fulfilled,
        (state: ICustomerServiceBillingState, action) => {
          state.loading = false
          state.error = null
          state.customerServiceBilling = action.payload
        }
      )
      .addCase(
        getCustomerServiceBilling.rejected,
        (state: ICustomerServiceBillingState) => {
          state.loading = false
          state.error = 'Error fetching customer service billing.'
          state.customerServiceBilling = null
        }
      )
  }
})

export default customerServiceBillingAPISlice
