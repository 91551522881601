import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import {
  Box,
  Collapse,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  type SxProps
} from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'
// import { FaCog } from 'react-icons/fa'
import verizonLogo from 'assets/verizon-brand.svg'

import clsx from 'clsx'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import LogoutIcon from '@mui/icons-material/Logout'
import { NavList, NavTooltip } from 'components/SideNav'
import React, { type ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { adminPanelURL, loginPageURL } from 'routes/routes'
import { logoutUser } from '../buddy-api/api/AuthAPI'
import { useAppDispatch, useAppSelector } from '../store/ConfigureStore'
import { useTranslation } from 'react-i18next'
import { updateSelectedLanguage } from '../store/callsession/callsessionSlice'
import { Languages } from 'store/languages'

export interface SidebarItemProps {
  title: string
  to?: string
  active?: boolean
  onClick?: () => void
  url?: string
  tooltip: string
  visible?: boolean
  icon?: ReactNode
  open?: boolean
  childElement?: SidebarItemProps[]
  menuExpanded?: boolean
  children?: ReactNode
}

const ChildContainer = ({
  menuExpanded,
  children
}: {
  menuExpanded: boolean
  children: ReactNode
}) => {
  return (
    <Box
      sx={[
        {
          marginLeft: '25px',
          paddingLeft: '10px',
          borderLeft: '2px solid rgba(189, 191, 191, 0.3)'
        },
        !menuExpanded && { display: 'none' }
      ]}
    >
      {children}
    </Box>
  )
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  title,
  to,
  active = false,
  onClick,
  children,
  tooltip,
  childElement,
  open,
  menuExpanded
}) => {
  const tooltipTitle = tooltip ?? title
  const [menuItemExpand, setMenuItemExpand] = React.useState(open)

  const renderLinkItem = () => {
    return (
      <NavTooltip arrow placement='right' title={tooltipTitle}>
        <ListItemButton
          component={Link}
          id='application-icon-monitoring'
          href={to}
          target='_blank'
          className={clsx({ active })}
          sx={{
            textDecoration: 'none !important',
            flexGrow: 'initial',
            maxHeight: '3rem'
          }}
        >
          <ListItemIcon>{children}</ListItemIcon>
          <ListItemText primary={title} id='application-icon-monitoring-text' />
        </ListItemButton>
      </NavTooltip>
    )
  }

  const renderToItem = (to: string) => {
    const expand = menuExpanded === true ? <ExpandLess /> : <ExpandMore />
    return (
      <>
        {/* eslint-disable */}
        <NavTooltip arrow placement='right' title={tooltipTitle}>
          <ListItemButton
            component={RouterLink}
            to={to}
            className={clsx({ active: active })}
            sx={{
              flexGrow: 'initial',
              maxHeight: '3rem'
            }}
            onClick={() => {
              setMenuItemExpand(!menuItemExpand)
            }}
          >
            <ListItemIcon>{children}</ListItemIcon>
            <ListItemText
              primary={title}
              id='application-icon-opportunities-text'
            />
            {childElement?.length ? expand : null}
          </ListItemButton>
        </NavTooltip>
        {menuItemExpand && childElement ? (
          <ChildContainer menuExpanded={Boolean(menuExpanded)}>
            {childElement.map((childItem) => {
              return (
                <SidebarItem
                  key={'child'}
                  title={childItem.title}
                  active={childItem.active}
                  to={childItem.to}
                  tooltip={!menuExpanded ? childItem.tooltip : ''}
                >
                  {childItem.icon}
                </SidebarItem>
              )
            })}
          </ChildContainer>
        ) : (
          ''
        )}
      </>
    )
  }

  const renderRegularItem = () => {
    const expand = menuItemExpand ? <ExpandLess /> : <ExpandMore />
    return (
      <>
        <NavTooltip arrow placement='right' title={tooltipTitle}>
          <ListItemButton
            onClick={() => {
              setMenuItemExpand(!menuItemExpand)
              if (onClick) {
                onClick()
              }
            }}
            id='application-icon-isa'
            className={clsx({ active: active })}
            sx={{ flexGrow: 'initial', maxHeight: '3rem' }}
          >
            <ListItemIcon>{children}</ListItemIcon>
            <ListItemText primary={title} id='application-icon-isa-text' />
            {childElement?.length ? expand : null}
          </ListItemButton>
        </NavTooltip>
        {menuItemExpand && childElement ? (
          <ChildContainer menuExpanded={Boolean(menuExpanded)}>
            {childElement.map((childItem) => {
              return childItem.url ? (
                <NavTooltip arrow placement='right' title={childItem.tooltip}>
                  <ListItemButton
                    component={RouterLink}
                    to={childItem.url}
                    className={clsx({
                      active: childItem.active
                    })}
                    sx={{ flexGrow: 'initial', maxHeight: '3rem' }}
                  >
                    <ListItemIcon>{childItem.icon}</ListItemIcon>
                    <ListItemText
                      primary={childItem.title}
                      id={childItem.title + '-menu'}
                    />
                  </ListItemButton>
                </NavTooltip>
              ) : (
                ''
              )
            })}
          </ChildContainer>
        ) : (
          ''
        )}
      </>
    )
  }

  if (to === undefined && onClick === undefined)
    throw new Error('Either to or onClick must be provided')

  if (to !== undefined && to.startsWith('http')) {
    return renderLinkItem()
  }

  if (to !== undefined) {
    return renderToItem(to)
  }

  return renderRegularItem()
}

export const SideBar: React.VFC<{
  sx?: SxProps
  sidebarType: string
  items: SidebarItemProps[]
  collectNextUrl?: (url: string, navigateFlag: boolean) => void
}> = ({ sx, items, sidebarType, collectNextUrl }) => {
  const [expanded, setExpanded] = React.useState(false)
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.authAPI)
  const { i18n } = useTranslation()
  const { t } = useTranslation('sideBar')

  const logout = async () => {
    await dispatch(logoutUser())
    collectNextUrl?.(loginPageURL(), true)
  }

  // eslint-disable-next-line no-unused-vars
  const navigateToAdminPanel = () => {
    collectNextUrl?.(adminPanelURL(), true)
  }

  // eslint-disable-next-line no-unused-vars
  const userIsAdmin = (): boolean => {
    return user?.roles.map((role) => role.name).includes('Administrator')
  }

  const changeLanguageTitle = () => {
    if (i18n && i18n.language) {
      const lang = (i18n.language.split('-')[0] ||
        Languages.ENGLISH) as Languages
      if (lang === Languages.ENGLISH) {
        return 'changeToEs'
      }
      return 'changeToEn'
    }
    return ''
  }

  const changeLanguage = () => {
    if (i18n && i18n.language) {
      const lang = (i18n.language.split('-')[0] ||
        Languages.ENGLISH) as Languages
      if (lang === Languages.SPANISH) {
        i18n.changeLanguage(Languages.ENGLISH)
        dispatch(updateSelectedLanguage(Languages.ENGLISH))
      } else {
        i18n.changeLanguage(Languages.SPANISH)
        dispatch(updateSelectedLanguage(Languages.SPANISH))
      }
    }
    return ''
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        position: 'relative',
        ...sx
      }}
      // className={classes.sidebar}
    >
      <div>
        <Collapse
          orientation='horizontal'
          in={expanded}
          collapsedSize={64}
          sx={{
            height: '100%',
            '& .active': {
              backgroundColor: '#424F51',
              '& .MuiListItemText-primary': { color: 'primary' }
            }
          }}
        >
          <NavList
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              zIndex: 110,
              pt: 0
            }}
          >
            <Box
              sx={{
                // backgroundColor: '#F50A23',
                backgroundColor: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: expanded ? '100%' : 65,
                height: expanded ? '15%' : 55,
                mb: '21px'
              }}
            >
              <Box
                component='img'
                sx={{
                  width: expanded ? '50%' : '70%',

                  objectFit: 'contain'
                }}
                src={verizonLogo}
                alt='Buddy'
              />
            </Box>

            {items.map((item) => {
              return (
                !!item.visible && (
                  <SidebarItem
                    key={item.title}
                    title={item.title}
                    active={item.active}
                    to={item.to}
                    onClick={() => {
                      collectNextUrl?.(item?.url ?? '', true)
                    }}
                    menuExpanded={expanded}
                    tooltip={!expanded ? item.tooltip : ''}
                    childElement={item.childElement}
                    open={item.open}
                  >
                    {item.icon}
                  </SidebarItem>
                )
              )
            })}

            <Box sx={{ flex: 1 }} />

            {!expanded && (
              <SidebarItem
                title={t('expand')}
                onClick={() => {
                  setExpanded((e) => !e)
                }}
                tooltip={!expanded ? t('expand') : ''}
              >
                <IconButton
                  // className={classes.toggle}
                  id='application-button-expand-panel'
                  sx={{
                    color: 'white',
                    width: expanded ? '100%' : 40
                  }}
                >
                  <KeyboardDoubleArrowRightOutlinedIcon />
                </IconButton>
              </SidebarItem>
            )}

            {!!expanded && (
              <SidebarItem
                title={t('collapse')}
                onClick={() => {
                  setExpanded((e) => !e)
                }}
                tooltip=''
              >
                <IconButton
                  // className={classes.toggle}
                  id='application-button-collapse-panel'
                  sx={{
                    color: 'white',
                    width: expanded ? '100%' : 40
                  }}
                >
                  <KeyboardDoubleArrowLeftOutlinedIcon id='application-button-collapse-panel' />
                </IconButton>
              </SidebarItem>
            )}

            <SidebarItem
              title={t(changeLanguageTitle())}
              to={undefined}
              tooltip={!expanded ? t('language') : ''}
              onClick={() => changeLanguage()}
            >
              <LanguageIcon id='application-icon-language' />
            </SidebarItem>

            {/* {userIsAdmin() && (
              <SidebarItem
                title={t('admin')}
                tooltip={!expanded ? t('admin') : ''}
                onClick={() => navigateToAdminPanel()}
              >
                <FaCog
                  id='application-icon-admin-panel'
                  size={20}
                  color={'white'}
                />
              </SidebarItem>
            )} */}

            <SidebarItem
              title={t('logout')}
              onClick={logout}
              tooltip={!expanded ? t('logout') : ''}
            >
              <LogoutIcon id='application-icon-logout' />
            </SidebarItem>
          </NavList>
        </Collapse>
      </div>
    </Box>
  )
}
