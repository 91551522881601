import { useAppSelector } from '../store/ConfigureStore'

export const useSettings = () => {
  const { settings } = useAppSelector((state) => state.settingAPI)

  // Function to get a specific setting by name
  const getBooleanSetting = (name: string): boolean | undefined => {
    if (settings) {
      const setting = settings.find((s) => s.key === name)
      // Assuming the setting.value is a string that could be 'true' or 'false'
      return setting && setting.type === 'boolean'
        ? setting.value === 'true'
        : undefined
    }
    return undefined
  }

  return {
    getBooleanSetting
  }
}
