import {
  type ICallSession,
  ICallSessionCreate,
  type ICallSessionState
} from 'buddy-api/types/callsession/CallSession'
import { type IAPIParams, type IListResponseWrapper } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const basePath = '/callsessions/'

export interface CallSessionListParams extends IAPIParams {
  start_date?: string
  end_date?: string
  user_id?: number
  user_name?: string
  account_id?: string
}

export class CustomerSearchAPI {
  public list = async (
    params?: CallSessionListParams,
    signal?: AbortSignal | null
  ): Promise<ICallSession[]> => {
    const result = await ApiRequest.get<IListResponseWrapper<ICallSession>>(
      basePath + 'list/',
      signal,
      params
    )
    return result.body.results
  }

  public save = async (
    data: ICallSessionCreate,
    signal?: AbortSignal | null
  ): Promise<ICallSession> => {
    const result = await ApiRequest.post<ICallSession>(basePath, signal, data)
    return result.body
  }
}

const customerSearchAPI = new CustomerSearchAPI()

export const fetchCallSessions = createAsyncThunk(
  'callSessions/fetchCallSessions',
  async (params: CallSessionListParams): Promise<ICallSession[]> => {
    return await customerSearchAPI.list(params)
  }
)

export const saveCallSession = createAsyncThunk(
  'callSessions/saveCallSession',
  async (data: ICallSessionCreate): Promise<ICallSession> => {
    return await customerSearchAPI.save(data)
  }
)

const initialState: ICallSessionState = {
  callSessions: [],
  loading: false,
  error: null,
  saveLoading: false,
  saveError: null
}

const callSessionsAPISlice = createSlice({
  name: 'callSessions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCallSessions.pending, (state: ICallSessionState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        fetchCallSessions.fulfilled,
        (state: ICallSessionState, action) => {
          state.loading = false
          state.error = null
          state.callSessions = action.payload
        }
      )
      .addCase(
        fetchCallSessions.rejected,
        (state: ICallSessionState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.callSessions = []
        }
      )
      .addCase(saveCallSession.pending, (state: ICallSessionState) => {
        state.saveLoading = true
        state.saveError = null
      })
      .addCase(saveCallSession.fulfilled, (state: ICallSessionState) => {
        state.saveLoading = false
        state.saveError = null
      })
      .addCase(saveCallSession.rejected, (state: ICallSessionState, action) => {
        if (action.payload) {
          state.saveError = action.payload as string
        } else {
          state.saveError = 'Error saving call session. Please try again.'
        }
        state.saveLoading = false
      })
  }
})

export default callSessionsAPISlice
