import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Collapse } from '@mui/material'

export interface IBaseItem {
  title: string
  controls?: React.ReactNode
  children?: React.ReactNode
  expanded?: boolean
  onToggle?: (expanded: boolean) => void // eslint-disable-line no-unused-vars
  disabled?: boolean
}

export const BaseItem = ({
  title,
  controls,
  expanded,
  onToggle,
  disabled,
  children
}: IBaseItem) => {
  const [localExpanded, setLocalExpanded] = useState(false)

  const isExpanded = onToggle ? expanded : localExpanded
  const onClick = () => {
    if (onToggle) onToggle(!expanded)
    else setLocalExpanded(!localExpanded)
  }

  return (
    <Box sx={{ border: '1px solid #D8D8D8', borderTop: 'none' }}>
      {/* Top Row */}
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
        <Box
          sx={{
            flexGrow: 1,
            cursor: disabled ? 'default' : 'pointer',
            display: 'flex',
            padding: '12px 0px'
          }}
          onClick={onClick}
        >
          <Box
            sx={{
              color: '#141928',
              // fontFamily: 'Arial',
              fontSize: '13px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal'
            }}
          >
            {title}
          </Box>
        </Box>
        {controls}
      </Box>
      {/* Expandable section */}
      {!disabled && (
        <Collapse in={isExpanded}>
          <Box style={{ margin: '0 20px 18px 20px' }}>{children}</Box>
        </Collapse>
      )}
    </Box>
  )
}
