/* eslint-disable no-unused-vars */
export enum AccountStatus {
  Live = 'LIVE',
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending Change',
  Suspended = 'Suspended'
}

export enum ProductClassName {
  Internet = 'INTERNET',
  Video = 'VIDEO',
  Voice = 'VOICE',
  Promotion = 'PROMOTION',
  Miscellaneous = 'MISCELLANEOUS',
  VAS = 'ADDITIONAL SERVICES'
}

export enum Repository {
  FrontierAPI = 'frontier_api',
  AuroraPostgres = 'aurora_postgres'
}
/* eslint-disable no-unused-vars */

export enum OfferedOptions {
  offeredAccepted = 'Offered - accepted',
  offeredRejected = 'Offered - rejected',
  notOffered = 'Not offered'
}

export enum YesNoOptions {
  yes = 'Yes',
  no = 'No'
}

export enum MoveTypeOptions {
  shortTermMove = 'Short-term move',
  longTermMove = 'Long-term move'
}

export enum DataNetwork {
  fiber = 'FIBER',
  copper = 'COPPER'
}

export enum ProductNamesEnum {
  copper = 'Copper',
  fiber = 'Fiber',
  video = 'TV',
  voice = 'Voice'
}

export enum ServiceCallAbout {
  Deactivation = 'Deactivation',
  NotDeactivation = 'Not deactivation related'
}
