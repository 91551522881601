import { Box, ListItem, Typography } from '@mui/material'
import React, { isValidElement, ReactNode } from 'react'
import { useAppSelector } from 'store/ConfigureStore'
import { useTranslation } from 'react-i18next'
import { buildOfferString } from 'store/callsession/callsessionSlice'

interface OffersInstructionsProps {
  [key: string]: {
    [usageCategory: string]: ReactNode
  }
}

export const OffersInstructions: React.FC = () => {
  const { t } = useTranslation('offersInstructions')

  const offersInstructions: OffersInstructionsProps = {
    pricing: {
      sgp_less_10gb: (
        <Box>
          <ListItem sx={{ display: 'list-item' }}>
            {t('pricing.sgp_less_10gb.1')}
          </ListItem>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('pricing.sgp_less_10gb.2')}
          </Typography>
        </Box>
      )
    },
    device: {
      bronze_4g: (
        <Box>
          <ListItem sx={{ display: 'list-item' }}>
            {t('device.bronze_4g.1')}
          </ListItem>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('device.bronze_4g.2')}
          </Typography>
        </Box>
      ),
      sgp_4g: (
        <Box>
          <ListItem sx={{ display: 'list-item' }}>
            {t('device.sgp_4g.1')}
          </ListItem>
        </Box>
      )
    },
    network: {
      bronze_4g: (
        <Box>
          <ListItem sx={{ display: 'list-item' }}>
            {t('network.bronze_4g.1')}
          </ListItem>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('network.bronze_4g.2')}
          </Typography>
        </Box>
      ),
      sgp_4g: (
        <Box>
          <ListItem sx={{ display: 'list-item' }}>
            {t('network.sgp_4g.1')}
          </ListItem>
        </Box>
      )
    },
    financialHardship: {
      sgp_less_10gb: (
        <Box>
          <ListItem sx={{ display: 'list-item' }}>
            {t('financialHardship.sgp_less_10gb.1')}
          </ListItem>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('financialHardship.sgp_less_10gb.2')}
          </Typography>
        </Box>
      )
    }
  }

  const pricingOfferDirection = () => {
    const rootCause = useAppSelector(
      (state) => state.agentCallSession.call_session_root_cause
    )
    if (!rootCause) return

    const selectedValue = {
      plan: useAppSelector((state) => state.agentCallSession.plan),
      device: useAppSelector((state) => state.agentCallSession.device),
      dataUsage: useAppSelector((state) => state.agentCallSession.data_usage),
      lines: useAppSelector((state) => state.agentCallSession.lines)
    }

    const selectedDiscoveryCombination = buildOfferString(
      rootCause.name,
      selectedValue.plan,
      selectedValue.device,
      selectedValue.dataUsage,
      selectedValue.lines
    )

    if (!selectedDiscoveryCombination) return

    const rootCauseName = rootCause.name
    const instructionMessage =
      offersInstructions[rootCauseName]?.[selectedDiscoveryCombination] ||
      offersInstructions.defaultMessage

    const isValid =
      isValidElement(instructionMessage) ||
      typeof instructionMessage === 'string'

    return (
      <Box>
        {isValid ? instructionMessage : <strong>{t('defaultMessage')}</strong>}
      </Box>
    )
  }

  return <Box>{pricingOfferDirection()}</Box>
}
