import { type IAPIParams } from '../types'
import ApiRequest from '../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Setting, SettingState } from '../types/Setting'

const basePath = '/settings/'

export class SettingAPI {
  public list = async (
    params?: IAPIParams,
    signal?: AbortSignal | null
  ): Promise<Setting[]> => {
    const result = await ApiRequest.get<Setting[]>(basePath + 'list/', signal)
    return result.body
  }
}

const settingAPI = new SettingAPI()

export const fetchSettings = createAsyncThunk(
  'setting/listSettings',
  async (): Promise<Setting[]> => {
    return await settingAPI.list()
  }
)

export const updateSetting = createAsyncThunk(
  'setting/updateSetting',
  async (setting: Setting): Promise<Setting> => {
    const result = await ApiRequest.put<Setting, Setting>(
      basePath + setting.id + '/',
      null,
      setting
    )
    return result.body
  }
)

const initialState: SettingState = {
  settings: null,
  loading: false,
  error: null
}

const settingAPISlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state: SettingState) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchSettings.fulfilled, (state: SettingState, action) => {
        state.loading = false
        state.error = null
        state.settings = action.payload
      })
      .addCase(fetchSettings.rejected, (state: SettingState, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(updateSetting.pending, (state: SettingState) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateSetting.fulfilled, (state: SettingState, action) => {
        state.loading = false
        state.error = null
        if (state.settings) {
          state.settings = state.settings.map((setting) => {
            if (setting.key === action.payload.key) {
              setting.value = action.payload.value
            }
            return setting
          })
        }
      })
      .addCase(updateSetting.rejected, (state: SettingState, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  }
})

export default settingAPISlice
