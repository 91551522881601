import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerBill,
  ICustomerBillState
} from '../../types/customer/CustomerBill'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-bills/'

export interface GetCustomerBillParams extends IAPIParams {
  account_id: string
  repository?: Repository
  account_number?: string | null
}

export class CustomerBillAPI {
  public get = async (
    params: GetCustomerBillParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerBill[]> => {
    const result = await ApiRequest.get<ICustomerBill[]>(
      basePath + params.account_id + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerBillAPI = new CustomerBillAPI()

export const getCustomerBill = createAsyncThunk(
  'use_get_customer_bill',
  async (params: GetCustomerBillParams): Promise<ICustomerBill[]> => {
    return await customerBillAPI.get(params)
  }
)

const initialState: ICustomerBillState = {
  bills: [],
  loading: false,
  error: null
}

const customerBillAPISlice = createSlice({
  name: 'use_frontier_service_usages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerBill.pending, (state: ICustomerBillState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        getCustomerBill.fulfilled,
        (state: ICustomerBillState, action) => {
          state.loading = false
          state.error = null
          state.bills = action.payload
        }
      )
      .addCase(getCustomerBill.rejected, (state: ICustomerBillState) => {
        state.loading = false
        state.error = 'Error fetching bill documents.'
        state.bills = []
      })
  }
})

export default customerBillAPISlice
