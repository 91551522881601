import React from 'react'

import { Box, type BoxProps } from '@mui/material'

export const Row: React.FC<BoxProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export const Column: React.FC<BoxProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export const SmallVerticalSpace: React.FC<BoxProps> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Box
      sx={{
        height: '10px',
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
