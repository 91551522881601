import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DeviceOffers } from './DeviceOffers'
import { DiscountOffers } from './DiscountOffers'
import { useAppSelector } from 'store/ConfigureStore'
import {
  offerTypes,
  setDeviceOffers,
  setDiscountOffers
} from 'store/callsession/callsessionSlice'
import {
  buildCallsessionDefaultOffer,
  getOrderOffersByDisplay,
  IDefaultOffer
} from 'lib/defaultOffers'
import {
  completeSection,
  CallFlowSection,
  openSection,
  incompleteSection,
  closeSection
} from 'store/callflow/collapsableSections'

export interface Offer {
  offer_ladder_id: number
  offer_product_type: string
  priority: number
  name: string
  script: string
  is_active: boolean
}

export const OffersSection = () => {
  const { t } = useTranslation('offersInstructionsRow')
  const dispatch = useDispatch()

  const devicesFirstOfferLadders = [3, 4]
  const [defaultOffers, setDefaultOffers] = useState<IDefaultOffer[]>([])

  const { allOffers, deviceOffers, discountOffers } = useAppSelector(
    (state) => ({
      allOffers: state.offersAPI.offers,
      deviceOffers: state.agentCallSession.callsession_device_offers,
      discountOffers: state.agentCallSession.callsession_discount_offers
    })
  )

  // Format offers and handle device and discount ones
  const isDeviceFirst = devicesFirstOfferLadders.includes(
    allOffers[0]?.offer_ladder_id
  )
  useEffect(() => {
    let offers = allOffers.map((offer) => buildCallsessionDefaultOffer(offer))
    offers = getOrderOffersByDisplay(offers, isDeviceFirst)
    setDefaultOffers(offers)
    dispatch(setDeviceOffers(offers))
    dispatch(setDiscountOffers(offers))
  }, [allOffers])

  const areOffersValid = (offers: IDefaultOffer[]): boolean => {
    const allRejected = offers.every((offer) => offer.rejected)
    const oneAccepted = offers.some((offer) => offer.accepted)

    return oneAccepted || allRejected
  }
  const areOffersComplete = (offers: IDefaultOffer[]): boolean => {
    if (!areOffersValid(offers)) {
      dispatch(incompleteSection(CallFlowSection.OFFERS))
      dispatch(closeSection(CallFlowSection.ACTIONS))
      dispatch(closeSection(CallFlowSection.SESSION_OUTCOME))
      return false

      // If it's valid, go to next section
    } else {
      dispatch(completeSection(CallFlowSection.OFFERS))
      dispatch(openSection(CallFlowSection.ACTIONS))
      return true
    }
  }

  const getBulletInstructions = (key: string): string[] => {
    const result = t(key, { returnObjects: true })
    return Array.isArray(result) ? (result as string[]) : []
  }

  const handleDefaultOffersChange = (
    offerType: string,
    nextOfferIndex: number
  ) => {
    const offers =
      offerType === offerTypes.device.name ? deviceOffers : discountOffers

    let hasChanged = false
    const updatedOffers = offers.map((offer) => {
      if (offer.displayOrder === nextOfferIndex) {
        hasChanged = true
        return { ...offer, isDisplayed: true }
      }
      return offer
    })

    if (!hasChanged) return
    if (offerType === offerTypes.device.name) {
      dispatch(setDeviceOffers(updatedOffers))
    } else if (offerType === offerTypes.discount.name) {
      dispatch(setDiscountOffers(updatedOffers))
    }
  }

  const onOfferChange = (currentOffer: IDefaultOffer) => {
    const currentOfferDisplayOrder = currentOffer.displayOrder
    if (currentOfferDisplayOrder === undefined) return

    const nextOfferIndex = currentOfferDisplayOrder + 1
    const updatedOffers = defaultOffers.map((offer) => {
      if (offer.displayOrder === currentOffer.displayOrder) return currentOffer

      if (offer.displayOrder === nextOfferIndex)
        return { ...offer, isDisplayed: true }
      return offer
    })

    if (areOffersComplete(updatedOffers)) return

    // If there are offers to check, continue
    setDefaultOffers(updatedOffers)
    const nextOffer = updatedOffers.find(
      (offer) => offer.displayOrder === nextOfferIndex
    )
    if (nextOffer) {
      handleDefaultOffersChange(nextOffer.offer_product_type, nextOfferIndex)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {isDeviceFirst ? (
        <>
          <DeviceOffers
            generalInstruction={t('offersGeneralInstruction')}
            bulletInstructions={getBulletInstructions(
              'devicesFirstBulletInstructions'
            )}
            onCheckOffer={(currentOffer) => onOfferChange(currentOffer)}
          />
          <DiscountOffers
            bulletInstructions={getBulletInstructions(
              'discountsSecondBulletInstructions'
            )}
            onCheckOffer={(currentOffer) => onOfferChange(currentOffer)}
          />
        </>
      ) : (
        <>
          <DiscountOffers
            generalInstruction={t('offersGeneralInstruction')}
            bulletInstructions={getBulletInstructions(
              'discountsFirstBulletInstructions'
            )}
            onCheckOffer={(currentOffer) => onOfferChange(currentOffer)}
          />
          <DeviceOffers
            bulletInstructions={getBulletInstructions(
              'devicesSecondBulletInstructions'
            )}
            onCheckOffer={(currentOffer) => onOfferChange(currentOffer)}
          />
        </>
      )}
    </Box>
  )
}
