import { Box, IconButton, Paper, TextField, Tooltip } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/ConfigureStore'
import { updateAgentCallSessionComments } from '../../store/callsession/callsessionSlice'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useTranslation } from 'react-i18next'

const CallSessionComment: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('sessionOutcomePanel')

  const { comments } = useAppSelector((state) => ({
    comments: state.agentCallSession.comments
  }))

  const updateComment = (comment: string) => {
    dispatch(updateAgentCallSessionComments(comment))
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(comments ?? '')
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        padding: '16px',
        margin: '10px'
      }}
    >
      <Box
        style={{ marginTop: '22px', marginBottom: '12px', fontWeight: 'bold' }}
        id='call-session-comments'
      >
        {t('comments')}
        <Tooltip title='Copy comments' placement='top'>
          <span>
            <IconButton
              disabled={comments === null || comments === ''}
              onClick={() => copyToClipboard()}
              aria-label='Copy comments'
            >
              <ContentCopyIcon fontSize='small' />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <TextField
        id='call-session-comments-textarea'
        sx={{ width: '100%' }}
        placeholder={t('commentsPlaceholder')}
        multiline
        minRows={6}
        value={comments ?? ''}
        onChange={(e) => {
          updateComment(e.target.value as unknown as string)
        }}
      />
    </Paper>
  )
}

export default CallSessionComment
