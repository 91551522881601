import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { userLandingPage } from 'routes/routes'

const NotFoundPage = (): React.ReactElement => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(userLandingPage())
  }, [])

  return <div> Not Found </div>
}

export { NotFoundPage }
