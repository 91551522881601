import { Box } from '@mui/system'
import { OfferValues } from 'lib/calcDiscount'
import { roundPrice } from 'lib/utils'
import React from 'react'

const squareSize = '60px'
const gapSize = '15px'

const formatAmount = (amount: number) => {
  amount = roundPrice(amount)
  if (amount < 0) return `-$${amount * -1}`
  return `$${amount}`
}

const formatAmountDiff = (amount: number) => {
  amount = roundPrice(amount)
  if (amount < 0) return `-$${amount * -1}`
  return `+$${amount}`
}

const PriceItem = ({
  value,
  color,
  width
}: {
  value: number
  color?: string
  width?: string
}) => {
  return (
    <Box
      sx={{
        height: squareSize,
        width: width ?? squareSize,
        background: '#f8f8f8',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color
      }}
    >
      <Box>{formatAmount(value)}</Box>
    </Box>
  )
}

const Breakdown = ({
  offerValues,
  width
}: {
  offerValues: OfferValues
  width: string
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width,
        background: '#f8f8f8',
        fontSize: '12px',
        padding: '10px',
        gap: '10px  '
      }}
    >
      <Box
        sx={{
          width: '45px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {formatAmountDiff(offerValues.totalDiff)}
      </Box>
      <Box sx={{ height: '100%', borderLeft: '1px solid #D8D8D8' }}></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        {offerValues.breakdown.map((item, idx) => (
          <div key={idx}>
            {`${formatAmountDiff(item.amount)} ${item.description}`}
          </div>
        ))}
      </Box>
    </Box>
  )
}

const HeaderItem = ({ value, width }: { value: string; width?: string }) => {
  return (
    <Box sx={{ width: width ?? squareSize, textAlign: 'center' }}>{value}</Box>
  )
}

interface IOfferAmount {
  summary: OfferValues
}

export const OfferAmount = ({ summary }: IOfferAmount) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: gapSize,
          background: '#f8f8f8',
          padding: '5px 0px',
          marginBottom: gapSize
        }}
      >
        <HeaderItem value='Current total' width='90px' />
        <HeaderItem value='Changes' width='220px' />
        <HeaderItem value='New total' width='70px' />
      </Box>
      <Box sx={{ display: 'flex', gap: gapSize }}>
        <PriceItem value={roundPrice(summary.currentTotal)} width='90px' />
        <Breakdown width='220px' offerValues={summary} />
        <PriceItem value={roundPrice(summary.newTotal)} width='70px' />
      </Box>
    </>
  )
}
