import { type IAPIParams } from '../types'
import ApiRequest from '../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Repository } from '../../utils/enums'

const basePath = '/offers/'

export interface OfferAPIParams extends IAPIParams {
  callsession_intent_id?: number
  root_cause_id?: number
  dropped_product_id?: number
  account_id?: string
  account_number?: string
  repository?: Repository
  new_tech_option_id?: number
  customer_move_type_id?: number
  inactive_account?: boolean
  winback_speed_id?: number
  offer_combo?: string
  root_cause_name?: string
}

export class OfferAPI {
  public list = async (
    params: OfferAPIParams,
    signal?: AbortSignal | null
  ): Promise<any[]> => {
    // Filter undefined values
    const paramsSend = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== undefined) // eslint-disable-line  no-unused-vars,  @typescript-eslint/no-unused-vars
    )
    const result = await ApiRequest.get<any[]>(basePath, signal, paramsSend)
    return result.body
  }
}

const offerAPI = new OfferAPI()

export const fetchOffers = createAsyncThunk(
  'offer/fetchOffers',
  async (params: OfferAPIParams): Promise<any[]> => {
    return await offerAPI.list(params)
  }
)

interface IOfferState {
  offers: any[]
  loading: boolean
  error: string | null
}

const initialState: IOfferState = {
  offers: [],
  loading: false,
  error: null
}

const offersAPISlice = createSlice({
  name: 'callsessionIntent',
  initialState,
  reducers: {
    resetOffers: (state: IOfferState) => {
      state.offers = []
      state.loading = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state: IOfferState) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchOffers.fulfilled, (state: IOfferState, offer) => {
        state.loading = false
        state.error = null
        state.offers = offer.payload
      })
      .addCase(fetchOffers.rejected, (state: IOfferState, offer) => {
        state.loading = false
        state.error = offer.payload as string
        state.offers = []
      })
  }
})

export const { resetOffers } = offersAPISlice.actions
export default offersAPISlice
