import React from 'react'
import { useIsInactiveCustomer } from 'utils/useIsDisconnectedCustomer'
import { ContextSettingActiveCustomer } from './ContextSettingActiveCustomer'
import { ContextSettingInactiveCustomer } from './ContextSettingInactiveCustomer'

export const ContextSetting = () => {
  const inactiveCustomer = useIsInactiveCustomer()

  return inactiveCustomer ? (
    <ContextSettingInactiveCustomer />
  ) : (
    <ContextSettingActiveCustomer />
  )
}
