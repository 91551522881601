import { IDefaultOffer } from 'lib/defaultOffers'
import { useAppSelector } from 'store/ConfigureStore'
import { useIsInactiveCustomer } from 'utils/useIsDisconnectedCustomer'

const hasOffered = (offers: IDefaultOffer[]): boolean => {
  return offers.some((offer) => offer.accepted || offer.rejected)
}
export const useCanOpenActions = () => {
  if (useIsInactiveCustomer()) return true

  const deviceOffers = useAppSelector(
    (state) => state.agentCallSession.callsession_device_offers
  )
  const discountOffers = useAppSelector(
    (state) => state.agentCallSession.callsession_discount_offers
  )

  const deviceHasValidOffer = hasOffered(deviceOffers)
  const discountHasValidOffer = hasOffered(discountOffers)

  return deviceHasValidOffer || discountHasValidOffer
}
