import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'

import Microsoft from '@mui/icons-material/Microsoft'

import './LoginPage.scss'
import { loginUser } from '../../buddy-api/api/AuthAPI'
import { type RootState, useAppDispatch } from '../../store/ConfigureStore'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { samlLoginURL, userLandingPage } from '../../routes/routes'
import { type IUserTokenInfo } from '../../buddy-api'
import { LOCAL_STORAGE_KEYS } from '../../constants'
import FrontierNameLogo from '../../assets/verizon-name-logo.svg'
import FrontierBuddyLogo from '../../assets/images/frontier-buddy-icon.svg'

import { useTranslation } from 'react-i18next'
import LanguageSwitcher from 'components/common/LanguageSwitcher'

export function updateLocalStorageWithUserToken(token: IUserTokenInfo): void {
  localStorage.setItem(LOCAL_STORAGE_KEYS.access_key, token.access_token)
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.access_key_expires_in,
    token.access_token_expires.toString()
  )
}

export const LoginComponent: React.FC = (): React.ReactElement => {
  const { t } = useTranslation('login')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useAppDispatch()
  const { user, loading, error } = useSelector(
    (state: RootState) => state.authAPI
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (user.token !== undefined) {
      updateLocalStorageWithUserToken(user.token)
      navigate(userLandingPage())
    }
  }, [user.token])

  const handleLogin = (e: React.FormEvent): void => {
    e.preventDefault()
    if (!loading) {
      dispatch(
        loginUser({
          username,
          password
        })
      )
    }
  }

  const handleSSOLogin = (): void => {
    document.title = 'Buddy'
    window.location.replace(samlLoginURL())
  }

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' && !loading) {
      dispatch(
        loginUser({
          username,
          password
        })
      )
    }
  }

  return (
    <Box className='login-wrapper'>
      <Box className='login-outer-container'>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            minHeight: '7rem',
            justifyContent: 'center',
            mx: 'auto',
            alignItems: 'center',
            background: 'rgba(255, 255, 255, 1)',
            gap: 2,
            borderRadius: '8px'
          }}
        >
          <Box component='img' src={FrontierNameLogo} sx={{ maxHeight: 30 }} />
          <Box component='img' src={FrontierBuddyLogo} />
        </Box>
        <Box className='login-container'>
          <Box>
            <LanguageSwitcher></LanguageSwitcher>
            <Typography
              variant='h5'
              sx={{ fontFamily: 'Arial', pb: 2, textAlign: 'center' }}
            >
              <strong>{t('signIn')}</strong> {t('yourAccount')}
            </Typography>
            <form onSubmit={handleLogin} className='login-form'>
              <Box className='input-group'>
                <label htmlFor='email'>{t('username')}</label>
                <TextField
                  sx={{
                    width: '100%'
                  }}
                  id='email'
                  type='email'
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value)
                  }}
                  placeholder={t('username')}
                  onKeyDown={handleKeyDown}
                  disabled={loading}
                />
              </Box>
              <Box className='input-group'>
                <label htmlFor='password'>{t('password')}</label>
                <TextField
                  sx={{ width: '100%' }}
                  id='password'
                  type='password'
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  placeholder={t('password')}
                  onKeyDown={handleKeyDown}
                  disabled={loading}
                />
              </Box>
              <Button onClick={handleLogin} variant='contained' sx={{ mb: 1 }}>
                {loading ? <CircularProgress size={24} /> : t('login')}
              </Button>
              {error !== null && <p style={{ color: 'red' }}>{error}</p>}
            </form>
            <Box className='divider' sx={{ display: 'none' }}>
              <span>{t('or')}</span>
            </Box>
            <Box className='sso-buttons' sx={{ display: 'none' }}>
              <Button
                startIcon={<Microsoft />}
                onClick={() => {
                  handleSSOLogin()
                }}
                variant='contained'
                sx={{
                  mb: 1,
                  backgroundColor: '#F3F3F3',
                  color: '#5E5E5E',
                  '&:hover': {
                    backgroundColor: '#E1E1E1'
                  }
                }}
              >
                {t('continueMS')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LoginComponent
