/* eslint-disable */
export default class APIClient {
  static readonly baseUrl: string
  static readonly onHttp400?: <T>(body: T) => void
  static readonly onHttp401?: <T>(body?: T) => void
  static readonly onHttp403?: <T>(body: T) => void
  static readonly onHttp404?: <T>(body: T) => void
  static readonly onHttp500?: <T>(body: T) => void
}
/* eslint-enable */
