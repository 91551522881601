import { IListResponseWrapper, type IAPIParams } from '../types'
import ApiRequest from '../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IBattleCardCompetitor,
  IBattleCardCompetitorState
} from 'buddy-api/types/BattleCardCompetitor'

const basePath = '/battle-card/competitor/'

export class BattleCardCompetitorAPI {
  public get = async (
    params?: IAPIParams,
    signal?: AbortSignal | null
  ): Promise<IBattleCardCompetitor[]> => {
    const result = await ApiRequest.get<
      IListResponseWrapper<IBattleCardCompetitor>
    >(basePath, signal)
    return result.body.results
  }
}

const battleCardCompetitorAPI = new BattleCardCompetitorAPI()

export const getBattleCardCompetitors = createAsyncThunk(
  'battleCard/getBattleCardCompetitors',
  async (): Promise<IBattleCardCompetitor[]> => {
    return await battleCardCompetitorAPI.get()
  }
)

const initialState: IBattleCardCompetitorState = {
  battle_card_competitors: [],
  loading: false,
  error: null
}

const battleCardCompetitorAPISlice = createSlice({
  name: 'battleCardCompetitors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getBattleCardCompetitors.pending,
        (state: IBattleCardCompetitorState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        getBattleCardCompetitors.fulfilled,
        (state: IBattleCardCompetitorState, action) => {
          state.loading = false
          state.error = null
          state.battle_card_competitors = action.payload
        }
      )
      .addCase(
        getBattleCardCompetitors.rejected,
        (state: IBattleCardCompetitorState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.battle_card_competitors = []
        }
      )
  }
})

export default battleCardCompetitorAPISlice
