import { Typography } from '@mui/material'
import React from 'react'

export const CustomerSearchResultColumns = [
  {
    field: 'account_id',
    renderHeader: () => <Typography variant='h2'>Account ID</Typography>,
    flex: 1,
    headerName: 'Account ID'
  },
  {
    field: 'name',
    renderHeader: () => <Typography variant='h2'>Customer Name</Typography>,
    flex: 1,
    headerName: 'Customer Name',
    valueGetter: (params: any) => {
      return params.row.firstname + ' ' + params.row.lastname
    }
  }
]
