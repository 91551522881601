import React from 'react'
import { Box } from '@mui/system'
import Markdown from 'markdown-to-jsx'

const formatDescription = (value: string) => {
  return value.replace(/\n +/g, '\n')
}

export const defaultFont = {
  color: '#141928',
  fontFamily: 'Arial',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
}

interface IScriptBlock {
  script: string
}

export const ScriptBlock = ({ script }: IScriptBlock) => {
  return (
    <Box
      sx={{
        background: '#F8F8F8',
        padding: '15px',
        border: '1px solid #D8D8D8',
        ...defaultFont
      }}
    >
      <Markdown>{formatDescription(script)}</Markdown>
    </Box>
  )
}
