import React from 'react'
import { Switch } from '@mui/material'
import { CheckButton } from 'components/input/CheckButton'
import { ButtonCol } from '../../components/ButtonCol'
import { ActionType } from 'buddy-api/types/Action'
import { RejectButton } from 'components/input/RejectButton'

interface IOfferItemControls {
  accepted: boolean
  rejected: boolean
  offered: boolean
  type: ActionType
  onOfferedClick: () => void
  onAcceptClick: () => void
  onRejectClick: () => void
}

const displayAcceptReject = (type: ActionType) => {
  if (type === 'Done') return false

  return true
}

export const ActionItemControls = (props: IOfferItemControls) => {
  return (
    <>
      {displayAcceptReject(props.type) ? (
        <>
          <ButtonCol>
            <CheckButton
              checked={props.accepted}
              onClick={props.onAcceptClick}
              acceptedOfferButton={props.accepted}
            />
          </ButtonCol>
          <ButtonCol>
            <RejectButton
              reject={props.rejected}
              onClick={props.onRejectClick}
            />
          </ButtonCol>
        </>
      ) : (
        <>
          <ButtonCol>
            <Switch
              size='small'
              checked={props.offered}
              onChange={props.onOfferedClick}
            />
          </ButtonCol>
          <ButtonCol></ButtonCol>
          <ButtonCol></ButtonCol>
        </>
      )}
    </>
  )
}
