/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { List, ListItem, SelectChangeEvent, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Dropdown } from 'components/input/Dropdown'
import { useAppDispatch, useAppSelector } from 'store/ConfigureStore'
import {
  updateAgentSelectedCallSessionCompetitor,
  updateCallSessionRootCause,
  updateReroute,
  YesNoDropdownItems,
  updatePlan,
  updateDataUsage,
  updateDevice,
  updateLines
} from 'store/callsession/callsessionSlice'
import { renderScripts } from 'utils/scripts'
import { InputRow } from '../../../../components/input/InputRow'
import { IBattleCardCompetitor } from '../../../../buddy-api/types/BattleCardCompetitor'
import { updateBattleCardDropdownSelectedCompetitor } from '../../../../store/battlecard/battlecardSlice'
import { getBattleCard } from '../../../../buddy-api/api/BattleCardAPI'
import CompetitorInput from './ContextSettings/intentFlows/CompetitorInput'
import { fetchCallSessionRootCauseScripts } from '../../../../buddy-api/api/callsession/CallSessionRootCauseScriptAPI'
import { ProductNamesEnum, YesNoOptions } from '../../../../utils/enums'
import { serviceCallAboutLang } from 'utils/properties'
import { InputLabel } from 'components/input/InputLabel'
import { ICallSessionRootCause } from '../../../../buddy-api/types/callsession/CallSessionRootCause'
import { DevicePlanDataDiscovery } from './ContextSettings/intentFlows/DevicePlanDataDiscovery'
import { useTranslation } from 'react-i18next'
import { OffersInstructions } from 'utils/offersInstructions'
import {
  completeSection,
  CallFlowSection,
  incompleteSection,
  openSection,
  closeSection
} from 'store/callflow/collapsableSections'
import { getRelatedLangItem, Languages } from 'store/languages'

type ContextParameters = {
  reroute?: boolean
  rootCause: boolean
  identifyPlan: boolean
  competitor?: boolean
}

export const ContextItemBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  background: '#f8f8f8',
  padding: '20px',
  gap: '1rem'
})

export const ContextContainerBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
})

export const ContextSettingActiveCustomer = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation('contextSettingActiveCustomer')

  const {
    rootCauses,
    battle_card_competitors,
    customerCompetitors,
    rootCause,
    selectedCompetitor,
    customerIntent,
    dropped_product,
    reroute,
    scripts,
    customerAddress,
    selectedLanguage
  } = useAppSelector((state) => ({
    rootCauses: state.callSessionRootCausesAPI.rootCauses,
    battle_card_competitors:
      state.battlecardCompetitorAPI.battle_card_competitors,
    customerCompetitors: state.customerCompetitorAPI.customerCompetitors,
    rootCause: state.agentCallSession.call_session_root_cause,
    selectedCompetitor: state.agentCallSession.selected_callsession_competitor,
    customerIntent: state.agentCallSession.call_session_intent,
    dropped_product: state.agentCallSession.dropped_product,
    reroute: state.agentCallSession.reroute,
    scripts: state.callSessionRootCauseScriptsAPI.scripts,
    customerAddress: state.customerAddressAPI.customerAddress,
    selectedLanguage: state.agentCallSession.selectedLanguage
  }))

  const defaultBattleCardCompetitorId = battle_card_competitors.find(
    (item) => item.name.toLowerCase() === 'other'
  )?.id

  const [finalBattleCardsCompetitors, setFinalBattleCardsCompetitors] =
    React.useState<IBattleCardCompetitor[]>([])

  const selectedValue = {
    plan: useAppSelector((state) => state.agentCallSession.plan),
    device: useAppSelector((state) => state.agentCallSession.device),
    dataUsage: useAppSelector((state) => state.agentCallSession.data_usage),
    lines: useAppSelector((state) => state.agentCallSession.lines)
  }

  const initialStateComplete = {
    rootCause: false,
    identifyPlan: false
  }
  const [contextSettingComplete, setContextSettingComplete] =
    useState<ContextParameters>(initialStateComplete)

  useEffect(() => {
    if (
      selectedValue.plan &&
      selectedValue.device &&
      selectedValue.dataUsage &&
      selectedValue.lines
    ) {
      setContextSettingComplete((prevState) => ({
        ...prevState,
        identifyPlan: true
      }))
    }
  }, [
    selectedValue.plan,
    selectedValue.device,
    selectedValue.dataUsage,
    selectedValue.lines
  ])

  const openOfferAndCompleteContextSetting = () => {
    dispatch(completeSection(CallFlowSection.CONTEXT_SETTING))
    dispatch(openSection(CallFlowSection.OFFERS))
  }

  useEffect(() => {
    if (
      contextSettingComplete.reroute &&
      contextSettingComplete.identifyPlan &&
      contextSettingComplete.rootCause
    ) {
      openOfferAndCompleteContextSetting()
    } else if (
      contextSettingComplete.rootCause &&
      contextSettingComplete.competitor &&
      contextSettingComplete.identifyPlan
    ) {
      openOfferAndCompleteContextSetting()
    } else {
      dispatch(incompleteSection(CallFlowSection.CONTEXT_SETTING))
      dispatch(closeSection(CallFlowSection.OFFERS))
    }
  }, [contextSettingComplete])

  useEffect(() => {
    if (!selectedLanguage || !rootCause) return

    const newRootCause = getRelatedLangItem(
      rootCause.id,
      rootCauses,
      selectedLanguage
    )
    handleNewRootCauseSelected(newRootCause)
    if (!newRootCause) return

    dispatch(
      fetchCallSessionRootCauseScripts({
        root_cause_id: newRootCause.id
      })
    )
  }, [rootCauses])

  const showNonRetention = () => {
    const lang = (i18n.language.split('-')[0] || Languages.ENGLISH) as Languages
    return customerIntent?.name === serviceCallAboutLang[lang]?.NotDeactivation
  }

  const handleCompetitorChange = (event: SelectChangeEvent) => {
    let showDefault = false
    let selectedCompetitor = battle_card_competitors.find(
      (competitor: IBattleCardCompetitor) =>
        `${competitor.id}-${competitor.name}`.toLowerCase() ===
        (event.target.value as unknown as string)
    )
    if (selectedCompetitor === undefined) {
      showDefault = true
      selectedCompetitor = finalBattleCardsCompetitors.find(
        (item) =>
          `${item.id}-${item.name}`.toLowerCase() ===
          (event.target.value as unknown as string)
      )
      const defaultBattleCardCompetitor = battle_card_competitors.find(
        (item) => item.name.toLowerCase() === 'other'
      )
      dispatch(
        updateBattleCardDropdownSelectedCompetitor(defaultBattleCardCompetitor)
      )
    }
    setContextSettingComplete((prevState) => ({
      ...prevState,
      competitor: true
    }))
    dispatch(updateAgentSelectedCallSessionCompetitor(selectedCompetitor))
    if (selectedCompetitor) {
      if (!showDefault) {
        dispatch(updateBattleCardDropdownSelectedCompetitor(selectedCompetitor))
      }

      let competitor_id = selectedCompetitor.id
      if (showDefault && defaultBattleCardCompetitorId) {
        competitor_id = defaultBattleCardCompetitorId
      }

      if (customerAddress?.state ?? 'all') {
        dispatch(
          getBattleCard({
            competitor_id,
            state_code: 'all'
          })
        )
      }
    }
  }

  const handleRerouteChange = (event: SelectChangeEvent) => {
    const rerouteOption = YesNoDropdownItems.find(
      (option) => option.id === (event.target.value as unknown as number)
    )
    setContextSettingComplete((prevState) => ({
      ...prevState,
      reroute: true
    }))
    dispatch(updateReroute(rerouteOption))
  }

  const handleRootCauseChange = (event: SelectChangeEvent) => {
    const rc = rootCauses?.find(
      (rootCause) => rootCause.id === (event.target.value as unknown as number)
    )
    handleNewRootCauseSelected(rc)
    if (rc) {
      dispatch(
        fetchCallSessionRootCauseScripts({
          root_cause_id: rc.id
        })
      )
      dispatch(updatePlan(null))
      dispatch(updateDataUsage(null))
      dispatch(updateDevice(null))
      dispatch(updateLines('1'))
      setContextSettingComplete((prevState) => ({
        ...prevState,
        identifyPlan: false
      }))
    }
  }

  const handleNewRootCauseSelected = (rootCause: any) => {
    dispatch(updateCallSessionRootCause(rootCause))
    setContextSettingComplete((prevState) => ({
      ...prevState,
      rootCause: true
    }))
  }

  const noCompetitor = {
    id: 0,
    name: 'No competitor considered'
  }

  const getBattleCardCompetitors = useMemo(() => {
    let finalCompetitorList = battle_card_competitors
    finalCompetitorList = finalCompetitorList.concat(noCompetitor)

    const customerCompetitorList =
      customerCompetitors?.map((competitor) => {
        return competitor.competitor // Return the 'competitor' object
      }) ?? []
    if (customerCompetitorList.length > 0) {
      const filteredBattleCardCompetitor = customerCompetitorList?.filter(
        (item2) =>
          !finalCompetitorList.some(
            (item1) => item1.name.toLowerCase() === item2.name.toLowerCase()
          )
      )
      finalCompetitorList = finalCompetitorList.concat(
        ...filteredBattleCardCompetitor
      )
    }
    setFinalBattleCardsCompetitors(finalCompetitorList)

    return finalCompetitorList.map((competitor: IBattleCardCompetitor) => ({
      id: `${competitor.id}-${competitor.name}`.toLowerCase(),
      label: competitor.name,
      name: competitor.name
    }))
  }, [battle_card_competitors, customerCompetitors])

  /**
   * Handles the change event for the sub root cause selection.
   * Update subRootCause state variable with checked item and vice-versa
   * @param event - The change event object.
  const handleSubRootCauseChange = (
    event: SelectChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = (event.target as HTMLInputElement).checked
    const subrc = AccountServicesNonRetention.find(
      (src) => src.id === Number(event.target.value)
    )

    if (isChecked) {
      const updatedSubRootCause = subRootCause
        ? subRootCause.split(',').concat(subrc?.name || [])
        : [subrc?.name || '']
      dispatch(updateCallSessionSubRootCause(updatedSubRootCause.join(',')))
    } else if (subRootCause && subrc?.name) {
      const updatedSubRootCause = subRootCause
        .split(',')
        .filter((name) => name !== subrc.name)
      dispatch(updateCallSessionSubRootCause(updatedSubRootCause.join(',')))
    }
  }*/

  const filterRootCauses = () => {
    return rootCauses.filter((rootCause: ICallSessionRootCause) => {
      if (showNonRetention()) {
        return rootCause
      }
      return !(
        dropped_product &&
        !(
          dropped_product.label.includes(ProductNamesEnum.video) ||
          dropped_product.label.includes(ProductNamesEnum.voice)
        )
      )
    })
  }

  /*const subRootCauseItems = AccountServicesNonRetention.map((subrc) => (
    <FormControlLabel
      key={subrc.id}
      control={
        <Checkbox
          sx={{
            color: theme.palette.primary.light,
            '&.Mui-checked': {
              color: theme.palette.primary.light
            }
          }}
          checked={Boolean(
            subRootCause?.split(',').includes(subrc.name.toString())
          )}
          onChange={(event) => handleSubRootCauseChange(event)}
        />
      }
      label={subrc.name}
      value={subrc.id.toString()}
    />
  ))*/

  const renderListItem = (primaryText: string) => (
    <ListItem sx={{ display: 'list-item' }}>{primaryText}</ListItem>
  )

  const rootCauseColumn = (
    <ContextContainerBox>
      <ContextItemBox>
        <InputLabel>{t('rootCauseLabel')}</InputLabel>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2
          }}
        >
          {renderListItem(t('rootCauseMsg1'))}
          {renderListItem(t('rootCauseMsg2'))}
        </List>
        <InputRow
          label={t('rootCauseLabel')}
          required={true}
          inputElem={
            <Dropdown
              id='customer-intent-dropdown'
              value={rootCause?.id.toString()}
              items={filterRootCauses()}
              onChange={handleRootCauseChange}
              error={rootCause === null}
            />
          }
        />
        {rootCause && renderScripts(scripts)}
      </ContextItemBox>

      {rootCause && (
        <ContextItemBox>
          <DevicePlanDataDiscovery />
          <OffersInstructions />
        </ContextItemBox>
      )}

      {/* TODO: Uncomment when Discovery selection logic was implemented on UsageQuestions  */}
      {/* {rootCause?.name != rootCauseMap.customerService.name &&
        !showNonRetention() && (
          <ContextItemBox>
            <Stack gap={2}>
              <UsageQuestions />
            </Stack>{' '}
          </ContextItemBox>
        )} */}
    </ContextContainerBox>
  )

  const getCompetitorId = (): string => {
    if (!selectedCompetitor) {
      return ''
    } else {
      return `${selectedCompetitor?.id}-${selectedCompetitor?.name}`.toLowerCase()
    }
  }

  const competitorColumn = (
    <ContextContainerBox
      sx={{
        opacity: showNonRetention() ? '40%' : '100%'
      }}
    >
      <ContextItemBox>
        <InputLabel>{t('competitorInfoLabel')}</InputLabel>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2
          }}
        >
          {renderListItem(t('competitorQuestion1'))}
          {renderListItem(t('competitorQuestion2'))}
          {renderListItem(t('competitorQuestion3'))}
        </List>
        <InputRow
          label={t('competitorLabel')}
          required={!showNonRetention()}
          inputElem={
            <Dropdown
              disabled={showNonRetention()}
              id='competitor-dropdown'
              value={getCompetitorId()}
              onChange={handleCompetitorChange}
              items={getBattleCardCompetitors}
              dividerIndex={battle_card_competitors.length - 2}
              error={selectedCompetitor === null && !showNonRetention()}
            />
          }
        />
        {selectedCompetitor === null ||
          (selectedCompetitor?.id === defaultBattleCardCompetitorId && (
            <Box gap={2} display={'flex'} flexDirection={'column'}>
              <Typography variant='subtitle1'>{t('noCompetitor')}</Typography>
              <CompetitorInput />
            </Box>
          ))}
        {(selectedCompetitor?.name === noCompetitor.name ||
          selectedCompetitor === null) && (
          <Typography variant='subtitle1'>{t('competitorPrompt')}</Typography>
        )}
      </ContextItemBox>
    </ContextContainerBox>
  )

  const deactivationOrNoReroute = (
    <Box display='flex' gap={2}>
      <Box flex='1'>
        <Stack direction='column'>{rootCauseColumn}</Stack>
      </Box>
      <Box flex='1'>
        <Stack direction='column' alignItems='center'>
          {competitorColumn}
        </Stack>
      </Box>
    </Box>
  )

  const listStyleReRoute = {
    paddingTop: '0',
    paddingBottom: '0',
    listStyleType: 'disc',
    pl: 2
  }

  const noDeactivate = (
    <>
      <Stack direction='row' gap={0}>
        <Stack
          direction='column'
          gap={0}
          sx={{
            background: '#f8f8f8',
            padding: '20px'
          }}
        >
          <strong>{t('negativeFeedbackTitle')}</strong>
          <List sx={listStyleReRoute}>
            {renderListItem(t('negativeFeedbackMsg'))}
          </List>
          <strong>{t('simpleQuestionTitle')}</strong>
          <List
            sx={{
              listStyleType: 'disc',
              pl: 2
            }}
          >
            {renderListItem(t('simpleQuestionMsg'))}
          </List>

          <Stack gap={3}>
            {t('rerouteTitle')}
            <List
              sx={{
                listStyleType: 'disc',
                pl: 2
              }}
            >
              {renderListItem(t('rerouteMsg'))}
            </List>
            <InputRow
              label={t('rerouteLabel')}
              required={true}
              inputElem={
                <Dropdown
                  id='reroute-dropdown'
                  value={reroute?.id.toString()}
                  items={YesNoDropdownItems}
                  onChange={handleRerouteChange}
                />
              }
              inputWidth='150px'
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' gap={5}>
        {reroute?.name === YesNoOptions.yes && (
          <Box>
            <strong>{t('rerouteToTech')}</strong>
          </Box>
        )}
      </Stack>
      {reroute?.name === YesNoOptions.no && deactivationOrNoReroute}
    </>
  )

  return (
    <Stack
      sx={{
        marginTop: '7px', // to compensate the accordeon topPadding of 9px, and have a total gap of 16px like other blocks
        borderRadius: '3px'
      }}
      gap={1}
      direction='column'
    >
      {/* {customerIntent?.name === ServiceCallAbout.NotDeactivation && dropped_product !== null
        ? noDeactivate
        : null}
      {customerIntent?.name === ServiceCallAbout.Deactivation && dropped_product !== null ? (
        <Stack direction='row' gap={2}>
          {deactivationOrNoReroute}
        </Stack>
      ) : null} */}

      {showNonRetention() ? (
        noDeactivate
      ) : (
        <Stack direction='row' gap={2}>
          {deactivationOrNoReroute}
        </Stack>
      )}
    </Stack>
  )
}
