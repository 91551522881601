import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICallSessionRootCauseScript,
  ICallSessionRootCauseScriptState
} from '../../types/callsession/CallSessionRootCauseScript'

const basePath = '/callsession-root-cause-script/'

export interface CallSessionRootCauseScriptParams extends IAPIParams {
  root_cause_id: number
}

export class CallSessionRootCauseScriptAPI {
  public list = async (
    params?: CallSessionRootCauseScriptParams,
    signal?: AbortSignal | null
  ): Promise<ICallSessionRootCauseScript[]> => {
    const result = await ApiRequest.get<ICallSessionRootCauseScript[]>(
      basePath + 'list/',
      signal,
      params
    )
    return result.body
  }
}

const callSessionRootCauseScriptAPI = new CallSessionRootCauseScriptAPI()

export const fetchCallSessionRootCauseScripts = createAsyncThunk(
  'callSessionRootCauseScript/fetchCallSessionRootCauseScripts',
  async (
    params: CallSessionRootCauseScriptParams
  ): Promise<ICallSessionRootCauseScript[]> => {
    return await callSessionRootCauseScriptAPI.list(params)
  }
)

const initialState: ICallSessionRootCauseScriptState = {
  scripts: [],
  loading: false,
  error: null
}

const callSessionRootCauseScriptsAPISlice = createSlice({
  name: 'callsessionRootCauseScript',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCallSessionRootCauseScripts.pending,
        (state: ICallSessionRootCauseScriptState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        fetchCallSessionRootCauseScripts.fulfilled,
        (state: ICallSessionRootCauseScriptState, action) => {
          state.loading = false
          state.error = null
          state.scripts = action.payload
        }
      )
      .addCase(
        fetchCallSessionRootCauseScripts.rejected,
        (state: ICallSessionRootCauseScriptState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.scripts = []
        }
      )
  }
})

export default callSessionRootCauseScriptsAPISlice
