import React, { useEffect } from 'react'
import {
  DataGridPro,
  type GridColumns,
  GridSortDirection
} from '@mui/x-data-grid-pro'
import { RecentCustomerInteractionColumns } from './RecentCustomerInteractionColumns'
import {
  type RootState,
  useAppDispatch,
  useAppSelector
} from '../../store/ConfigureStore'
import { fetchCallSessions } from '../../buddy-api/api/callsession/CallSessionAPI'
import { callSessionPageURL } from '../../routes/routes'
import { useNavigate } from 'react-router'
import { ICallSession } from '../../buddy-api'
import config from '../../config'
import { useTranslation } from 'react-i18next'

const RecentCustomerInteraction = (): React.ReactElement => {
  const { t } = useTranslation('recentCustomerInteractionColumns')
  const callsessionDispatch = useAppDispatch()
  const { callSessions, loading, error } = useAppSelector(
    (state: RootState) => state.callSessionsAPI
  )
  const { user } = useAppSelector((state: RootState) => state.authAPI)
  const navigate = useNavigate()

  useEffect(() => {
    callsessionDispatch(fetchCallSessions({ user_id: user.id }))
  }, [callsessionDispatch])

  const recentCustomerInteractionsColumns: GridColumns<ICallSession> =
    React.useMemo(() => RecentCustomerInteractionColumns(t), [t])

  const openCallSession = (params: any) => {
    if (config.app.env !== 'dev') {
      navigate(
        callSessionPageURL(params.row.account_id, params.row.account_number)
      )
    }
  }

  const initialState = {
    sorting: {
      sortModel: [{ field: 'start_at', sort: 'desc' as GridSortDirection }]
    }
  }

  return (
    <DataGridPro
      sx={{
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer'
        }
      }}
      loading={loading}
      error={error}
      rows={callSessions}
      columns={recentCustomerInteractionsColumns}
      headerHeight={40}
      rowHeight={52}
      getRowId={(row) => row.id ?? 0}
      pageSize={10}
      rowsPerPageOptions={[10]}
      pagination={true}
      onRowClick={openCallSession}
      initialState={initialState} // Set initial state with sort model
    />
  )
}

export default RecentCustomerInteraction
