import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerAccountItem,
  ICustomerAccountItemState
} from '../../types/customer/CustomerAccountItem'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-account-item/'

export interface GetCustomerAccountItemParams extends IAPIParams {
  account_id: string
  repository?: Repository
}

export class CustomerAccountItemAPI {
  public get = async (
    params: GetCustomerAccountItemParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerAccountItem[]> => {
    const result = await ApiRequest.get<ICustomerAccountItem[]>(
      basePath + params.account_id + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerAccountItemAPI = new CustomerAccountItemAPI()

export const getCustomerAccountItems = createAsyncThunk(
  'getCustomerAccountItems',
  async (
    params: GetCustomerAccountItemParams
  ): Promise<ICustomerAccountItem[]> => {
    return await customerAccountItemAPI.get(params)
  }
)

const initialState: ICustomerAccountItemState = {
  account_items: [],
  loading: false,
  error: null
}

const customerAccountItemSlice = createSlice({
  name: 'customer_account_item',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCustomerAccountItems.pending,
        (state: ICustomerAccountItemState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        getCustomerAccountItems.fulfilled,
        (state: ICustomerAccountItemState, action) => {
          state.loading = false
          state.error = null
          state.account_items = action.payload
        }
      )
      .addCase(
        getCustomerAccountItems.rejected,
        (state: ICustomerAccountItemState) => {
          state.loading = false
          state.error = 'Failed to fetch customer account items.'
          state.account_items = []
        }
      )
  }
})

export default customerAccountItemSlice
