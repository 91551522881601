import React from 'react'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  ContextContainerBox,
  ContextItemBox
} from './ContextSettingActiveCustomer'
import { useTranslation } from 'react-i18next'

export const ClosingRemarksSection = () => {
  const { t } = useTranslation('closingRemarks')

  return (
    <Stack
      sx={{
        padding: '17px', // to compensate the accordeon topPadding of 9px, and have a total gap of 16px like other blocks
        borderRadius: '3px',
        background: '#f8f8f8'
      }}
      gap={1}
      direction='column'
    >
      <ContextContainerBox>
        <ContextItemBox sx={{ background: '#f8f8f8' }}>
          <ul>
            <li>
              <Typography>{t('reviewActions')}</Typography>
            </li>
          </ul>
          <ul>
            <li>
              <Typography>{t('anythingElse')}</Typography>
            </li>
          </ul>
          <Typography>{t('transferToDept')}</Typography>
          <ul>
            <li>
              <Typography>{t('thankCustomer')}</Typography>
            </li>
          </ul>
        </ContextItemBox>
      </ContextContainerBox>
    </Stack>
  )
}
