import { combineReducers, configureStore } from '@reduxjs/toolkit'
import callSessionsReducer from '../buddy-api/api/callsession/CallSessionAPI'
import authAPISlice from '../buddy-api/api/AuthAPI'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import customerSearchAPISlice from '../buddy-api/api/customer/CustomerSearchAPI'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import customerAPISlice from '../buddy-api/api/customer/CustomerAPI'
import customerAccountItemAPISlice from '../buddy-api/api/customer/CustomerAccountItem'
import { agentCallSessionSlice } from './callsession/callsessionSlice'
import callSessionOutcomeAPI from '../buddy-api/api/callsession/CallSessionOutcomeAPI'
import customerCompetitorAPISlice from '../buddy-api/api/customer/CustomerCompetitorAPI'
import battleCardApiSlice from '../buddy-api/api/BattleCardAPI'
import { collapsableSectionsSlice } from './callflow/collapsableSections'
import settingAPISlice from '../buddy-api/api/SettingAPI'
import customerProductMetricsAPISlice from '../buddy-api/api/customer/CustomerProductMetric'
import customerCoreServiceAPISlice from '../buddy-api/api/customer/CustomerCoreServiceAPI'
import { callFlowSlice } from './callflow/callFlow'
import { customerEmailAPISlice } from '../buddy-api/api/customer/CustomerEmailAPI'
import { customerPhoneAPISlice } from '../buddy-api/api/customer/CustomerPhoneAPI'
import { customerAddressAPISlice } from '../buddy-api/api/customer/CustomerAddressAPI'
import customerServiceBillingAPISlice from '../buddy-api/api/customer/CustomerServiceBillingAPI'
import { customerPaymentAPISlice } from '../buddy-api/api/customer/CustomerPaymentAPI'
import customerBillDocumentAPISlice from 'buddy-api/api/customer/CustomerBillDocument'
import { customerOrderAPISlice } from '../buddy-api/api/customer/CustomerOrderAPI'
import callSessionIntentsAPISlice from '../buddy-api/api/callsession/CallSessionIntentAPI'
import productAPISlice from '../buddy-api/api/ProductAPI'
import callSessionRootCausesAPISlice from '../buddy-api/api/callsession/CallSessionRootCauseAPI'
import callSessionUsageQuestionPerCategoryAPISlice from '../buddy-api/api/callsession/CallSessionUsageQuestionAPI'
import battleCardCompetitorAPISlice from '../buddy-api/api/BattleCardCompetitorAPI'
import { battlecardDropdownSlice } from './battlecard/battlecardSlice'
import callSessionNewTechOptionsAPISlice from '../buddy-api/api/callsession/CallSessionNewTechOptionAPI'
import callSessionNewTechScriptsAPISlice from '../buddy-api/api/callsession/CallSessionNewTechScriptAPI'
import actionsAPISlice from '../buddy-api/api/ActionAPI'
import callSessionRootCauseScriptsAPISlice from '../buddy-api/api/callsession/CallSessionRootCauseScriptAPI'

import { callFlowValidationSlice } from './callflow/validation/validation'
import callSessionOfferedOptionsAPISlice from '../buddy-api/api/callsession/CallSessionOfferedOptions'
import callSessionCustomerMoveTypesAPISlice from '../buddy-api/api/callsession/CallSessionCustomerMoveType'
import offersAPISlice from '../buddy-api/api/OfferAPI'
import { accountItemsSectionsSlice } from './topbar/accountItemSections'
import topBarCollapseSlice from './topbar/topBarCollapse'
import customerBillAPISlice from '../buddy-api/api/customer/CustomerBill'
import callSessionWinbackSpeedsAPISlice from 'buddy-api/api/callsession/CallSessionWinbackSpeedAPI'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authAPI', 'agentCallSession']
}

const rootReducer = combineReducers({
  callSessionsAPI: callSessionsReducer.reducer,
  authAPI: authAPISlice.reducer,
  customerSearchAPI: customerSearchAPISlice.reducer,
  customerAPI: customerAPISlice.reducer,
  customerAccountItemAPI: customerAccountItemAPISlice.reducer,
  agentCallSession: agentCallSessionSlice.reducer,
  callSessionOutcomeAPI: callSessionOutcomeAPI.reducer,
  customerCompetitorAPI: customerCompetitorAPISlice.reducer,
  battlecardCompetitorAPI: battleCardCompetitorAPISlice.reducer,
  battlecardDropdownSlice: battlecardDropdownSlice.reducer,
  battleCardAPI: battleCardApiSlice.reducer,
  collapsableSections: collapsableSectionsSlice.reducer,
  accountItemSections: accountItemsSectionsSlice.reducer,
  settingAPI: settingAPISlice.reducer,
  customerProductMetricsAPI: customerProductMetricsAPISlice.reducer,
  customerCoreServiceAPI: customerCoreServiceAPISlice.reducer,
  callFlow: callFlowSlice.reducer,
  customerEmailAPI: customerEmailAPISlice.reducer,
  customerPhoneAPI: customerPhoneAPISlice.reducer,
  customerAddressAPI: customerAddressAPISlice.reducer,
  customerServiceBillingAPI: customerServiceBillingAPISlice.reducer,
  customerPaymentAPI: customerPaymentAPISlice.reducer,
  customerBillDocument: customerBillDocumentAPISlice.reducer,
  customerOrderAPI: customerOrderAPISlice.reducer,
  callSessionIntentAPI: callSessionIntentsAPISlice.reducer,
  productAPI: productAPISlice.reducer,
  callSessionRootCausesAPI: callSessionRootCausesAPISlice.reducer,
  callSessionUsageQuestionPerCategoryAPI:
    callSessionUsageQuestionPerCategoryAPISlice.reducer,
  callSessionNewTechOptionsAPI: callSessionNewTechOptionsAPISlice.reducer,
  callSessionNewTechScriptsAPI: callSessionNewTechScriptsAPISlice.reducer,
  callSessionOfferedOptionsAPI: callSessionOfferedOptionsAPISlice.reducer,
  actionsAPISlice: actionsAPISlice.reducer,
  callSessionRootCauseScriptsAPI: callSessionRootCauseScriptsAPISlice.reducer,
  callFlowValidation: callFlowValidationSlice.reducer,
  callSessionCustomerMoveTypesAPI: callSessionCustomerMoveTypesAPISlice.reducer,
  callSessionWinbackSpeedsAPI: callSessionWinbackSpeedsAPISlice.reducer,
  offersAPI: offersAPISlice.reducer,
  topBarCollapse: topBarCollapseSlice.reducer,
  customerBillAPI: customerBillAPISlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE']
      }
    })
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
