import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { IconButton } from '@mui/material'

interface ICheckButton {
  checked: boolean
  onClick: () => void
  disabled?: boolean
  acceptedOfferButton?: boolean
}

export const CheckButton = ({
  checked,
  onClick,
  disabled,
  acceptedOfferButton
}: ICheckButton) => {
  const returnIconColor = () => {
    if (disabled) {
      return '#d8d8d8'
    }
    if (acceptedOfferButton) {
      return 'green'
    }
    return undefined
  }

  return (
    <IconButton sx={{ padding: '0px' }} onClick={onClick} disabled={disabled}>
      {checked ? (
        <CheckCircleIcon color='primary' sx={{ color: returnIconColor }} />
      ) : (
        <CheckCircleOutlineIcon sx={{ color: '#d8d8d8' }} />
      )}
    </IconButton>
  )
}
