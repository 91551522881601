import { styled } from '@mui/material/styles'

export const InputLabel = styled('span')({
  color: '#141928',
  fontFfamily: 'Arial',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase'
})
