import config from 'config'
import SideBar from 'pages/sidebar/BuddySideBar'

import LoginPage from 'pages/login/LoginPage'
import React from 'react'
import { Outlet, Route, Routes } from 'react-router'
import ProtectedRoute, { type ProtectedRouteProps } from './AuthenticatedRoute'
import { loginPageURL } from './routes'
import { NotFoundPage } from 'pages'
import InboundOpportunities from '../pages/inbound-opportunity/InboundOpportunityPage'
import { type RootState, useAppSelector } from '../store/ConfigureStore'
import { CallSessionPage } from '../pages/callsession/CallSessionPage'
import { AdminPanel } from '../pages/admin/AdminPanel'
import { CaptureToken } from '../pages/login/CaptureAccessTokenSSO'

const RouteElement: React.FC = () => {
  return <SideBar />
}

const MainRouter: React.FC = () => {
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.authAPI.user.isAuthenticated
  )

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated,
    authenticationPath: loginPageURL()
  }

  return (
    <Routes>
      <Route path={`${config.app.basePath}`} element={<Outlet />}>
        <Route path='accessToken' element={<CaptureToken />} />
        <Route
          path='user'
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<RouteElement />}
            />
          }
        >
          <Route
            index
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<InboundOpportunities />}
              />
            }
          />
          <Route
            path='callsession'
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<CallSessionPage />}
              />
            }
          />
          <Route
            path='callsession/:account_id'
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<CallSessionPage />}
              />
            }
          />
          <Route
            path='admin'
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<AdminPanel />}
              />
            }
          />
        </Route>
        <Route path='login' element={<LoginPage />} />
        <Route path='' element={<NotFoundPage />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}

export default MainRouter
