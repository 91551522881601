import {
  ICustomerPhone,
  ICustomerPhoneState
} from '../../types/customer/CustomerPhone'
import { IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-phone/'

export interface GetCustomerPhoneParam extends IAPIParams {
  account_id: string
  repository?: Repository
}

export class CustomerPhoneAPI {
  public get = async (
    params: GetCustomerPhoneParam,
    signal?: AbortSignal | null
  ): Promise<ICustomerPhone> => {
    const result = await ApiRequest.get<ICustomerPhone>(
      basePath + params.account_id + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerPhoneAPI = new CustomerPhoneAPI()

export const getCustomerPhone = createAsyncThunk(
  'customer/getCustomerPhone',
  async (params: GetCustomerPhoneParam): Promise<ICustomerPhone> => {
    return await customerPhoneAPI.get(params)
  }
)

const initialState: ICustomerPhoneState = {
  customerPhone: null,
  loading: false,
  error: null
}

export const customerPhoneAPISlice = createSlice({
  name: 'customerPhone',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerPhone.pending, (state: ICustomerPhoneState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        getCustomerPhone.fulfilled,
        (state: ICustomerPhoneState, action) => {
          state.loading = false
          state.error = null
          state.customerPhone = action.payload
        }
      )
      .addCase(getCustomerPhone.rejected, (state: ICustomerPhoneState) => {
        state.loading = false
        state.error = 'Failed to fetch customer phone number.'
        state.customerPhone = null
      })
  }
})

export default customerPhoneAPISlice.reducer
