import * as env from 'env-var'

export default {
  basePath: env.get('REACT_APP_BASE_PATH').default('').asString(),
  idpLoginURL: env.get('REACT_APP_IDP_LOGIN_URL').default('').asString(),
  env: env.get('REACT_APP_ENV').default('local').asString(),
  muiKey: env.get('REACT_APP_MUI_KEY').default('').asString(),
  launchDarklyKey: env
    .get('REACT_APP_LAUNCH_DARKLY_KEY')
    .default('')
    .asString(),
  googleMapsKey: env.get('REACT_APP_GOOGLE_MAPS_API_KEY').default('').asString()
}
