import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    supportedLngs: ['en', 'es'],
    fallbackLng: 'en',
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: [
        'localStorage',
        'cookie',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ],
      caches: ['localStorage', 'cookie'],
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      lookupLocalStorage: 'i18nextLng'
    },
    react: {
      useSuspense: false
    }
  })

i18n.on('languageChanged', (lng) => {
  const baseLanguage = lng.split('-')[0]
  if (lng !== baseLanguage) {
    localStorage.setItem('i18nextLng', baseLanguage)
  }
})

export default i18n
