import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICallsessionOfferedOption,
  ICallsessionOfferedOptionsState
} from '../../types/callsession/OfferedOptions'

const basePath = '/callsession-offer-options/'

export class CallSessionOfferedOptionsAPI {
  public list = async (
    params?: IAPIParams,
    signal?: AbortSignal | null
  ): Promise<ICallsessionOfferedOption[]> => {
    const result = await ApiRequest.get<ICallsessionOfferedOption[]>(
      basePath + 'list/',
      signal
    )
    return result.body
  }
}

const callSessionOfferedOptionsAPI = new CallSessionOfferedOptionsAPI()

export const fetchCallSessionOfferedOptions = createAsyncThunk(
  'callSessionOfferedOptions/fetchCallSessionOfferedOptions',
  async (): Promise<ICallsessionOfferedOption[]> => {
    return await callSessionOfferedOptionsAPI.list()
  }
)

const initialState: ICallsessionOfferedOptionsState = {
  options: [],
  loading: false,
  error: null
}

const callSessionOfferedOptionsAPISlice = createSlice({
  name: 'callsessionOfferedOptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCallSessionOfferedOptions.pending,
        (state: ICallsessionOfferedOptionsState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        fetchCallSessionOfferedOptions.fulfilled,
        (state: ICallsessionOfferedOptionsState, action) => {
          state.loading = false
          state.error = null
          state.options = action.payload
        }
      )
      .addCase(
        fetchCallSessionOfferedOptions.rejected,
        (state: ICallsessionOfferedOptionsState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.options = []
        }
      )
  }
})

export default callSessionOfferedOptionsAPISlice
