import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TopBarCollapseState {
  isCollapsed: boolean
  pinned: boolean
}

export const initialTopBarCollapseState: TopBarCollapseState = {
  isCollapsed: false,
  pinned: false
}

export const topBarCollapseSlice = createSlice({
  name: 'topBarCollapse',
  initialState: initialTopBarCollapseState,
  reducers: {
    updateTopBarCollapse: (
      state: TopBarCollapseState,
      action: PayloadAction<boolean>
    ) => {
      state.isCollapsed = action.payload
    },
    updateTopBarPinned: (
      state: TopBarCollapseState,
      action: PayloadAction<boolean>
    ) => {
      state.pinned = action.payload
    }
  }
})

export const { updateTopBarCollapse, updateTopBarPinned } =
  topBarCollapseSlice.actions
export default topBarCollapseSlice
