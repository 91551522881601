import { Typography } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid'
import React from 'react'
import { useAppSelector } from 'store/ConfigureStore'

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)

  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)

  // Adjust the formatting to match the exact desired output
  const [month, day, year] = formattedDate.split(',')[0].split('/')
  const time = formattedDate.split(', ')[1]

  return `${month}/${day}/${year} - ${time}`
}

const getRootCauseLabel = (rootCauseId: number): string => {
  const rootCauses = useAppSelector(
    (state) => state.callSessionRootCausesAPI.rootCauses
  )

  const currentRootCause = rootCauses.find(
    (rootCause) => rootCause.id === rootCauseId
  )
  return currentRootCause ? currentRootCause.label : ''
}

export const RecentCustomerInteractionColumns = (
  // eslint-disable-next-line no-unused-vars
  t: (key: string) => string
) => {
  return [
    {
      field: 'start_at',
      renderHeader: () => (
        <Typography variant='h2'>{t('interactionDate')}</Typography>
      ),
      flex: 1,
      headerName: t('interactionDate'),
      renderCell: (params: GridCellParams) => {
        return (
          <Typography variant='body1'>
            {formatDate(params.row.start_at)}
          </Typography>
        )
      }
    },
    {
      field: 'account_id',
      renderHeader: () => (
        <Typography variant='h2'>{t('accountId')}</Typography>
      ),
      flex: 1,
      headerName: t('accountId')
    },
    {
      field: 'root_cause_name',
      renderHeader: () => (
        <Typography variant='h2'>{t('rootCauseName')}</Typography>
      ),
      flex: 1,
      headerName: t('rootCauseName'),
      renderCell: (params: GridCellParams) => {
        const rootCauseName = getRootCauseLabel(params.row.root_cause_id)
        return <Typography variant='body1'>{rootCauseName}</Typography>
      }
    },
    {
      field: 'outcome_name',
      renderHeader: () => <Typography variant='h2'>{t('outcome')}</Typography>,
      flex: 1,
      headerName: t('outcome'),
      valueGetter: (params: any) => params.row.outcome?.name
    },
    {
      field: 'comments',
      renderHeader: () => <Typography variant='h2'>{t('comments')}</Typography>,
      flex: 1,
      headerName: t('comments')
    }
  ]
}
