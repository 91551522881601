import { createSlice } from '@reduxjs/toolkit'
import offerLadderLookup from './offerLadderTypeLookup.json'
import offerLogicLookup from './offerLogicLookup.json'
import { offerSets } from './offers'
import { resetAgentCallSession } from 'store/callsession/callsessionSlice'
import { closeAllSections } from './collapsableSections'
import { resetCallSessionUsageQuestionPerCategory } from 'buddy-api/api/callsession/CallSessionUsageQuestionAPI'
import { resetBattleCard } from 'buddy-api/api/BattleCardAPI'
import { resetNewTechScript } from 'buddy-api/api/callsession/CallSessionNewTechScriptAPI'
import { resetBattleCardDropdownSelectedCompetitor } from 'store/battlecard/battlecardSlice'
import { resetActions } from 'buddy-api/api/ActionAPI'
import { resetOffers } from 'buddy-api/api/OfferAPI'
import { closeAllAccountItemSections } from 'store/topbar/accountItemSections'
import { AppDispatch } from 'store/ConfigureStore'

export interface IOffer {
  type: string
  script: string
  product: 'BB' | 'Video' | 'Voice'
}
export interface IOfferSet {
  id: string
  product: 'BB' | 'Video' | 'BB Migration' | 'VAS' | 'Voice' | 'ACP'
  offers: IOffer[]
}

export interface IAccountService {
  id: string | number
  name: string
  label: string
}

export interface IOfferType {
  id: string
  value: string
}

const initialState: {
  offerLadderLookup: any[]
  offerLogicLookup: any[]
  offerSets: IOfferSet[]
} = {
  offerLadderLookup,
  offerLogicLookup,
  offerSets
}

export const callFlowSlice = createSlice({
  name: 'callFlow',
  initialState,
  reducers: {}
})

export const resetCallFlowState = () => (dispatch: AppDispatch) => {
  dispatch(resetAgentCallSession())
  dispatch(closeAllSections())
  dispatch(resetCallSessionUsageQuestionPerCategory())
  dispatch(resetBattleCard())
  dispatch(resetNewTechScript())
  dispatch(resetBattleCardDropdownSelectedCompetitor())
  dispatch(resetActions())
  dispatch(resetOffers())

  dispatch(closeAllAccountItemSections())
}
