import { Box, Paper, SelectChangeEvent } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/ConfigureStore'
import { updateAgentCallSessionOutcome } from '../../store/callsession/callsessionSlice'
import { ICallSessionOutcome } from '../../buddy-api/types/callsession/CallSessionOutcome'
import { Dropdown } from '../../components/input/Dropdown'
import { InputRow } from '../../components/input/InputRow'
import { useTranslation } from 'react-i18next'
import {
  CallFlowSection,
  completeSection,
  openSection
} from 'store/callflow/collapsableSections'
import { Required } from 'components/input/Required'
import { getRelatedLangItem } from 'store/languages'

const SessionOutcomePanel: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('sessionOutcomePanel')

  const { outcome, callSessionOutcomes, selectedLanguage } = useAppSelector(
    (state) => ({
      outcome: state.agentCallSession.outcome,
      callSessionOutcomes: state.callSessionOutcomeAPI.callSessionOutcomes,
      selectedLanguage: state.agentCallSession.selectedLanguage
    })
  )

  const [outcomeValue, setOutcomeValue] = useState<string>()

  // Save outcome on callsession state
  const updateCallSessionOutcome = useCallback(
    (outcomeID: number) => {
      const outcome = callSessionOutcomes?.find((o) => o.id === outcomeID)
      if (!outcome) return

      dispatch(updateAgentCallSessionOutcome(outcome))
    },
    [dispatch, callSessionOutcomes]
  )

  const formatOutcomes = useCallback(() => {
    return callSessionOutcomes?.map((outcome: ICallSessionOutcome) => ({
      id: outcome.id,
      name: outcome.name,
      label: outcome.name
    }))
  }, [callSessionOutcomes])

  // Responsible to update outcome on language change
  useEffect(() => {
    if (!selectedLanguage || !outcome) return

    const newOutcome =
      getRelatedLangItem(outcome.id, callSessionOutcomes, selectedLanguage) ||
      outcome
    updateCallSessionOutcome(newOutcome.id)
    setOutcomeValue(newOutcome.id)
  }, [callSessionOutcomes])

  const onOutcomeChange = (e: SelectChangeEvent) => {
    const selectedOutcomeId = e.target.value
    if (!selectedOutcomeId) return
    setOutcomeValue(selectedOutcomeId)

    updateCallSessionOutcome(Number(selectedOutcomeId))
    dispatch(completeSection(CallFlowSection.SESSION_OUTCOME))
    dispatch(openSection(CallFlowSection.CLOSING_REMARKS))
  }

  return (
    <Paper
      elevation={0}
      sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '22px',
          width: '100%'
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <div
            style={{ marginBottom: '12px', fontWeight: 'bold' }}
            id='call-session-heading-session-outcome'
          >
            {t('sessionOutcome')}
            <Required />
          </div>
          <InputRow
            label=''
            required={false}
            inputElem={
              <Dropdown
                id='outcome-dropdown'
                value={outcomeValue}
                items={formatOutcomes()}
                onChange={onOutcomeChange}
                error={outcome === null}
              />
            }
            inputWidth='300px'
          />
        </Box>
      </Box>
    </Paper>
  )
}

export default SessionOutcomePanel
