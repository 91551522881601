import React from 'react'
import {
  Typography,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip
} from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useAppDispatch, useAppSelector } from '../../store/ConfigureStore'
import { fetchSettings, updateSetting } from '../../buddy-api/api/SettingAPI'

export const AdminPanel: React.FC = () => {
  const { settings } = useAppSelector((state) => state.settingAPI)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const setting = settings?.find((s) => s.key === event.target.name)
    if (setting && setting.type === 'boolean') {
      dispatch(
        updateSetting({
          id: setting.id,
          key: setting.key,
          value: event.target.checked.toString(),
          type: setting.type,
          display_name: setting.display_name,
          description: setting.description
        })
      )
    }
  }

  return (
    <div style={{ margin: '20px' }}>
      <Typography variant='h4'>Admin Panel</Typography>
      <Paper style={{ padding: '20px', marginTop: '20px', maxWidth: '400px' }}>
        <Typography variant='h6'>Global Call Session Settings</Typography>
        <FormGroup>
          {settings?.map((setting) => {
            return (
              <div
                key={setting.key}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px'
                }}
              >
                <FormControlLabel
                  key={setting.key}
                  control={
                    <Switch
                      checked={setting.value === 'true'}
                      onChange={handleChange}
                      name={setting.key}
                    />
                  }
                  label={setting.display_name}
                  sx={{ marginRight: '2px' }}
                />
                <Tooltip title={setting.description || ''} placement='top'>
                  <HelpOutlineIcon
                    style={{
                      fill: 'gray',
                      cursor: 'pointer',
                      height: '16px'
                    }}
                  />
                </Tooltip>
              </div>
            )
          })}
        </FormGroup>
      </Paper>
    </div>
  )
}
