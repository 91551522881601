import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerBillDocument,
  ICustomerBillDocumentState
} from '../../types/customer/CustomerBillDocument'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-billing/'

export interface GetCustomerBillDocumentParams extends IAPIParams {
  billing_account_number: string
  repository?: Repository
}

export class CustomerBillDocumentAPI {
  public get = async (
    params: GetCustomerBillDocumentParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerBillDocument[]> => {
    const result = await ApiRequest.get<ICustomerBillDocument[]>(
      basePath + params.billing_account_number + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerBillDocumentAPI = new CustomerBillDocumentAPI()

export const getCustomerBillDocument = createAsyncThunk(
  'use_frontier_service_usages',
  async (
    params: GetCustomerBillDocumentParams
  ): Promise<ICustomerBillDocument[]> => {
    return await customerBillDocumentAPI.get(params)
  }
)

const initialState: ICustomerBillDocumentState = {
  data: [],
  loading: false,
  error: null
}

const customerBillDocumentAPISlice = createSlice({
  name: 'use_frontier_service_usages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCustomerBillDocument.pending,
        (state: ICustomerBillDocumentState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        getCustomerBillDocument.fulfilled,
        (state: ICustomerBillDocumentState, action) => {
          state.loading = false
          state.error = null
          state.data = action.payload
        }
      )
      .addCase(
        getCustomerBillDocument.rejected,
        (state: ICustomerBillDocumentState) => {
          state.loading = false
          state.error = 'Error fetching customer bill document.'
          state.data = []
        }
      )
  }
})

export default customerBillDocumentAPISlice
