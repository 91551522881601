import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerMoveTypeOption,
  ICustomerMoveTypeOptionState
} from '../../types/callsession/CustomerMoveTypeOptions'

const basePath = '/callsession-customer-move-type/'

export class CallSessionCustomerMoveTypesAPI {
  public list = async (
    params?: IAPIParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerMoveTypeOption[]> => {
    const result = await ApiRequest.get<ICustomerMoveTypeOption[]>(
      basePath + 'list/',
      signal
    )
    return result.body
  }
}

const callSessionCustomerMoveTypesAPI = new CallSessionCustomerMoveTypesAPI()

export const fetchCallSessionCustomerMoveTypes = createAsyncThunk(
  'callSessionCustomerMoveTypes/fetchCallSessionCustomerMoveTypes',
  async (): Promise<ICustomerMoveTypeOption[]> => {
    return await callSessionCustomerMoveTypesAPI.list()
  }
)

const initialState: ICustomerMoveTypeOptionState = {
  options: [],
  loading: false,
  error: null
}

const callSessionCustomerMoveTypesAPISlice = createSlice({
  name: 'callsessionCustomerMoveTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCallSessionCustomerMoveTypes.pending,
        (state: ICustomerMoveTypeOptionState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        fetchCallSessionCustomerMoveTypes.fulfilled,
        (state: ICustomerMoveTypeOptionState, action) => {
          state.loading = false
          state.error = null
          state.options = action.payload
        }
      )
      .addCase(
        fetchCallSessionCustomerMoveTypes.rejected,
        (state: ICustomerMoveTypeOptionState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.options = []
        }
      )
  }
})

export default callSessionCustomerMoveTypesAPISlice
