import { useEffect } from 'react'
import Cookies from 'js-cookie'

import { useLocation } from 'react-router-dom'
import { LOCAL_STORAGE_KEYS } from '../../constants'
import { getUserAfterSSOLogin } from '../../buddy-api/api/AuthAPI'
import { useAppDispatch } from '../../store/ConfigureStore'

export const CaptureToken = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handleSSOLogin = async () => {
      const getQueryParam = (param: any) => {
        const queryString = new URLSearchParams(location.search)
        return queryString.get(param)
      }

      const accessToken = getQueryParam('token')

      if (accessToken) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.access_key, accessToken)
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.access_key_expires_in,
          Cookies.get('expiresIn') ?? '0'
        )
        await dispatch(getUserAfterSSOLogin())

        window.location.href = '/user'
      }
    }
    handleSSOLogin().catch(console.error)
  }, [location])

  return null // Render nothing, or a loading indicator, etc.
}
