import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerOrder,
  ICustomerOrderState
} from 'buddy-api/types/customer/CustomerOrder'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-order'

export interface CustomerOrderParams extends IAPIParams {
  account_number: string
  repository?: Repository
}

export class CustomerOrderAPI {
  public list = async (
    params: CustomerOrderParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerOrder[]> => {
    const result = await ApiRequest.get<ICustomerOrder[]>(
      basePath + '/' + params.account_number + '/',
      signal,
      params
    )
    return result.body
  }
}

const CustomerOrdersAPI = new CustomerOrderAPI()

export const getCustomerOrder = createAsyncThunk(
  'CustomerOrders/listCustomerOrders',
  async (params: CustomerOrderParams): Promise<ICustomerOrder[]> => {
    return await CustomerOrdersAPI.list(params)
  }
)

const initialState: ICustomerOrderState = {
  orders: [],
  loading: false,
  error: null
}

export const customerOrderAPISlice = createSlice({
  name: 'CustomerOrders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerOrder.pending, (state: ICustomerOrderState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        getCustomerOrder.fulfilled,
        (state: ICustomerOrderState, action) => {
          state.loading = false
          state.orders = action.payload
        }
      )
      .addCase(getCustomerOrder.rejected, (state: ICustomerOrderState) => {
        state.loading = false
        state.error = 'Error fetching customer orders'
        state.orders = []
      })
  }
})

export default customerOrderAPISlice
