import { createTheme } from '@mui/material/styles'
import { CSSProperties } from '@mui/material/styles/createTypography'

import type {} from '@mui/x-data-grid/themeAugmentation'
import chroma from 'chroma-js'

declare module '@mui/material/styles' {
  interface Mixins {
    scrollbar: Partial<CSSProperties>
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: '#f2f2f2'
    },
    // Grey and black color for CustomerPanel
    text: {
      secondary: chroma('#7d7d7d').hex(),
      primary: chroma('#000000').hex()
    },
    primary: {
      light: chroma('#ff0037').brighten(0.5).hex(),
      main: '#ff0037',
      dark: chroma('#ff0037').darken(0.5).hex()
    },
    secondary: {
      light: chroma('#0BDCE9').brighten(0.5).hex(),
      main: '#0BDCE9',
      dark: chroma('#0BDCE9').darken(0.5).hex()
    },
    warning: {
      main: '#FFC107'
    },
    error: {
      light: '#ED4A4A',
      main: '#ED4A4A'
    },
    grey: {
      300: '#E0E0E0',
      400: '#BDBDBD'
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontWeight: 600,
      fontSize: '17px'
    },
    h2: {
      fontWeight: 600,
      fontSize: '13px'
    },
    h3: {
      fontWeight: 600,
      fontSize: '15px'
    },
    body1: {
      fontWeight: 400,
      fontSize: '13px'
    },
    body2: {
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '13px'
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '12px'
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '12px'
    }
  },
  mixins: {
    scrollbar: {
      '::-webkit-scrollbar-track': {
        borderRadius: '10px'
      },
      '::-webkit-scrollbar': {
        backgroundColor: '#fafafa',
        '&vertical{}': {
          width: '12px'
        },
        '&horizontal{}': {
          height: '12px'
        }
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
        borderRadius: '10px',
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      }
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        contained: {
          borderRadius: '3px',
          padding: '7px 12px'
        },
        containedPrimary: {
          color: '#FFFFFF'
        },
        containedSecondary: {
          color: '#FFFFFF'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0px'
        }
      }
    },
    MuiSelect: {},
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiDivider: {
      styleOverrides: {
        textAlignLeft: {
          '&::before': {
            width: 0
          },
          '&::after': {
            borderTop: '1px solid #000000',
            opacity: 0.3
          }
        },
        wrapper: {
          padding: 0,
          fontWeight: 600,
          fontSize: '13px'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '3px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            color: '#3A404D'
          },
          fontWeight: 600,
          fontSize: '13px',
          borderBottom: 'solid 3px transparent',
          ':hover': {
            borderBottom: 'solid 3px #2760F0'
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          color: '#3A404D',
          '&.Mui-checked': {
            color: '#3A404D'
          }
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          color: '#3A404D',
          '&.Mui-checked': {
            color: '#3A404D'
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-fontSizeSmall': {
            fontSize: '18px'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 8px 1px rgba(0, 0, 0, 0.2)'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontSize: '12px',
          fontWeight: 400
        },
        displayedRows: {
          fontSize: '12px',
          fontWeight: 400
        },
        toolbar: {
          '&.MuiInputBase-root': {
            fontSize: '12px',
            fontWeight: 400
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.mixins.scrollbar
        })
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.mixins.scrollbar
        })
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          outline: 'none !important',
          background: 'white'
        },
        columnHeader: {
          outline: 'none !important'
        },
        columnHeaderTitle: {
          fontWeight: 600,
          fontSize: '14px'
        },
        cell: {
          outline: 'none !important',
          fontWeight: 400,
          fontSize: '14px'
        },
        menu: {
          boxShadow: '0px 4px 8px 1px rgba(0, 0, 0, 0.2)',
          '.MuiPaper-root': {
            borderRadius: '3px'
          }
        }
      }
    }
  }
})

export default theme
