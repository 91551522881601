import { type IAPIParams, IListResponseWrapper } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerCompetitors,
  ICustomerCompetitorsState
} from '../../types/customer/CustomerCompetitor'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-competitor/'

interface CustomerCompetitorAPIParams extends IAPIParams {
  account_id: string
  repository?: Repository
}

export class CustomerCompetitorAPI {
  public get = async (
    params?: CustomerCompetitorAPIParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerCompetitors[]> => {
    const result = await ApiRequest.get<
      IListResponseWrapper<ICustomerCompetitors>
    >(basePath, signal, params)
    return result.body.results
  }
}

const customerCompetitorAPI = new CustomerCompetitorAPI()

export const getCustomerCompetitors = createAsyncThunk(
  'customerCompetitors/getCustomerCompetitors',
  async (
    params: CustomerCompetitorAPIParams
  ): Promise<ICustomerCompetitors[]> => {
    return await customerCompetitorAPI.get(params)
  }
)

const initialState: ICustomerCompetitorsState = {
  customerCompetitors: null,
  loading: false,
  error: null
}

const customerCompetitorAPISlice = createSlice({
  name: 'customerCompetitors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCustomerCompetitors.pending,
        (state: ICustomerCompetitorsState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        getCustomerCompetitors.fulfilled,
        (state: ICustomerCompetitorsState, action) => {
          state.loading = false
          state.error = null
          state.customerCompetitors = action.payload
        }
      )
      .addCase(
        getCustomerCompetitors.rejected,
        (state: ICustomerCompetitorsState) => {
          state.loading = false
          state.error = 'Failed to fetch customer competitors.'
          state.customerCompetitors = null
        }
      )
  }
})

export default customerCompetitorAPISlice
