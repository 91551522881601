import { useAppSelector } from '../../../store/ConfigureStore'
import { ProductNamesEnum } from '../../../utils/enums'

export const useShowLegacyProductWarning = () => {
  const dropped_product = useAppSelector(
    (state) => state.agentCallSession.dropped_product
  )

  const customer_service_billing = useAppSelector(
    (state) => state.customerServiceBillingAPI.customerServiceBilling
  )

  const showLegacyProductWarning = () => {
    if (!dropped_product) {
      return false
    } else {
      return (
        (customer_service_billing?.is_voice_legacy &&
          dropped_product.label.includes(ProductNamesEnum.voice)) ||
        (customer_service_billing?.is_video_legacy &&
          dropped_product.label.includes(ProductNamesEnum.video))
      )
    }
  }

  return showLegacyProductWarning()
}
