import { offerTypes } from 'store/callsession/callsessionSlice'

export interface IRawOffer {
  id: number
  is_active?: boolean
  name: string
  offer_ladder_id?: number
  offer_product_type: string
  priority: number
  script: string
}

export interface IDefaultOffer {
  id: number
  name: string
  offer_ladder_id: number
  offer_product_type: string
  priority: number
  script: string

  accepted: boolean
  rejected: boolean
  notOffered: boolean
  expanded: boolean

  typePriorityOrder?: number
  displayOrder?: number
  isDisplayed?: boolean
}

export const getOrderOffersByDisplay = (
  offers: IDefaultOffer[],
  isDeviceFirst = true
): IDefaultOffer[] => {
  const firstSortOffers = offers.map((offer) => {
    let typePriorityOrder = 1 // Default priority

    if (isDeviceFirst && offer.offer_product_type === offerTypes.device.name) {
      typePriorityOrder = 0
    } else if (
      !isDeviceFirst &&
      offer.offer_product_type === offerTypes.discount.name
    ) {
      typePriorityOrder = 0
    }

    return { ...offer, typePriorityOrder }
  })

  const finalSortedOffers: IDefaultOffer[] = firstSortOffers.sort(
    (offer1, offer2) => {
      // 1- Sort by typePriority
      if (offer1.typePriorityOrder !== offer2.typePriorityOrder)
        return offer1.typePriorityOrder - offer2.typePriorityOrder

      // 2- Sort by priority
      return offer1.priority - offer2.priority
    }
  )

  // Include a displayOrder property to indicate the order of them all
  // Include a isDisplayed flag to indicate if the offer was displayed or not
  finalSortedOffers.forEach((offer, index) => {
    offer.displayOrder = index
    offer.isDisplayed = index === 0 // Set the first offer as default to be displayed
  })

  return finalSortedOffers
}

export const buildCallsessionDefaultOffer = (
  offer: IRawOffer
): IDefaultOffer => {
  return {
    id: offer.id,
    name: offer.name,
    offer_ladder_id: offer.offer_ladder_id || 0,
    offer_product_type: offer.offer_product_type || '',
    priority: offer.priority || 0,
    script: offer.script,

    accepted: false,
    rejected: false,
    notOffered: true,
    expanded: false
  }
}

export const getCallsessionDefaultOffers = (
  offers: IDefaultOffer[],
  type: string
): IDefaultOffer[] => {
  const deviceOffers = offers.filter(
    (offer) => offer.offer_product_type === type
  )
  return orderOffersByPriority(deviceOffers)
}

const orderOffersByPriority = (offers: IDefaultOffer[]) => {
  return offers.sort((offer1, offer2) => offer1.priority - offer2.priority)
}
