import { AgentCallSession } from '../../callsession/callsessionSlice'
import { isDisconnectedCustomer } from '../../../lib/utils'

export const isIntroductionSectionValid = (
  agentCallSessionState: AgentCallSession
): boolean => {
  const intentValid = agentCallSessionState.call_session_intent !== null
  // const droppedProductValid = agentCallSessionState.dropped_product !== null
  const disconnectedCustomer = isDisconnectedCustomer(
    agentCallSessionState?.customer?.account_status
  )

  if (disconnectedCustomer) return true
  return intentValid
}
