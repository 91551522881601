import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/ConfigureStore'
import { useLocation, useParams } from 'react-router-dom'
import { getCustomer } from '../../buddy-api/api/customer/CustomerAPI'
import { getCustomerAccountItems } from '../../buddy-api/api/customer/CustomerAccountItem'
import {
  updateAgentCallSessionCustomer,
  updateAgentCallSessionStartAt,
  updateAgentCallSessionUser,
  updateSessionCanBePerformed
} from '../../store/callsession/callsessionSlice'
import { Box, CircularProgress } from '@mui/material'
import { fetchCallSessions } from '../../buddy-api/api/callsession/CallSessionAPI'
import { Column, Row } from '../../ui'
import { CallSessionCompetitorPanel } from './Competitor/CallSessionCompetitor'
import { CallFlow } from './CallFlow/CallFlow'
import { getCustomerCompetitors } from '../../buddy-api/api/customer/CustomerCompetitorAPI'
import { fetchCallSessionOutcomes } from '../../buddy-api/api/callsession/CallSessionOutcomeAPI'
import { fetchSettings } from '../../buddy-api/api/SettingAPI'
import { getCustomerProductMetrics } from '../../buddy-api/api/customer/CustomerProductMetric'
import { getCustomerCoreService } from '../../buddy-api/api/customer/CustomerCoreServiceAPI'
import { useSettings } from 'utils/Settings'
import { getCustomerEmail } from '../../buddy-api/api/customer/CustomerEmailAPI'
import { getCustomerPhone } from '../../buddy-api/api/customer/CustomerPhoneAPI'
import { getCustomerAddress } from '../../buddy-api/api/customer/CustomerAddressAPI'
import { getCustomerServiceBilling } from '../../buddy-api/api/customer/CustomerServiceBillingAPI'
import { getCustomerPayment } from '../../buddy-api/api/customer/CustomerPaymentAPI'
import { getCustomerBillDocument } from 'buddy-api/api/customer/CustomerBillDocument'
import { getCustomerOrder } from 'buddy-api/api/customer/CustomerOrderAPI'
import { fetchCallSessionIntents } from '../../buddy-api/api/callsession/CallSessionIntentAPI'
import { fetchProducts } from '../../buddy-api/api/ProductAPI'
import { fetchCallSessionRootCauses } from '../../buddy-api/api/callsession/CallSessionRootCauseAPI'
import { getBattleCardCompetitors } from '../../buddy-api/api/BattleCardCompetitorAPI'
import { fetchCallSessionNewTechOptions } from '../../buddy-api/api/callsession/CallSessionNewTechOptionAPI'
import { fetchCallSessionOfferedOptions } from '../../buddy-api/api/callsession/CallSessionOfferedOptions'
import { fetchCallSessionCustomerMoveTypes } from '../../buddy-api/api/callsession/CallSessionCustomerMoveType'
import config from '../../config'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { FloatingAPIStatusCard } from './ApiStatusCard'
import { getCustomerBill } from '../../buddy-api/api/customer/CustomerBill'
import { isDisconnectedCustomer } from 'lib/utils'
import { fetchWinbackSpeeds } from 'buddy-api/api/callsession/CallSessionWinbackSpeedAPI'
import { SaveSession } from './SaveSession'
import CallSessionComment from './CallSessionComment'

export const CallSessionPage = (): React.ReactElement => {
  const accountId = useParams().account_id
  const location = useLocation()
  const [showSummary, setShowSummary] = React.useState<boolean>(true)

  const { getBooleanSetting } = useSettings()

  const ldClient = useLDClient()
  const flags = useFlags()

  const {
    customerAPI: { customer, loading: loadingCustomer, error: errorCustomer },
    customerBillDocument: { loading: loadingBillDocument, error: errorBillDoc },
    customerServiceBillingAPI: { loading: loadingCSB, error: errorCSB },
    customerAccountItemAPI: { loading: loadingCAI, error: errorCAI },
    authAPI: { user },
    topBarCollapse: { isCollapsed },
    agentCallSession: { selectedLanguage }
  } = useAppSelector((state) => state)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const email = user.email
    if (email && ldClient) {
      ldClient.waitForInitialization(3).then(() => {
        ldClient.identify({ kind: 'user', key: email.toLowerCase() })
      })
    }
  }, [])

  React.useEffect(() => {
    dispatch(fetchSettings())

    fetchCustomerDetails()
    fetchCustomerAddress()
    fetchCustomerEmail()
    fetchCustomerPhone()
    fetchCustomerCoreServices()
    fetchCustomerAccountItems()
    fetchCustomerDiscount()
    fetchCustomerPayment()
    fetchCustomerOrders()
    fetchCustomerBills()
    dispatch(getBattleCardCompetitors())
    dispatch(updateAgentCallSessionStartAt(new Date().toISOString()))
    dispatch(updateAgentCallSessionUser(user))
    dispatch(fetchCallSessionIntents())
    dispatch(fetchProducts())
    dispatch(fetchCallSessionRootCauses())
    dispatch(fetchCallSessionOfferedOptions())
    dispatch(fetchCallSessionNewTechOptions())
    dispatch(fetchCallSessionCustomerMoveTypes())
    dispatch(fetchWinbackSpeeds())
    dispatch(
      fetchCallSessionOutcomes({
        deactivated_account: false // make it dynamic incase deactivated account gets considered.
      })
    )

    if (accountId !== undefined) {
      dispatch(fetchCallSessions({ account_id: accountId }))
      dispatch(getCustomerCompetitors({ account_id: accountId }))
    }
  }, [dispatch, location, selectedLanguage])

  React.useEffect(() => {
    if (customer !== null) {
      dispatch(updateAgentCallSessionCustomer(customer))
    }
  }, [customer])

  React.useEffect(() => {
    if (errorCustomer || errorBillDoc || errorCAI || errorCSB) {
      dispatch(updateSessionCanBePerformed(false))
    } else {
      dispatch(updateSessionCanBePerformed(true))
    }
  }, [errorCustomer, errorBillDoc, errorCAI, errorCSB])

  React.useEffect(() => {
    if (customer?.telephone !== null) {
      fetchAccountServiceUsages(customer?.telephone)
      fetchCustomerBillDocument(customer?.billing_account_number)
    }
  }, [customer])

  React.useEffect(() => {
    if (customer) {
      dispatch(
        fetchCallSessionOutcomes({
          deactivated_account: isDisconnectedCustomer(customer.account_status)
        })
      )
    }
  }, [customer])

  const fetchAccountServiceUsages = (telephone: string | undefined) => {
    const useFrontierApi =
      getBooleanSetting('use_frontier_service_usages') &&
      config.app.env !== 'local'
    if (telephone !== undefined && telephone !== null) {
      if (useFrontierApi) {
        dispatch(
          getCustomerProductMetrics({
            telephone: telephone
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(
          getCustomerProductMetrics({
            telephone: telephone
          })
        )
      }
    }
  }

  const fetchCustomerBillDocument = (accountNumber: string | undefined) => {
    if (accountNumber) {
      dispatch(
        getCustomerBillDocument({
          billing_account_number: accountNumber ?? ''
          //repository: Repository.FrontierAPI
        })
      )
    }
  }

  const fetchCustomerOrders = () => {
    const queryParams = new URLSearchParams(location.search)
    const accountNumber = queryParams.get('account_number')
    const useFrontierApi =
      getBooleanSetting('use_frontier_customer_order_history') &&
      config.app.env !== 'local'
    if (accountNumber !== undefined && accountNumber !== null) {
      if (useFrontierApi) {
        dispatch(
          getCustomerOrder({
            account_number: accountNumber
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(getCustomerOrder({ account_number: accountNumber }))
      }
    }
  }

  const fetchCustomerDetails = () => {
    // const useFrontierApi =
    //   getBooleanSetting('use_frontier_customer_details') &&
    //   config.app.env !== 'local'
    if (accountId !== undefined) {
      dispatch(
        getCustomer({
          account_id: accountId
          // repository: useFrontierApi
          //   ? Repository.FrontierAPI
          //   : Repository.AuroraPostgres
        })
      )
    }
  }

  const fetchCustomerBills = () => {
    // const useFrontierApi = false
    const queryParams = new URLSearchParams(location.search)
    const accountNumber = queryParams.get('account_number')
    if (accountId !== undefined) {
      dispatch(
        getCustomerBill({
          account_id: accountId,
          // repository: useFrontierApi
          //   ? Repository.FrontierAPI
          //   : Repository.AuroraPostgres,
          account_number: accountNumber
        })
      )
    }
  }

  const fetchCustomerAddress = () => {
    const useFrontierApiCoreServices =
      getBooleanSetting('use_frontier_customer_address') &&
      config.app.env !== 'local'
    if (accountId !== undefined) {
      if (useFrontierApiCoreServices) {
        dispatch(
          getCustomerAddress({
            account_id: accountId
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(getCustomerAddress({ account_id: accountId }))
      }
    }
  }

  const fetchCustomerCoreServices = () => {
    const useFrontierApiCoreServices =
      getBooleanSetting('use_frontier_core_services') &&
      config.app.env !== 'local'
    if (accountId !== undefined) {
      if (useFrontierApiCoreServices) {
        dispatch(
          getCustomerCoreService({
            account_id: accountId
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(getCustomerCoreService({ account_id: accountId }))
      }
    }
  }

  const fetchCustomerAccountItems = () => {
    const useFrontierApiAccountItems =
      getBooleanSetting('use_frontier_account_items') &&
      config.app.env !== 'local'
    if (accountId !== undefined) {
      if (useFrontierApiAccountItems) {
        dispatch(
          getCustomerAccountItems({
            account_id: accountId
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(getCustomerAccountItems({ account_id: accountId }))
      }
    }
  }

  const fetchCustomerEmail = () => {
    const useFrontierApi =
      getBooleanSetting('use_frontier_customer_email') &&
      config.app.env !== 'local'
    if (accountId !== undefined) {
      if (useFrontierApi) {
        dispatch(
          getCustomerEmail({
            account_id: accountId
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(getCustomerEmail({ account_id: accountId }))
      }
    }
  }

  const fetchCustomerPhone = () => {
    const useFrontierApi =
      getBooleanSetting('use_frontier_customer_phone') &&
      config.app.env !== 'local'
    if (accountId !== undefined) {
      if (useFrontierApi) {
        dispatch(
          getCustomerPhone({
            account_id: accountId
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(getCustomerPhone({ account_id: accountId }))
      }
    }
  }

  const fetchCustomerDiscount = () => {
    const useFrontierApi =
      getBooleanSetting('use_frontier_customer_service_bill') &&
      config.app.env !== 'local'
    if (accountId !== undefined) {
      if (useFrontierApi) {
        dispatch(
          getCustomerServiceBilling({
            account_id: accountId
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(getCustomerServiceBilling({ account_id: accountId }))
      }
    }
  }

  const fetchCustomerPayment = () => {
    const queryParams = new URLSearchParams(location.search)
    const accountNumber = queryParams.get('account_number')
    const useFrontierApi =
      getBooleanSetting('use_frontier_customer_payment') &&
      config.app.env !== 'local'
    if (accountNumber !== undefined && accountNumber !== null) {
      if (useFrontierApi) {
        dispatch(
          getCustomerPayment({
            account_number: accountNumber
            //repository: Repository.FrontierAPI
          })
        )
      } else {
        dispatch(getCustomerPayment({ account_number: accountNumber }))
      }
    }
  }

  return (
    <Box
      id={'call-session-page'}
      style={{
        height: '100vh',
        position: 'relative'
      }}
    >
      {loadingCustomer || loadingBillDocument || loadingCAI || loadingCSB ? (
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Row>
            <Column alignItems='center'>
              <CircularProgress sx={{ margin: '10px' }} />
              <span>Loading Customer Information</span>
            </Column>
          </Row>
        </Box>
      ) : (
        <Box>
          {/* {!isCollapsed && (
            <Box
              sx={{
                position: pinned ? 'sticky' : '',
                top: pinned ? '0' : 'auto',
                zIndex: 9999
              }}
            >
              <CustomerTopBar />
            </Box>
          )}
          {isCollapsed && pinned ? (
            <Drawer
              open={pinned}
              onClose={() => dispatch(updateTopBarPinned(!pinned))}
              anchor='top'
              sx={{
                width: '90% !important',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: '95% !important',
                  boxSizing: 'border-box',
                  left: 65
                }
              }}
            >
              <CustomerTopBar />
              <Tooltip title='Hide'>
                <IconButton
                  sx={{
                    position: 'absolute',
                    marginTop: 46,
                    left: '50%',
                    transform: 'rotate(180deg)',
                    zIndex: 1111,
                    cursor: 'pointer'
                  }}
                  onClick={() => dispatch(updateTopBarPinned(!pinned))}
                >
                  <ExpandCircleDownRoundedIcon
                    color={'error'}
                    fontSize='medium'
                  />
                </IconButton>
              </Tooltip>
            </Drawer>
          ) : (
            isCollapsed && <CustomerCollapsedTopBar customer={customer} />
          )} */}

          <Row>
            <Column sx={{ flex: 10 }}>
              <CallFlow
                setShowSummary={setShowSummary}
                showSummary={showSummary}
              />
              <CallSessionComment />
              <SaveSession />
            </Column>
            <Column
              sx={{
                flex: 4,
                display: 'flex',
                flexDirection: 'column',
                minWidth: '380px'
              }}
            >
              <CallSessionCompetitorPanel topValue={!isCollapsed ? 0 : 0} />
            </Column>
          </Row>
        </Box>
      )}
      {flags.fron187ShowApiStatusCard && <FloatingAPIStatusCard />}
    </Box>
  )
}
