import React from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useTranslation } from 'react-i18next'

interface ISkippedDialog {
  open: boolean
  onYes: () => void
  onNo: () => void
}

export const SkippedDialog = ({ open, onYes, onNo }: ISkippedDialog) => {
  const { t } = useTranslation('skippedDialog')
  return (
    <Dialog open={open} onClose={onNo}>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '16px' }}>
          {t('action')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo}>{t('no')}</Button>
        <Button onClick={onYes}>{t('yes')}</Button>
      </DialogActions>
    </Dialog>
  )
}
