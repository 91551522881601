import { Languages } from 'store/languages'

export const serviceCallAboutLang = {
  [Languages.ENGLISH]: {
    Deactivation: 'Deactivation',
    NotDeactivation: 'Not deactivation related'
  },
  [Languages.SPANISH]: {
    Deactivation: 'Desactivación',
    NotDeactivation: 'No relacionado con la desactivación'
  }
}

export const nonRetentionOutcomeTypes = {
  [Languages.ENGLISH]: {
    OutOfScope: 'Out of scope',
    DisconnectedMoved: 'Disconnected - move to competitors'
  },
  [Languages.SPANISH]: {
    OutOfScope: 'Fuera de alcance',
    DisconnectedMoved: 'Desconectado - trasladado a competidores'
  }
}
