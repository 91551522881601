import React from 'react'
import { BaseItem } from '../../components/BaseItem'
import { ScriptBlock } from '../../components/ScriptBlock'
import { ActionItemControls } from './ActionItemControls'
import { ActionType } from 'buddy-api/types/Action'
import { useAppDispatch, useAppSelector } from 'store/ConfigureStore'
import { updateExpandedActionIndex } from 'store/callsession/callsessionSlice'

interface IActionItem {
  index: number
  title: string
  script: string
  accepted: boolean
  rejected: boolean
  offered: boolean
  type: ActionType
  onOfferedClick: () => void
  onAcceptClick: () => void
  onRejectClick: () => void
}

const getControls = (props: IActionItem) => {
  return <ActionItemControls {...props} />
}

export const ActionItem = (props: IActionItem) => {
  const dispatch = useAppDispatch()
  const expandedActionIndex = useAppSelector(
    (state) => state.agentCallSession.expandedActionIndex
  )
  const actionExpanded = expandedActionIndex === props.index ? true : false

  const onToggle = () => {
    if (expandedActionIndex === props.index) {
      dispatch(updateExpandedActionIndex(null))
    } else {
      dispatch(updateExpandedActionIndex(props.index))
    }
  }
  return (
    <BaseItem
      {...props}
      controls={getControls(props)}
      expanded={actionExpanded}
      onToggle={onToggle}
    >
      <ScriptBlock script={props.script} />
    </BaseItem>
  )
}
