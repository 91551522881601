import moment from 'moment'
import { IProduct } from 'buddy-api/types/Offer'
import { ICallSessionOffer } from 'buddy-api/types/callsession/CallSessionOffer'
import { AccountStatus } from '../utils/enums'

export const clone = (input: any) => JSON.parse(JSON.stringify(input))

export const roundPrice = (value: number) => {
  return Math.round(value * 100.0) / 100.0
}

export const isYoutubeOffer = (offer: ICallSessionOffer) => {
  return offer.product === 'Video' && offer.video_tier === 'YouTube TV'
}

export const isYoutubeTVOfferAccepted = (offers: ICallSessionOffer[]) => {
  // If an offer was accepted via drop offer,
  // mark the offer as accepted
  offers = clone(offers)
  const videoDropOffer = offers.find(
    (offer) =>
      offer.type === 'drop' &&
      offer.product === 'Video' &&
      offer.rejected &&
      offer.selectedOfferId
  )
  if (videoDropOffer) {
    const selectedOffer = offers.find(
      (offer) => offer.id === videoDropOffer.selectedOfferId
    )
    if (selectedOffer) {
      selectedOffer.accepted = true
    }
  }

  return offers.some((offer) => isYoutubeOffer(offer) && offer.accepted)
}

export const isProductDropped = (
  product: IProduct,
  offers: ICallSessionOffer[]
) => {
  return offers.some(
    (offer) =>
      offer.product === product && offer.type === 'drop' && offer.accepted
  )
}

export const formatPrice = (value: any) => {
  if (typeof value !== 'number') {
    return value
  }

  let isNeg = false
  if (value < 0) {
    isNeg = true
    value = -value
  }
  const priceStr = '$' + value.toFixed(2)

  return isNeg ? '-' + priceStr : priceStr
}

export const formatDateUS = (value: any) => {
  try {
    return moment(value).format('YYYY-MM-DD hh:mm A')
  } catch {
    return value
  }
}

export const isDisconnectedCustomer = (accountStatus?: string) => {
  return accountStatus === AccountStatus.Inactive
}
