import React from 'react'
import config from 'config'
// @ts-expect-error: Cannot import
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.scss'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'theme'
import { asyncWithLDProvider, basicLogger } from 'launchdarkly-react-client-sdk'

void (async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.app.launchDarklyKey,
    context: {
      anonymous: true
    },
    options: {
      logger: basicLogger({ level: 'warn' })
    }
  })
  const root = ReactDOM.createRoot(document.getElementById('root'))
  if (config.app.env === 'local') {
    root.render(
      <ThemeProvider theme={theme}>
        <LDProvider>
          <CssBaseline />
          <App />
        </LDProvider>
      </ThemeProvider>
    )
  } else {
    root.render(
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <LDProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </LDProvider>
        </I18nextProvider>
      </React.StrictMode>
    )
  }
})()
