import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { IconButton } from '@mui/material'

interface IRejectButton {
  reject: boolean
  onClick: () => void
  disabled?: boolean
}

export const RejectButton = ({ reject, onClick, disabled }: IRejectButton) => {
  return (
    <IconButton sx={{ padding: '0px' }} onClick={onClick} disabled={disabled}>
      {reject ? (
        <CancelIcon
          color='primary'
          sx={{ color: disabled ? '#d8d8d8' : undefined }}
        />
      ) : (
        <CancelOutlinedIcon sx={{ color: '#d8d8d8' }} />
      )}
    </IconButton>
  )
}
