import React, { useEffect } from 'react'
import { Fade, Menu, Paper } from '@mui/material'
import {
  type ICustomer,
  ICustomerSearch
} from '../../buddy-api/types/customer/Customer'
import { useSelector } from 'react-redux'
import { type RootState, useAppDispatch } from '../../store/ConfigureStore'
import {
  DataGridPro,
  type GridColumns,
  type GridRowParams
} from '@mui/x-data-grid-pro'
import { CustomerSearchResultColumns } from './CustomerSearchResultColumns'
import { useNavigate } from 'react-router'
import { callSessionPageURL } from '../../routes/routes'
import { resetCustomerSearch } from '../../buddy-api/api/customer/CustomerSearchAPI'
import { fetchSettings } from '../../buddy-api/api/SettingAPI'

export const SearchResultMenu: React.FC = (): React.ReactElement => {
  const { customers, loading, error } = useSelector(
    (state: RootState) => state.customerSearchAPI
  )
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchSettings())
  }, [])

  useEffect(() => {
    if (loading) setAnchorEl(document.getElementById('search-bar'))
  }, [loading])

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const customerSearchResultColumns: GridColumns<ICustomerSearch> =
    React.useMemo(() => CustomerSearchResultColumns, [])

  const onCustomerClick = (params: GridRowParams<ICustomer>) => {
    dispatch(resetCustomerSearch())
    navigate(
      callSessionPageURL(params.row.account_id, params.row.account_number)
    )
  }

  return (
    <Menu
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorEl={anchorEl}
      keepMounted
      id='CustomerSearchResult'
      TransitionComponent={Fade}
      sx={{
        marginLeft: '-45px'
      }}
    >
      <Paper
        sx={{
          position: 'fixed',
          transition: 'opacity 0.5s',
          minWidth: '630px',
          height: '50vh',
          '& ul': {
            display: 'none'
          }
        }}
      >
        <DataGridPro
          sx={{
            '& .MuiTablePagination-toolbar p': {
              marginBottom: 0
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer'
            },
            '& .MuiDataGrid-cellContent': {
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}
          columns={customerSearchResultColumns}
          rows={customers ?? []}
          loading={loading}
          error={error}
          headerHeight={40}
          rowHeight={52}
          getRowId={(row) => row.account_id + row.firstname + row.lastname}
          pageSize={10}
          rowsPerPageOptions={[10]}
          pagination={true}
          onRowClick={(params: GridRowParams<ICustomer>) => {
            onCustomerClick(params)
          }}
        />
      </Paper>
    </Menu>
  )
}
