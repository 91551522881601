import {
  ICustomerPayment,
  ICustomerPaymentState
} from '../../types/customer/CustomerPayment'
import { IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-payment/'

export interface GetCustomerPaymentParam extends IAPIParams {
  account_number: string
  repository?: Repository
}

export class CustomerPaymentAPI {
  public get = async (
    params: GetCustomerPaymentParam,
    signal?: AbortSignal | null
  ): Promise<ICustomerPayment> => {
    const result = await ApiRequest.get<ICustomerPayment>(
      basePath + params.account_number + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerPaymentAPI = new CustomerPaymentAPI()

export const getCustomerPayment = createAsyncThunk(
  'customer/getCustomerPayment',
  async (params: GetCustomerPaymentParam): Promise<ICustomerPayment> => {
    return await customerPaymentAPI.get(params)
  }
)

const initialState: ICustomerPaymentState = {
  customerPayment: null,
  loading: false,
  error: null
}

export const customerPaymentAPISlice = createSlice({
  name: 'customerPayment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerPayment.pending, (state: ICustomerPaymentState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        getCustomerPayment.fulfilled,
        (state: ICustomerPaymentState, action) => {
          state.loading = false
          state.error = null
          state.customerPayment = action.payload
        }
      )
      .addCase(getCustomerPayment.rejected, (state: ICustomerPaymentState) => {
        state.loading = false
        state.error = 'Failed to fetch customer payment.'
        state.customerPayment = null
      })
  }
})

export default customerPaymentAPISlice.reducer
