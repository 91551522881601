import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum CallFlowSection {
  // eslint-disable-next-line no-unused-vars
  INTRODUCTION = 'INTRODUCTION',
  // eslint-disable-next-line no-unused-vars
  CONTEXT_SETTING = 'CONTEXT_SETTING',
  // eslint-disable-next-line no-unused-vars
  OFFERS = 'OFFERS',
  // eslint-disable-next-line no-unused-vars
  ACTIONS = 'ACTIONS',
  // eslint-disable-next-line no-unused-vars
  CLOSING_REMARKS = 'CLOSING_REMARKS',
  // eslint-disable-next-line no-unused-vars
  SAVE_SESSION_PANEL = 'SAVE_SESSION',
  // eslint-disable-next-line no-unused-vars
  SESSION_OUTCOME = 'SESSION_OUTCOME'
}

export interface CollapsableSectionsState {
  openSections: {
    // eslint-disable-next-line no-unused-vars
    [key in CallFlowSection]: {
      open: boolean
      completed: boolean
    }
  }
}

export const initialCollapsableSectionsState: CollapsableSectionsState = {
  openSections: {
    [CallFlowSection.INTRODUCTION]: {
      open: true,
      completed: true
    },
    [CallFlowSection.CONTEXT_SETTING]: {
      open: false,
      completed: false
    },
    [CallFlowSection.OFFERS]: {
      open: false,
      completed: false
    },
    [CallFlowSection.ACTIONS]: {
      open: false,
      completed: false
    },
    [CallFlowSection.SESSION_OUTCOME]: {
      open: false,
      completed: false
    },
    [CallFlowSection.CLOSING_REMARKS]: {
      open: false,
      completed: false
    },
    [CallFlowSection.SAVE_SESSION_PANEL]: {
      open: false,
      completed: false
    }
  }
}

export const collapsableSectionsSlice = createSlice({
  name: 'collapsableSections',
  initialState: initialCollapsableSectionsState,
  reducers: {
    toggleSection: (
      state: CollapsableSectionsState,
      action: PayloadAction<CallFlowSection>
    ) => {
      const section = action.payload
      state.openSections[section].open = !state.openSections[section].open
    },
    openAllSections: (state: CollapsableSectionsState) => {
      for (const section in state.openSections) {
        state.openSections[section as CallFlowSection].open = true
      }
    },
    openAvailableSections: (state: CollapsableSectionsState) => {
      for (const section in state.openSections) {
        if (!state.openSections[section as CallFlowSection].completed) {
          break // Stop iterating if the section is not completed
        }
        state.openSections[section as CallFlowSection].open = true
      }
    },
    closeAllSections: (state: CollapsableSectionsState) => {
      for (const section in state.openSections) {
        state.openSections[section as CallFlowSection].open = false
      }
    },
    closeSection: (
      state: CollapsableSectionsState,
      action: PayloadAction<CallFlowSection>
    ) => {
      const section = action.payload
      state.openSections[section].open = false
    },
    openSection: (
      state: CollapsableSectionsState,
      action: PayloadAction<CallFlowSection>
    ) => {
      const section = action.payload
      state.openSections[section].open = true
    },
    completeSection: (
      state: CollapsableSectionsState,
      action: PayloadAction<CallFlowSection>
    ) => {
      const section = action.payload
      state.openSections[section].completed = true
    },
    incompleteSection: (
      state: CollapsableSectionsState,
      action: PayloadAction<CallFlowSection>
    ) => {
      const section = action.payload
      state.openSections[section].completed = false
    }
  }
})

export const {
  toggleSection,
  openAllSections,
  closeAllSections,
  openSection,
  completeSection,
  incompleteSection,
  openAvailableSections,
  closeSection
} = collapsableSectionsSlice.actions
