import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ICustomer, ICustomerState } from '../../types/customer/Customer'
import { Repository } from '../../../utils/enums'

const basePath = '/customer/'

export interface GetCustomerParam extends IAPIParams {
  account_id: string
  repository?: Repository
}

export class CustomerAPI {
  public get = async (
    params: GetCustomerParam,
    signal?: AbortSignal | null
  ): Promise<ICustomer> => {
    const result = await ApiRequest.get<ICustomer>(
      basePath + params.account_number + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerAPI = new CustomerAPI()

export const getCustomer = createAsyncThunk(
  'getCustomer',
  async (params: GetCustomerParam): Promise<ICustomer> => {
    return await customerAPI.get(params)
  }
)

const initialState: ICustomerState = {
  customer: null,
  loading: false,
  error: null
}

const customerAPISlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomer.pending, (state: ICustomerState) => {
        state.loading = true
        state.error = null
      })
      .addCase(getCustomer.fulfilled, (state: ICustomerState, action) => {
        state.loading = false
        state.error = null
        state.customer = action.payload
      })
      .addCase(getCustomer.rejected, (state: ICustomerState) => {
        state.loading = false
        state.error = 'Error fetching customer data.'
        state.customer = null
      })
  }
})

export default customerAPISlice
