import config from 'config'

export function callSessionPageURL(
  customerId: string,
  accountNumber: string
): string {
  return `${config.app.basePath}/user/callsession/${customerId}?account_number=${accountNumber}`
}

export function callSessionWithoutCustomerPageURL(): string {
  return `${config.app.basePath}/user/callsession`
}

export function userLandingPage(): string {
  return `${config.app.basePath}/user`
}

export function loginPageURL(): string {
  return `${config.app.basePath}/login`
}

export function adminPanelURL(): string {
  return `${config.app.basePath}/user/admin`
}

export function samlLoginURL(): string {
  return `${config.app.idpLoginURL}`
}
