import { createSlice } from '@reduxjs/toolkit'
import { IBattleCardCompetitor } from '../../buddy-api/types/BattleCardCompetitor'

export interface BattleCardDropdownState {
  selectedCompetitor: IBattleCardCompetitor | null
}

const initialState: BattleCardDropdownState = {
  selectedCompetitor: null
}

export const battlecardDropdownSlice = createSlice({
  name: 'battlecardDropdown',
  initialState,
  reducers: {
    updateBattleCardDropdownSelectedCompetitor: (state, action) => {
      state.selectedCompetitor = action.payload
    },
    resetBattleCardDropdownSelectedCompetitor: (state) => {
      state.selectedCompetitor = null
    }
  }
})

export const {
  updateBattleCardDropdownSelectedCompetitor,
  resetBattleCardDropdownSelectedCompetitor
} = battlecardDropdownSlice.actions
