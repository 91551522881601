import { Box, Button, InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField'
import React from 'react'
import { SearchResultMenu } from './SearchResultMenu'
import { useNavigate } from 'react-router'
import { callSessionWithoutCustomerPageURL } from 'routes/routes'
import { useTranslation } from 'react-i18next'
import { Required } from 'components/input/Required'
import { useAppDispatch, useAppSelector } from 'store/ConfigureStore'
import { updateAccountId } from 'store/callsession/callsessionSlice'
import { updateSelectedLanguage } from '../../store/callsession/callsessionSlice'
import { Languages } from 'store/languages'

const SearchBarComponent: React.FC = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { t } = useTranslation('searchBarComponent')

  const account_id = useAppSelector(
    (state) => state.agentCallSession.account_id
  )

  const changeLanguage = (lang: Languages) => {
    i18n.changeLanguage(lang || Languages.ENGLISH)
    dispatch(updateSelectedLanguage(lang))
  }

  const onStartCallsession = (lang: Languages) => {
    changeLanguage(lang || Languages.ENGLISH)
    navigate(callSessionWithoutCustomerPageURL())
  }

  const onChangeUpdateAccountId = (value: string) => {
    if (/^\d*$/.test(value)) {
      return dispatch(updateAccountId(value))
    }
  }

  const startCallsessionDisable = account_id?.length
    ? account_id.length < 9
    : true

  return (
    <Box>
      {/* <TextField
        id='search-bar'
        onKeyDown={handleKeyPress}
        sx={{
          width: '500px',
          mr: 2,
          height: '35px',
          fontSize: '24px',
          '& fieldset': {
            border: 'none'
          }
        }}
        InputProps={{
          sx: {
            background: '#FFFFFF',
            height: '100%',
            fontSize: '14px'
          },
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton
                sx={{
                  px: 0.3,
                  py: 0.3
                }}
                onClick={() => {
                  setSearchTerm('')
                }}
              >
                {searchTerm.length > 0 ? (
                  <CancelIcon fontSize='small' />
                ) : (
                  <SearchIcon
                    fontSize='medium'
                    sx={{
                      color: 'black'
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value)
        }}
        placeholder={placeholderString}
      /> */}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel sx={{ marginRight: '8px' }}>
            {t('accountIdLabel')}
          </InputLabel>
          <Required />
        </Box>

        <TextField
          sx={{ flex: '1 1 auto', minWidth: '170px' }} // Adjusts to fill available space
          id='account-id'
          variant='outlined'
          inputProps={{ maxLength: 9 }}
          value={account_id || ''}
          onChange={(e) => onChangeUpdateAccountId(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginTop: '1rem'
        }}
      >
        <Button
          variant='contained'
          sx={{
            height: '35px',
            width: '150px', // Fixed width to avoid stretching too much
            borderRadius: '5px',
            fontWeight: 600,
            fontSize: '14px'
          }}
          onClick={() => onStartCallsession(Languages.ENGLISH)}
          id='search-button'
          disabled={startCallsessionDisable}
        >
          {t('startCallsessionEN')}
        </Button>
        <Button
          variant='contained'
          sx={{
            height: '35px',
            width: '150px', // Fixed width to avoid stretching too much
            borderRadius: '5px',
            fontWeight: 600,
            fontSize: '14px'
          }}
          onClick={() => onStartCallsession(Languages.SPANISH)}
          id='search-button'
          disabled={startCallsessionDisable}
        >
          {t('startCallsessionES')}
        </Button>
      </Box>
      {/* <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        autoHideDuration={3000}
        message='Please enter at least three to characters to search.'
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false)
        }}
      /> */}
      <SearchResultMenu />
    </Box>
  )
}

export default SearchBarComponent
