import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Box } from '@mui/material'
import { useAppDispatch, type RootState } from '../../store/ConfigureStore'
import { useSelector } from 'react-redux'
import { updateSelectedLanguage } from '../../store/callsession/callsessionSlice'
import { Languages } from 'store/languages'

const LanguageSwitcher: React.FC = () => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()

  const language = useSelector(
    (state: RootState) => state.agentCallSession.selectedLanguage
  )
  const [langSelected, setLangSelected] = useState(language || i18n.language)

  const changeLanguage = (lg: string) => {
    if (lg) {
      const lang = (lg.split('-')[0] || Languages.ENGLISH) as Languages
      i18n.changeLanguage(lang)
      setLangSelected(lang)
      dispatch(updateSelectedLanguage(lang))
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        margin: '0rem 0rem .5rem 0rem'
      }}
    >
      <Button
        style={{ margin: '0rem .5rem' }}
        variant={langSelected === Languages.ENGLISH ? 'contained' : 'outlined'}
        size='small'
        onClick={() => changeLanguage(Languages.ENGLISH)}
      >
        English 🇺🇸
      </Button>
      <Button
        style={{ margin: '0rem .5rem' }}
        variant={langSelected === Languages.SPANISH ? 'contained' : 'outlined'}
        size='small'
        onClick={() => changeLanguage(Languages.SPANISH)}
      >
        Español 🇪🇸
      </Button>
    </Box>
  )
}

export default LanguageSwitcher
