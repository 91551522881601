import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICallSessionRootCause,
  ICallSessionRootCauseState
} from '../../types/callsession/CallSessionRootCause'

const basePath = '/callsession-root-cause/'

export class CallSessionRootCauseAPI {
  public list = async (
    params?: IAPIParams,
    signal?: AbortSignal | null
  ): Promise<ICallSessionRootCause[]> => {
    const result = await ApiRequest.get<ICallSessionRootCause[]>(
      basePath + 'list/',
      signal
    )
    return result.body
  }
}

const callSessionRootCauseAPI = new CallSessionRootCauseAPI()

export const fetchCallSessionRootCauses = createAsyncThunk(
  'callSessionRootCause/fetchCallSessionRootCauses',
  async (): Promise<ICallSessionRootCause[]> => {
    return await callSessionRootCauseAPI.list()
  }
)

const initialState: ICallSessionRootCauseState = {
  rootCauses: [],
  loading: false,
  error: null
}

const callSessionRootCausesAPISlice = createSlice({
  name: 'callsessionRootCause',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCallSessionRootCauses.pending,
        (state: ICallSessionRootCauseState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        fetchCallSessionRootCauses.fulfilled,
        (state: ICallSessionRootCauseState, action) => {
          state.loading = false
          state.error = null
          state.rootCauses = action.payload
        }
      )
      .addCase(
        fetchCallSessionRootCauses.rejected,
        (state: ICallSessionRootCauseState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.rootCauses = []
        }
      )
  }
})

export default callSessionRootCausesAPISlice
