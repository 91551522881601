import { type IAPIParams, type IListResponseWrapper } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  type ICustomerSearch,
  type ICustomerSearchState
} from '../../types/customer/Customer'
import { Repository } from '../../../utils/enums'

const basePath = '/customer/'

export interface CustomerSearchParam extends IAPIParams {
  searchTerm: string
  repository?: Repository
}

export class CustomerSearchAPI {
  public search = async (
    params?: CustomerSearchParam,
    signal?: AbortSignal | null
  ): Promise<ICustomerSearch[]> => {
    const result = await ApiRequest.get<IListResponseWrapper<ICustomerSearch>>(
      basePath + 'list/',
      signal,
      params
    )
    return result.body.results
  }
}

const customerSearchAPI = new CustomerSearchAPI()

export const searchCustomer = createAsyncThunk(
  'customer/searchCustomer',
  async (params: CustomerSearchParam): Promise<ICustomerSearch[]> => {
    return await customerSearchAPI.search(params)
  }
)

const initialState: ICustomerSearchState = {
  customers: null,
  loading: false,
  error: null
}

const customerSearchAPISlice = createSlice({
  name: 'customerSearch',
  initialState,
  reducers: {
    resetCustomerSearch: (state: ICustomerSearchState) => {
      state.customers = null
      state.loading = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCustomer.pending, (state: ICustomerSearchState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        searchCustomer.fulfilled,
        (state: ICustomerSearchState, action) => {
          state.loading = false
          state.error = null
          state.customers = action.payload
        }
      )
      .addCase(searchCustomer.rejected, (state: ICustomerSearchState) => {
        state.loading = false
        state.error = 'Error searching customers'
        state.customers = null
      })
  }
})

export default customerSearchAPISlice
export const { resetCustomerSearch } = customerSearchAPISlice.actions
