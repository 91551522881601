import { type IAPIParams } from '../types'
import ApiRequest from '../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IAction, IActionState } from '../types/Action'
import { Repository } from 'utils/enums'

const basePath = '/action/'

export interface ActionAPIParams extends IAPIParams {
  root_cause_id: number
  dropped_product_id?: number
  competitor_mentioned?: boolean
  deactivation?: boolean
  data_network?: string
  account_id?: string
  customer_already_switched?: boolean
  repository?: Repository
}

export class ActionAPI {
  public list = async (
    params?: ActionAPIParams,
    signal?: AbortSignal | null
  ): Promise<IAction[]> => {
    const result = await ApiRequest.get<IAction[]>(
      basePath + 'list/',
      signal,
      params
    )
    return result.body
  }
}

const actionAPI = new ActionAPI()

export const fetchActions = createAsyncThunk(
  'action/fetchActions',
  async (params: ActionAPIParams): Promise<IAction[]> => {
    return await actionAPI.list(params)
  }
)

const initialState: IActionState = {
  actions: [],
  loading: false,
  error: null
}

const actionsAPISlice = createSlice({
  name: 'callsessionIntent',
  initialState,
  reducers: {
    resetActions: (state: IActionState) => {
      state.actions = []
      state.loading = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActions.pending, (state: IActionState) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchActions.fulfilled, (state: IActionState, action) => {
        state.loading = false
        state.error = null
        state.actions = action.payload
      })
      .addCase(fetchActions.rejected, (state: IActionState, action) => {
        state.loading = false
        state.error = action.payload as string
        state.actions = []
      })
  }
})

export const { resetActions } = actionsAPISlice.actions
export default actionsAPISlice
