import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerProductMetric,
  ICustomerProductMetricState
} from '../../types/customer/CustomerProductMetric'
import { Repository } from '../../../utils/enums'

const basePath = '/account-service-usage/'

export interface GetCustomerProductMetricsParams extends IAPIParams {
  telephone: string
  repository?: Repository
}

export class CustomerProductMetricAPI {
  public get = async (
    params: GetCustomerProductMetricsParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerProductMetric[]> => {
    const result = await ApiRequest.get<ICustomerProductMetric[]>(
      basePath + params.telephone + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerProductMetricAPI = new CustomerProductMetricAPI()

export const getCustomerProductMetrics = createAsyncThunk(
  'getCustomerProductMetrics',
  async (
    params: GetCustomerProductMetricsParams
  ): Promise<ICustomerProductMetric[]> => {
    return await customerProductMetricAPI.get(params)
  }
)

const initialState: ICustomerProductMetricState = {
  service_usages: {
    success: false,
    telephone: '',
    product_metrics: []
  },
  loading: false,
  error: null
}

const customerProductMetricsAPISlice = createSlice({
  name: 'service_usages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCustomerProductMetrics.pending,
        (state: ICustomerProductMetricState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        getCustomerProductMetrics.fulfilled,
        (state: ICustomerProductMetricState, action) => {
          state.loading = false
          state.error = null
          state.service_usages =
            action.payload as unknown as ICustomerProductMetricState['service_usages']
        }
      )
      .addCase(
        getCustomerProductMetrics.rejected,
        (state: ICustomerProductMetricState) => {
          state.loading = false
          state.error = 'Error fetching customer product metrics.'
          state.service_usages = {
            success: false,
            telephone: '',
            product_metrics: []
          }
        }
      )
  }
})

export default customerProductMetricsAPISlice
