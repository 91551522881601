import React from 'react'

import { CheckButton } from 'components/input/CheckButton'
import { ButtonCol } from '../../components/ButtonCol'
import { RejectButton } from 'components/input/RejectButton'

interface IOfferItemControls {
  id: number
  disabled: boolean
  accepted: boolean
  rejected: boolean
  notOffered: boolean
  onNotOfferedClick: (id: number, notOffered: boolean) => void // eslint-disable-line no-unused-vars
  onAcceptClick: () => void
  onRejectClick: () => void
}

export const OfferItemControls = ({
  id,
  disabled,
  accepted,
  rejected,
  notOffered,
  onNotOfferedClick,
  onAcceptClick,
  onRejectClick
}: IOfferItemControls) => {
  return (
    <>
      <ButtonCol>
        <CheckButton
          checked={accepted}
          onClick={onAcceptClick}
          disabled={disabled}
          acceptedOfferButton={accepted}
        />
      </ButtonCol>
      <ButtonCol>
        <RejectButton
          reject={rejected}
          onClick={onRejectClick}
          disabled={disabled}
        />
      </ButtonCol>
      <ButtonCol>
        <CheckButton
          checked={notOffered}
          onClick={() => onNotOfferedClick(id, notOffered)}
          disabled={disabled}
        />
      </ButtonCol>
    </>
  )
}
