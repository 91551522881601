import React from 'react'
import List from '@mui/material/List'
import { styled, useTheme } from '@mui/material/styles'
import Tooltip, {
  tooltipClasses,
  type TooltipProps
} from '@mui/material/Tooltip'

export const NavTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.popper}`]: {
    marginLeft: -10,
    position: 'relative'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgb(59, 64, 76)'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgb(59, 64, 76)',
    padding: '0.5rem'
  }
}))

export const NavList = styled(List)(() => {
  const theme = useTheme()
  const textColor = 'white'
  return {
    width: 240,
    color: textColor,
    background: theme.palette.primary.dark,
    '& .MuiListSubheader-root': {
      fontSize: '0.875rem',
      color: textColor,
      fontWeight: 600
    },
    '& .MuiListItemButton-root': {
      height: '3rem',
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
      paddingLeft: '18px',
      borderRadius: 5,
      background: 'transparent',
      transition: 'background .6s ease',
      '&:hover': {
        background: 'rgba(30, 155, 229, 0.5)'
      },
      ':not(.MuiCollapse-entered &)': {
        width: '4rem',
        overflow: 'hidden'
      }
    },
    '& .MuiDivider-root': {
      margin: '1rem 0.25rem',
      borderColor: 'rgba(189, 191, 191, 0.3)',
      transition: 'width 1s .4s ease',
      ':not(.MuiCollapse-entered &)': {
        width: '2rem'
      }
    },
    '& .MuiListItemButton-root, & .MuiListSubheader-root': {
      '&.Mui-selected': {
        background: '#1E9BE5',
        color: theme.palette.primary.main
      },
      '& .MuiListItemText-root .MuiTypography-root': {
        fontSize: '0.875rem',
        color: textColor,
        fontWeight: 600,
        opacity: 0,
        transition: 'opacity .4s ease',
        '.MuiCollapse-entered &': {
          opacity: 1
        }
      },
      '& .MuiListItemIcon-root': {
        minWidth: '47px',
        width: '47px'
      },
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        width: 20,
        minWidth: 20,
        height: 20,
        color: textColor,
        marginRight: '1rem'
      },
      '&.Mui-selected > .MuiListItemText-root > .MuiTypography-root': {
        color: textColor
      },
      '&.Mui-selected > .MuiListItemIcon-root .MuiSvgIcon-root': {
        color: textColor
      },
      '& .MuiListItemButton-root': {
        '&:hover': {
          background: 'rgba(77, 82, 93)'
        }
      }
    }
  }
})
