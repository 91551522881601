import ApiRequest from '../ApiRequest'
import { type IUser, type IUserState } from '../types'

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LOCAL_STORAGE_KEYS } from '../../constants'

const basePath = '/auth/'

export interface LoginParams {
  username: string
  password: string
}

export class AuthAPI {
  public login = async (
    username: string,
    password: string,
    signal?: AbortSignal | null
  ): Promise<IUser> => {
    const result = await ApiRequest.post<IUser, LoginParams>(
      basePath + 'token/',
      signal,
      {
        username,
        password
      }
    )
    return result.body
  }

  public logout = async (signal?: AbortSignal | null): Promise<IUser> => {
    const result = await ApiRequest.post<IUser>(
      basePath + 'logout/',
      signal,
      {}
    )
    return result.body
  }

  public me = async (signal?: AbortSignal | null): Promise<IUser> => {
    const result = await ApiRequest.get<IUser>(basePath + 'me/', signal)
    return result.body
  }
}

const authAPI = new AuthAPI()

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (params: LoginParams, { rejectWithValue }) => {
    try {
      return await authAPI.login(params.username, params.password)
    } catch (error: any) {
      return rejectWithValue(
        (error.body.detail as string) || 'Error logging in'
      )
    }
  }
)

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await authAPI.logout()
    } catch (error: any) {
      return rejectWithValue(
        (error.body.detail as string) || 'Error logging out'
      )
    }
    dispatch(clearUser())
  }
)

const initialState: IUserState = {
  user: {
    id: -1,
    email: '',
    username: '',
    roles: [],
    isAuthenticated: false
  },
  loading: false,
  error: null
}

export const clearUserAndStorage = createAsyncThunk(
  'auth/clearUserAndStorage',
  async (_, { dispatch }) => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.access_key)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.access_key_expires_in)
    dispatch(clearUser())
  }
)

export const getUserAfterSSOLogin = createAsyncThunk(
  'auth/getUserAfterSSOLogin',
  async (_, { rejectWithValue }) => {
    try {
      return await authAPI.me()
    } catch (error: any) {
      return rejectWithValue(
        (error.body.detail as string) || 'Error getting user'
      )
    }
  }
)

const authAPISlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearUser: (state: IUserState) => {
      state.user = initialState.user
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state: IUserState) => {
        state.loading = true
        state.error = null
      })
      .addCase(loginUser.fulfilled, (state: IUserState, action) => {
        state.loading = false
        state.user = action.payload
        state.user.isAuthenticated = true
      })
      .addCase(loginUser.rejected, (state: IUserState, action) => {
        state.loading = false
        state.user.isAuthenticated = false
        state.error = action.payload as string
      })
      .addCase(logoutUser.pending, (state: IUserState) => {
        state.loading = true
        state.error = null
      })
      .addCase(logoutUser.fulfilled, (state: IUserState) => {
        state.loading = false
        state.user = initialState.user
      })
      .addCase(logoutUser.rejected, (state: IUserState, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(getUserAfterSSOLogin.pending, (state: IUserState) => {
        state.loading = true
        state.error = null
      })
      .addCase(getUserAfterSSOLogin.fulfilled, (state: IUserState, action) => {
        state.loading = false
        state.user = action.payload
        state.user.isAuthenticated = true
      })
      .addCase(getUserAfterSSOLogin.rejected, (state: IUserState, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  }
})

export default authAPISlice
export const { clearUser } = authAPISlice.actions
