import {
  ICustomerAddress,
  ICustomerAddressState
} from '../../types/customer/CustomerAddress'
import { IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-address/'

export interface GetCustomerAddressParam extends IAPIParams {
  account_id: string
  repository?: Repository
}

export class CustomerAddressAPI {
  public get = async (
    params: GetCustomerAddressParam,
    signal?: AbortSignal | null
  ): Promise<ICustomerAddress> => {
    const result = await ApiRequest.get<ICustomerAddress>(
      basePath + params.account_id + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerAddressAPI = new CustomerAddressAPI()

export const getCustomerAddress = createAsyncThunk(
  'customer/getCustomerAddress',
  async (params: GetCustomerAddressParam): Promise<ICustomerAddress> => {
    return await customerAddressAPI.get(params)
  }
)

const initialState: ICustomerAddressState = {
  customerAddress: null,
  loading: false,
  error: null
}

export const customerAddressAPISlice = createSlice({
  name: 'customerAddress',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerAddress.pending, (state: ICustomerAddressState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        getCustomerAddress.fulfilled,
        (state: ICustomerAddressState, action) => {
          state.loading = false
          state.error = null
          state.customerAddress = action.payload
        }
      )
      .addCase(getCustomerAddress.rejected, (state: ICustomerAddressState) => {
        state.loading = false
        state.error = 'Failed to fetch customer address.'
        state.customerAddress = null
      })
  }
})

export default customerAddressAPISlice.reducer
