import config from 'config'
import APIClient from './BaseApiClient'
import { clearUserAndStorage } from './AuthAPI'
import { redirect } from 'react-router'
import { loginPageURL } from 'routes/routes'

export class BuddyAPIClient extends APIClient {
  static readonly baseUrl: string = config.api.baseUrl

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  static readonly onHttp401 = async () => {
    const { store } = await import('store/ConfigureStore') // avoid circular import
    store.dispatch(clearUserAndStorage())
    redirect(loginPageURL())
  }
}
