import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICallSessionOutcome,
  ICallSessionOutcomeState
} from '../../types/callsession/CallSessionOutcome'

const basePath = '/callsession-outcome/'

export interface ICallSessinOutcomeAPIParams extends IAPIParams {
  deactivated_account?: boolean
}

export class CallSessionOutcomeAPI {
  public list = async (
    params?: ICallSessinOutcomeAPIParams,
    signal?: AbortSignal | null
  ): Promise<ICallSessionOutcome[]> => {
    const result = await ApiRequest.get<ICallSessionOutcome[]>(
      basePath + 'list/',
      signal,
      params
    )
    return result.body
  }
}

const callSessionOutcomeAPI = new CallSessionOutcomeAPI()

export const fetchCallSessionOutcomes = createAsyncThunk(
  'callSessionOutcome/fetchCallSessionOutcomes',
  async (
    params: ICallSessinOutcomeAPIParams
  ): Promise<ICallSessionOutcome[]> => {
    return await callSessionOutcomeAPI.list(params)
  }
)

const initialState: ICallSessionOutcomeState = {
  callSessionOutcomes: [],
  loading: false,
  error: null
}

const callSessionOutcomesAPISlice = createSlice({
  name: 'callsessionOutcome',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCallSessionOutcomes.pending,
        (state: ICallSessionOutcomeState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        fetchCallSessionOutcomes.fulfilled,
        (state: ICallSessionOutcomeState, action) => {
          state.loading = false
          state.error = null
          state.callSessionOutcomes = action.payload
        }
      )
      .addCase(
        fetchCallSessionOutcomes.rejected,
        (state: ICallSessionOutcomeState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.callSessionOutcomes = []
        }
      )
  }
})

export default callSessionOutcomesAPISlice
