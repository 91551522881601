import React, { useRef, useState } from 'react'
import {
  SelectChangeEvent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Paper
} from '@mui/material'
import { Dropdown } from 'components/input/Dropdown'
import { Stack } from '@mui/system'
import { styled } from '@mui/material/styles'
import { Required } from 'components/input/Required'
import { InputLabel } from 'components/input/InputLabel'
import { RootState, useAppDispatch, useAppSelector } from 'store/ConfigureStore'
import {
  updatePinRequested,
  updateCallSessionIntent,
  updateTransferPin
} from 'store/callsession/callsessionSlice'
import { useTranslation } from 'react-i18next'
import {
  CallFlowSection,
  completeSection,
  openSection
} from 'store/callflow/collapsableSections'
import { getRelatedLangItem } from 'store/languages'

const LabelContainer = styled('div')({
  width: '290px'
})
const InputContainer = styled('div')({ width: '300px' })

export const IntroductionSection = () => {
  const [toggleTransferPin, setToggleTransferPin] = useState<boolean>(false)
  const isTransferPinLoaded = useRef<boolean>(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation('introduction')

  const { no_core_services } = useAppSelector(
    (state: RootState) => state.customerCoreServiceAPI
  )

  const {
    callSessionIntents,
    callSessionIntent,
    callTransferPin,
    canPerformCall,
    agentUser,
    selectedLanguage
  } = useAppSelector((state) => ({
    callSessionIntents: state.callSessionIntentAPI.callSessionIntents,
    callSessionIntent: state.agentCallSession.call_session_intent,
    callTransferPin: state.agentCallSession.transferPin,
    canPerformCall: state.agentCallSession.sessionCanBePerformed,
    agentUser: state.agentCallSession.user,
    selectedLanguage: state.agentCallSession.selectedLanguage
  }))

  const handleIntentChange = (event: SelectChangeEvent) => {
    const intent = callSessionIntents.find(
      (intent) => intent.id === (event.target.value as unknown as number)
    )
    dispatch(completeSection(CallFlowSection.INTRODUCTION))
    dispatch(updateCallSessionIntent(intent))
    dispatch(completeSection(CallFlowSection.INTRODUCTION))
    dispatch(openSection(CallFlowSection.CONTEXT_SETTING))
  }

  const switchHandler = () => {
    if (!isTransferPinLoaded.current) {
      isTransferPinLoaded.current = true
      dispatch(updateTransferPin('[transfer PIN]'))
    }
    setToggleTransferPin(!toggleTransferPin)
    dispatch(updatePinRequested(!toggleTransferPin))
  }

  React.useEffect(() => {
    if (!selectedLanguage || !callSessionIntent) return
    const newIntent = getRelatedLangItem(
      callSessionIntent.id,
      callSessionIntents,
      selectedLanguage
    )

    dispatch(completeSection(CallFlowSection.INTRODUCTION))
    dispatch(updateCallSessionIntent(newIntent))
  }, [callSessionIntents])

  const renderListItem = (primaryText: string) => (
    <ListItem sx={{ display: 'list-item', p: 0, pl: 1 }}>
      <ListItemText primary={primaryText} />
    </ListItem>
  )

  const isCustomerEligible = no_core_services || !canPerformCall

  return (
    <Stack
      sx={{
        background: '#f8f8f8',
        padding: '20px 20px 9px 20px',
        borderRadius: '3px'
      }}
      gap={0.6}
    >
      {isCustomerEligible ? (
        <Typography variant='h3'>
          <ol>
            <li style={{ padding: '5px' }}>{t('noServicesCheckHub')}</li>
            <li style={{ padding: '5px' }}>{t('goToSessionOutcome')}</li>
          </ol>
        </Typography>
      ) : (
        <>
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            {renderListItem(
              t('introName', {
                firstName: agentUser?.first_name,
                lastName: agentUser?.last_name
              })
            )}
            {renderListItem(t('confirmAccount'))}
            {renderListItem(t('loyalCustomer'))}
          </List>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('rootCauseIntro')}
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            {renderListItem(t('exploreOptions'))}
            {renderListItem(t('commonIssues'))}
          </List>

          <Stack direction='row' alignItems='center' gap={1}>
            <LabelContainer>
              <InputLabel>{t('cancelService')}</InputLabel>
              <Required />
            </LabelContainer>
            <InputContainer>
              <Dropdown
                id='customer-intent-dropdown'
                value={callSessionIntent?.id.toString()}
                onChange={handleIntentChange}
                items={callSessionIntents}
                error={callSessionIntent === null}
              />
            </InputContainer>
          </Stack>

          <Stack direction='row' alignItems='center' gap={1}>
            <LabelContainer>
              <InputLabel>{t('requestNTP')}</InputLabel>
              <Required />
            </LabelContainer>
            <Switch onChange={switchHandler} />
          </Stack>
          <Typography
            sx={{
              maxHeight: toggleTransferPin ? '200px' : '0px',
              opacity: toggleTransferPin ? 1 : 0,
              transition: 'all 0.3s ease-in-out'
            }}
          >
            <Paper
              elevation={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                padding: '16px',
                margin: '10px',
                height: 'auto',
                fontWeight: 600
              }}
            >
              {t('transferPin', { transferPin: callTransferPin })}
            </Paper>
          </Typography>
        </>
      )}
    </Stack>
  )
}
