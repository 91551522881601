import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum AccountItemSection {
  // eslint-disable-next-line no-unused-vars
  INTERNET_PRODUCTS = 'INTERNET_PRODUCTS',
  // eslint-disable-next-line no-unused-vars
  VIDEO_PRODUCTS = 'VIDEO_PRODUCTS',
  // eslint-disable-next-line no-unused-vars
  VOICE_PRODUCTS = 'VOICE_PRODUCTS',
  // eslint-disable-next-line no-unused-vars
  VAS = 'VAS',
  // eslint-disable-next-line no-unused-vars
  OTHER = 'OTHER'
}

export interface AccountItemSectionState {
  openSections: {
    // eslint-disable-next-line no-unused-vars
    [key in AccountItemSection]: boolean
  }
}

export const initialAccountItemsSectionsState: AccountItemSectionState = {
  openSections: {
    [AccountItemSection.INTERNET_PRODUCTS]: false,
    [AccountItemSection.VIDEO_PRODUCTS]: false,
    [AccountItemSection.VOICE_PRODUCTS]: false,
    [AccountItemSection.VAS]: false,
    [AccountItemSection.OTHER]: false
  }
}

export const accountItemsSectionsSlice = createSlice({
  name: 'collapsableSections',
  initialState: initialAccountItemsSectionsState,
  reducers: {
    toggleAccountItemSection: (
      state: AccountItemSectionState,
      action: PayloadAction<AccountItemSection>
    ) => {
      const section = action.payload
      state.openSections[section] = !state.openSections[section]
    },
    closeAllAccountItemSections: (state: AccountItemSectionState) => {
      for (const section in state.openSections) {
        state.openSections[section as AccountItemSection] = false
      }
    }
  }
})

export const { toggleAccountItemSection, closeAllAccountItemSections } =
  accountItemsSectionsSlice.actions
