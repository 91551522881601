import React from 'react'

import { Box } from '@mui/system'

const propertiesHeader = {
  width: '80px',
  color: '#272727',
  // fontFamily: 'Arial',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  my: 'auto'
}

const propertiesMainHeader = {
  ...propertiesHeader,
  flexGrow: 1
}

const propertiesHeader2 = {
  ...propertiesHeader,
  textAlign: 'center'
}

interface IActionsHeader {
  name: string
  columns?: string[]
}

export const ActionsHeader = ({ name, columns }: IActionsHeader) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '13px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #D8D8D8',
        color: '#272727',
        // fontFamily: 'Arial',
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        textTransform: 'uppercase',
        textWrap: 'nowrap'
      }}
    >
      <Box sx={{ ...propertiesMainHeader }}>{name}</Box>

      {columns?.map((column) => (
        <Box key={column} sx={propertiesHeader2}>
          {column}
        </Box>
      ))}
    </Box>
  )
}
