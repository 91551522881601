import {
  ICustomerEmail,
  ICustomerEmailState
} from '../../types/customer/CustomerEmail'
import { IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Repository } from '../../../utils/enums'

const basePath = '/customer-email/'

export interface GetCustomerEmailParam extends IAPIParams {
  account_id: string
  repository?: Repository
}

export class CustomerEMailAPI {
  public get = async (
    params: GetCustomerEmailParam,
    signal?: AbortSignal | null
  ): Promise<ICustomerEmail> => {
    const result = await ApiRequest.get<ICustomerEmail>(
      basePath + params.account_id + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerEmailAPI = new CustomerEMailAPI()

export const getCustomerEmail = createAsyncThunk(
  'customer/getCustomerEmail',
  async (params: GetCustomerEmailParam): Promise<ICustomerEmail> => {
    return await customerEmailAPI.get(params)
  }
)

const initialState: ICustomerEmailState = {
  customerEmail: null,
  loading: false,
  error: null
}

export const customerEmailAPISlice = createSlice({
  name: 'customerEmail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerEmail.pending, (state: ICustomerEmailState) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        getCustomerEmail.fulfilled,
        (state: ICustomerEmailState, action) => {
          state.loading = false
          state.error = null
          state.customerEmail = action.payload
        }
      )
      .addCase(getCustomerEmail.rejected, (state: ICustomerEmailState) => {
        state.loading = false
        state.error = 'Failed to fetch customer email.'
        state.customerEmail = null
      })
  }
})

export default customerEmailAPISlice.reducer
