import { IOffer } from 'buddy-api/types/Offer'
import { IWinbackOffer } from 'store/callsession/callsessionSlice'
import { roundPrice } from './utils'

export const buildWinbackOffers = (offers: IOffer[]): IWinbackOffer[] => {
  return offers.map((offer) => ({
    id: offer.id,
    title: offer.title,
    product: offer.product,
    script: offer.script,
    offerAmount: roundPrice(offer.offer_amount),
    notOffered: false,
    accepted: false,
    rejected: false,
    expanded: false
  }))
}
