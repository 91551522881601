import React, { useEffect } from 'react'
import { Box } from '@mui/system'
import { ActionItem } from './ActionItem'
import { useAppDispatch, useAppSelector } from 'store/ConfigureStore'
import {
  updateCallsessionActionAccept,
  handleActionOffered,
  updateCallsessionActionReject,
  updateAgentCallSessionActionComments,
  updateAgentCallSessionActions
} from 'store/callsession/callsessionSlice'
import { ActionsHeader } from '../../components/ActionsHeader'
import { fetchActions } from '../../../../../buddy-api/api/ActionAPI'
import { IAction } from '../../../../../buddy-api/types/Action'
import { ICallSessionAction } from '../../../../../buddy-api/types/callsession/CallSessionAction'
import { YesNoOptions } from 'utils/enums'
import { TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const Actions = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('actions')
  const actions = useAppSelector((state) => state.actionsAPISlice.actions)
  const { action_comments } = useAppSelector((state) => state.agentCallSession)

  const deactivate =
    useAppSelector(
      (state) => state.agentCallSession.call_session_intent?.label
    ) === YesNoOptions.yes
  const competitor = useAppSelector(
    (state) => state.agentCallSession.selected_callsession_competitor
  )
  // const dropped_product = useAppSelector(
  //   (state) => state.agentCallSession.dropped_product
  // )
  const root_cause = useAppSelector(
    (state) => state.agentCallSession.call_session_root_cause
  )

  const { callsession_actions } = useAppSelector(
    (state) => state.agentCallSession
  )

  const { core_services } = useAppSelector(
    (state) => state.customerCoreServiceAPI
  )
  const updateActionComment = (comment: string) => {
    dispatch(updateAgentCallSessionActionComments(comment))
  }

  const alreadySwitched =
    useAppSelector(
      (state) => state.agentCallSession.already_switched_to_competitor?.name
    ) === YesNoOptions.yes

  const getDataNetwork = () => {
    if (core_services?.data_network !== 'COPPER') {
      return 'FIBER'
    } else {
      return 'COPPER'
    }
  }

  const getActions = () => {
    const root_cause_id = root_cause?.id
    // const dropped_product_id = dropped_product?.id
    const data_network = getDataNetwork()
    // const account_id = customer?.account_id
    const competitorMentioned = (competitor && competitor.id !== 0) ?? false

    if (root_cause_id !== undefined) {
      dispatch(
        fetchActions({
          root_cause_id: root_cause_id,
          competitor_mentioned: competitorMentioned,
          deactivation: deactivate,
          data_network: data_network,
          customer_already_switched: alreadySwitched
        })
      )
    }
  }

  useEffect(() => {
    getActions()
  }, [deactivate, competitor, root_cause])

  useEffect(() => {
    dispatch(updateAgentCallSessionActions(convertToCallSessionAction()))
  }, [actions])

  const convertToCallSessionAction = (): ICallSessionAction[] => {
    return actions.map((action: IAction) => {
      return {
        ...action,
        offered: false,
        accepted: false,
        rejected: false
      }
    })
  }

  const renderActionItem = (action: ICallSessionAction, index: number) => {
    return (
      <ActionItem
        index={index}
        key={action.id}
        title={action.title}
        script={action.description}
        offered={action.offered}
        accepted={action.accepted}
        rejected={action.rejected}
        type={action.type}
        onOfferedClick={() => dispatch(handleActionOffered(action.id))}
        onAcceptClick={() => {
          dispatch(updateCallsessionActionAccept(action.id))
        }}
        onRejectClick={() => {
          dispatch(updateCallsessionActionReject(action.id))
        }}
      />
    )
  }

  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <Typography>
        <strong>{t('relevantActions')}</strong>
      </Typography>
      <Box>
        <ActionsHeader
          name={t('endActionName')}
          columns={[t('done'), t('accepted'), t('rejected')]}
        />
        {callsession_actions.map((action, index) =>
          renderActionItem(action, index)
        )}
      </Box>
      {/* <Box>
        <ActionsHeader
          name={t('duringCallActionName')}
          columns={[t('done'), t('accepted'), t('rejected')]}
        />
        {callsession_actions.map((action, index) =>
          renderActionItem(action, index)
        )}
      </Box> */}
      <TextField
        sx={{ width: '100%' }}
        placeholder={t('placeholder')}
        variant='outlined'
        value={action_comments ?? ''}
        onChange={(e) => updateActionComment(e.target.value)}
      />
      <Typography>
        <strong>{t('closingRemarks')}</strong>
      </Typography>
    </Box>
  )
}
