import React, { ReactNode } from 'react'
import { Box, Stack } from '@mui/system'

import { InputLabel } from './InputLabel'
import { Required } from './Required'

export const InputRow = ({
  label,
  inputElem,
  required,
  inputWidth
}: {
  label?: string
  inputElem: ReactNode
  required?: boolean
  inputWidth?: string
}) => {
  return (
    <Stack direction='row' alignItems='center'>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <InputLabel>{label}</InputLabel>
        {required && <Required />}
      </Box>
      <Box sx={{ ml: label ? '9px' : '0px', width: inputWidth ?? '100%' }}>
        {inputElem}
      </Box>
    </Stack>
  )
}
