import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAppDispatch, useAppSelector } from '../../../store/ConfigureStore'
import { CallFlowSection } from '../../../store/callflow/collapsableSections'

import { openSectionIfValidated } from '../../../store/callflow/validation/validation'
import { Column, Row } from '../../../ui'
import { LegacyTag } from '../../../components/CustomerTopBar/CustomerInformation/AccountItems/LegacyTag'
import { useShowLegacyProductWarning } from './useShowLegacyProductWarning'
import { useShowVoiceAvailableWarning } from './useShowVoiceAvailableWarning'

export type CollapsableSectionProps = {
  section: CallFlowSection
  title: string
  children?: React.ReactNode
  disabled?: boolean
}

const ControlledAccordionOffer: React.FC<CollapsableSectionProps> = (
  props: CollapsableSectionProps
) => {
  const dispatch = useAppDispatch()
  const { openSections } = useAppSelector((state) => state.collapsableSections)
  const expanded = openSections[props.section].open

  const showLegacyProductWarning = useShowLegacyProductWarning()
  const showVoiceWarning = useShowVoiceAvailableWarning()

  const handleChange = () => {
    dispatch(openSectionIfValidated(props.section))
  }

  return (
    <>
      <Accordion
        disabled={props.disabled}
        expanded={expanded && !props.disabled}
        onChange={handleChange}
        sx={{
          width: '100%',
          boxShadow: 'none'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${props.section}-content`}
          id={`${props.section}-header`}
          sx={{
            backgroundColor: '#F8F8F8'
          }}
        >
          <Row
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Column
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'left'
              }}
            >
              <Typography>{props.title}</Typography>
            </Column>
            <Column
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100px',
                gap: '10px'
              }}
            >
              {/* Legacy warning */}
              {showLegacyProductWarning &&
              props.section === CallFlowSection.OFFERS ? (
                <LegacyTag
                  title={
                    'Due to legacy products and pricing, some offers might not be best suited for this customer.'
                  }
                />
              ) : null}

              {/* Voice availability warning */}
              {showVoiceWarning && props.section === CallFlowSection.OFFERS && (
                <LegacyTag
                  title={'Voice offers may not be available at new address'}
                />
              )}
            </Column>
          </Row>
        </AccordionSummary>
        <AccordionDetails sx={{ background: 'white' }}>
          {props.children}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default ControlledAccordionOffer
