import { Box, Typography } from '@mui/material'

import React from 'react'
import { Column, Row } from 'ui'
import TipsBulb from 'assets/images/TipsBulb.svg'
import { BattleCardInsights } from './BattleCardInsights'
import { useTranslation } from 'react-i18next'

export const CallSessionCompetitorPanel: React.FC<{ topValue: number }> = (
  topValue
) => {
  const { t } = useTranslation('callSessionCompetitorPanel')
  return (
    <Box
      sx={{
        position: 'sticky',
        top: topValue.topValue // Change the type of topValue to be a number
      }}
    >
      <Column
        sx={{
          border: '0px solid red',
          borderRadius: '3px 3px 0px 0px',
          backgroundColor: '#F8F8F8',
          margin: '10px'
        }}
      >
        <Row
          sx={{
            alignItems: 'center',
            my: 0,
            px: 0.5,
            py: '4px',
            borderBottom: '1px solid #E0E0E0',
            border: '1px solid #E0E0E0',
            backgroundColor: 'white',
            borderRadius: '3px 3px 0px 0px'
          }}
        >
          <img
            src={TipsBulb}
            style={{
              marginRight: 7,
              marginLeft: 8,
              width: '25px'
            }}
            alt='tips-bulb'
          />
          <Typography
            variant='overline'
            fontWeight={600}
            fontSize='12px'
            color='#3A404D'
          >
            {t('competitorLabel')}
          </Typography>
        </Row>

        <BattleCardInsights />
        {/* <OtherProvidersAtAddress /> */}
        <Row
          sx={{
            alignItems: 'left',
            mx: 1.5,
            px: 0.5,
            py: 1.5,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '3px'
          }}
        ></Row>
      </Column>
    </Box>
  )
}
