import { AgentCallSession } from '../../callsession/callsessionSlice'
import {
  MoveTypeOptions,
  ServiceCallAbout,
  YesNoOptions
} from '../../../utils/enums'
import { isDisconnectedCustomer } from '../../../lib/utils'

const isCustomerMoveValid = (
  agentCallSessionState: AgentCallSession
): boolean => {
  if (agentCallSessionState.call_session_root_cause?.name) return true

  // Selected move type is mandatory
  if (!agentCallSessionState.selected_move_type) return false

  // For short-term move, no other fields are required
  if (
    agentCallSessionState.selected_move_type.name ===
    MoveTypeOptions.shortTermMove
  )
    return true

  // Handle long-term move
  // Mandatory field
  if (!agentCallSessionState.selected_does_frontier_offer_service) return false

  // If frontier offers service at new addres, selecting new tech is mandatory
  if (
    agentCallSessionState.selected_does_frontier_offer_service.name ===
      YesNoOptions.yes &&
    !agentCallSessionState.selected_customer_move_new_tech
  )
    return false

  return true
}

const isCompedValid = (agentCallSessionState: AgentCallSession): boolean => {
  // For Account Service calls, selecting at least one sub root cause is mandatory
  if (
    agentCallSessionState.call_session_intent?.name ===
      ServiceCallAbout.NotDeactivation &&
    (!agentCallSessionState.call_session_subroot_cause ||
      agentCallSessionState.call_session_subroot_cause?.length < 1)
  ) {
    return false
  }

  // For deactivation calls, selecting the competitor field is mandatory
  if (
    agentCallSessionState.call_session_intent?.name ===
      ServiceCallAbout.Deactivation &&
    !agentCallSessionState.selected_callsession_competitor
  ) {
    return false
  }

  return true
}

const isDevicePlanDataDiscoveryFilled = (
  agentCallSessionState: AgentCallSession
): boolean => {
  return !!(
    agentCallSessionState.device &&
    agentCallSessionState.plan &&
    agentCallSessionState.data_usage
  )
}

export const isContextSectionValid = (
  agentCallSessionState: AgentCallSession
): boolean => {
  if (agentCallSessionState.reroute?.name === YesNoOptions.yes) {
    return true
  }
  const rootCauseValid = agentCallSessionState.call_session_root_cause !== null
  const compedValid = isCompedValid(agentCallSessionState)
  const customerMoveValid = isCustomerMoveValid(agentCallSessionState)
  const disconnectedCustomer = isDisconnectedCustomer(
    agentCallSessionState?.customer?.account_status
  )
  const devicePlanDataDiscoveryFilled = isDevicePlanDataDiscoveryFilled(
    agentCallSessionState
  )

  if (disconnectedCustomer) {
    return true
  }

  return (
    rootCauseValid &&
    customerMoveValid &&
    compedValid &&
    devicePlanDataDiscoveryFilled
  )
}
