import React, { useEffect, useState } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { grey, yellow, green, red } from '@mui/material/colors'
import { useAppSelector } from '../../store/ConfigureStore'
import { SmallVerticalSpace } from '../../ui'
import { IApiState } from 'buddy-api'

interface IApiStatusIcon {
  loading: boolean
  error: string | null
}

const ApiStatusIcon = (props: IApiStatusIcon) => {
  const { loading, error } = props
  if (loading) {
    return <CircularProgress size={20} style={{ color: yellow[800] }} />
  }
  if (error) {
    return <ErrorIcon style={{ color: red[500] }} />
  }
  return <CheckCircleIcon style={{ color: green[500] }} />
}

interface IApiStatusRow {
  title: string
  loading: boolean
  error: string | null
}

const APIStatusRow = (props: IApiStatusRow) => {
  const { title, loading, error } = props
  let color: string = green[500]
  if (loading) {
    color = yellow[800]
  } else if (error) {
    color = red[500]
  }

  return (
    <Box display='flex' alignItems='center' gap={1}>
      <ApiStatusIcon loading={loading} error={error} />
      <Typography style={{ color }}>{title}</Typography>
    </Box>
  )
}

export const FloatingAPIStatusCard = () => {
  const [status, setStatus] = useState({ visible: true, fadingOut: false })
  useEffect(() => {
    const timer = setTimeout(() => {
      setStatus({ visible: true, fadingOut: true })
      setTimeout(() => setStatus({ visible: false, fadingOut: false }), 1000) // duration of the fade-out
    }, 5000) // 20 seconds minus fade-out duration
    return () => clearTimeout(timer)
  }, [])

  const getStatus = (state: IApiState) => {
    return { loading: state.loading, error: state.error }
  }

  const apis: {
    key: string
    status: { loading: boolean; error: string | null }
  }[] = [
    {
      key: 'Customer Information',
      status: useAppSelector((state) => getStatus(state.customerAPI))
    },
    {
      key: 'Customer Products and Prices',
      status: useAppSelector((state) => getStatus(state.customerAccountItemAPI))
    },
    {
      key: 'Bill PDFs',
      status: useAppSelector((state) => getStatus(state.customerBillDocument))
    },
    {
      key: 'Customer Billing',
      status: useAppSelector((state) =>
        getStatus(state.customerServiceBillingAPI)
      )
    }
  ]

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        width: 300,
        padding: 2,
        bgcolor: grey[900],
        color: 'white',
        borderRadius: 2,
        boxShadow: 3,
        transition: status.fadingOut ? 'opacity 1s ease-out' : 'none',
        opacity: status.fadingOut || !status.visible ? 0 : 1
        // display: visible ? 'block' : 'none'
      }}
    >
      <Typography variant='h3' color='white'>
        API Status
      </Typography>
      <SmallVerticalSpace />
      {apis.map(({ key, status }) => {
        const { loading, error } = status
        return (
          <APIStatusRow
            key={key}
            title={`${key}`}
            loading={loading}
            error={error}
          />
        )
      })}
    </Box>
  )
}
