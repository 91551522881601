import { BuddyAPIClient as APIClient } from './api/BuddyApiClient'
import {
  type IAPIParams,
  type IAPIResponse,
  type IUserTokenInfo
} from './types'
import { LOCAL_STORAGE_KEYS } from '../constants'
import config from '../config'
import { Languages } from 'store/languages'

export function updateLocalStorageWithUserToken(token: IUserTokenInfo): void {
  localStorage.setItem(LOCAL_STORAGE_KEYS.access_key, token.access_token)
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.access_key_expires_in,
    token.access_token_expires.toString()
  )
}

export default class ApiRequest {
  static get baseUrl(): string {
    return APIClient.baseUrl
  }

  static readonly get = async <T>(
    path: string,
    signal: AbortSignal | null | undefined,
    params: IAPIParams = {}
  ): Promise<IAPIResponse<T>> => {
    const headers = ApiRequest.getHeaders({})
    const url = new URL(`${ApiRequest.baseUrl}${path}`)

    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(key, value as string)
    })

    const init: RequestInit = {
      method: 'GET',
      headers,
      signal
    }

    const response = fetch(url.toString(), init)

    return await ApiRequest.handleResponse<T>(response, url.toString(), init)
  }

  static readonly post = async <T, B = unknown>(
    path: string,
    signal: AbortSignal | null | undefined,
    data: B
  ): Promise<IAPIResponse<T>> => {
    const headers = ApiRequest.getHeaders({})
    const url = new URL(`${ApiRequest.baseUrl}${path}`)

    const init: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
      signal,
      credentials: 'include'
    }

    const response = fetch(url.toString(), init)

    return await ApiRequest.handleResponse<T>(response, url.toString(), init)
  }

  static readonly put = async <T, B = unknown>(
    path: string,
    signal: AbortSignal | null | undefined,
    data: B
  ): Promise<IAPIResponse<T>> => {
    const headers = ApiRequest.getHeaders({})
    const url = new URL(`${ApiRequest.baseUrl}${path}`)

    const init: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
      signal,
      credentials: 'include'
    }

    const response = fetch(url.toString(), init)

    return await ApiRequest.handleResponse<T>(response, url.toString(), init)
  }

  static readonly getHeaders = (
    customHeaders: Record<string, unknown>
  ): Headers => {
    const defaults = {
      contentType: 'application/json',
      acceptLanguage: localStorage.getItem('i18nextLng') || Languages.ENGLISH
    }
    const options = {
      ...defaults,
      ...customHeaders
    }
    const headers: Headers = new Headers()

    headers.set('Content-Type', options.contentType)
    headers.set('Accept-Language', options.acceptLanguage)
    headers.set(
      'Authorization',
      `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.access_key)}`
    )

    return headers
  }

  static readonly handleResponse = async <T>(
    responsePromise: Promise<Response>,
    originalRequest?: RequestInfo,
    init?: RequestInit
  ): Promise<IAPIResponse<T>> => {
    const response = await responsePromise

    let body: T

    switch (response.headers.get('Content-Type')) {
      case 'application/json':
        body = (await response.json()) as T
        break
      case 'text/html':
      default:
        body = (await response.text()) as unknown as T
        break
    }

    // @ts-expect-error: Element implicitly has an 'any' type
    const isRetryHeader = init?.headers?.['X-Retry']
    if (
      response.status === 401 &&
      originalRequest !== undefined &&
      isRetryHeader !== 'true'
    ) {
      const refreshed = await ApiRequest.refreshToken()
      if (refreshed) {
        // Update Authorization header with the new token
        const newHeaders = new Headers(init?.headers)
        newHeaders.set(
          'Authorization',
          `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.access_key)}`
        )
        newHeaders.set('X-Retry', 'true')

        // Retry the original request with the new token
        const retryResponse = await fetch(originalRequest, {
          headers: newHeaders,
          method: init?.method,
          body: init?.body,
          signal: init?.signal,
          credentials: init?.credentials
        })
        return await ApiRequest.handleResponse<T>(
          Promise.resolve(retryResponse)
        )
      }
    }

    switch (response.status) {
      case 400:
        APIClient.onHttp400?.(body)
        break
      case 401:
        APIClient.onHttp401?.()
        break
      case 403:
        APIClient.onHttp403?.(body)
        break
      case 404:
        APIClient.onHttp404?.(body)
        break
      case 500:
        APIClient.onHttp500?.(body)
        break
    }

    if (!response.ok) {
      throw new Error(
        `Response not ok. Response: ${JSON.stringify(response)}. Body: ${JSON.stringify(body)}`
      )
    }

    return {
      response,
      body
    }
  }

  static readonly refreshToken = async (): Promise<boolean> => {
    console.log('Attempting to refresh token')
    const url = new URL(`${config.api.baseUrl}/auth/refresh-token/`)
    try {
      const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      })

      if (!response.ok) {
        return false
      } else {
        const token = await response.json()
        updateLocalStorageWithUserToken(token as IUserTokenInfo)
        return true
      }
    } catch (e) {
      return false
    }
  }
}
