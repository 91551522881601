import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerMoveNewTechScript,
  ICustomerMoveNewTechScriptState
} from '../../types/callsession/CustomerMoveNewTechScripts'

const basePath = '/callsession-new-tech-script/'

export interface CallSessionNewTechScriptsParams extends IAPIParams {
  current_network: string
  new_tech_option_id: number
}

export class CallSessionNewTechScriptsAPI {
  public get = async (
    params?: CallSessionNewTechScriptsParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerMoveNewTechScript> => {
    const result = await ApiRequest.get<ICustomerMoveNewTechScript>(
      basePath,
      signal,
      params
    )
    return result.body
  }
}

const callSessionNewTechScriptsAPI = new CallSessionNewTechScriptsAPI()

export const fetchCallSessionNewTechScripts = createAsyncThunk(
  'callSessionNewTechScripts/fetchCallSessionNewTechScripts',
  async (
    params: CallSessionNewTechScriptsParams
  ): Promise<ICustomerMoveNewTechScript> => {
    return await callSessionNewTechScriptsAPI.get(params)
  }
)

const initialState: ICustomerMoveNewTechScriptState = {
  script: null,
  loading: false,
  error: null
}

const callSessionNewTechScriptsAPISlice = createSlice({
  name: 'callsessionNewTechScripts',
  initialState,
  reducers: {
    resetNewTechScript: (state: ICustomerMoveNewTechScriptState) => {
      state.script = null
      state.loading = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCallSessionNewTechScripts.pending,
        (state: ICustomerMoveNewTechScriptState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        fetchCallSessionNewTechScripts.fulfilled,
        (state: ICustomerMoveNewTechScriptState, action) => {
          state.loading = false
          state.error = null
          state.script = action.payload
        }
      )
      .addCase(
        fetchCallSessionNewTechScripts.rejected,
        (state: ICustomerMoveNewTechScriptState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.script = null
        }
      )
  }
})

export const { resetNewTechScript } = callSessionNewTechScriptsAPISlice.actions
export default callSessionNewTechScriptsAPISlice
