import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LicenseInfo } from '@mui/x-license-pro'
import 'antd/dist/antd.variable.min.css'
import config from 'config'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import MainRouter from 'routes/MainRouter'
import { Provider } from 'react-redux'
import { persistor, store } from './store/ConfigureStore'
import { PersistGate } from 'redux-persist/integration/react'

LicenseInfo.setLicenseKey(config.app.muiKey)

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <MainRouter />
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  )
}

export default App
