import { IOfferSet } from './callFlow'

export const offerSets: IOfferSet[] = [
  {
    id: '1',
    product: 'BB',
    offers: [
      {
        type: 'More-little more',
        product: 'BB',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you. We can go ahead and offer you that higher speed for the exact same price you're currently paying, [$x] per month. How does that sound?`
      },
      {
        type: 'More-same',
        product: 'BB',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you. We can go ahead and offer you that higher speed for the exact same price you're currently paying, [$x] per month. How does that sound?`
      },
      {
        type: 'Same-less',
        product: 'BB',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.

        We can offer you a plan that includes the exact same speeds you're currently enjoying , but for a lower price of [$x] per month. Does that work for you?`
      },
      {
        type: 'Same-lesser',
        product: 'BB',
        script: `Your business is extremely important to us, and we're determined to find a solution here. I'm working to find a customized offering that will be a perfect fit for you, both in terms of internet speed and monthly payments. We can continue to provide the same speeds you're currently getting, but at an even better price of [$x] per month. How does that sound?`
      }
    ]
  },
  {
    id: '2',
    product: 'BB',
    offers: [
      {
        type: 'More-same',
        product: 'BB',
        script: `Based on what you told me about your usage, it probably makes sense for you to upgrade your speed. You'll have even faster speeds and more reliable internet, and you'll be paying the exact same amount you're paying now. We can offer you [X MB] for [$X] per month. Does that work for you?`
      },
      {
        type: 'Same-less',
        product: 'BB',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.

        We can offer you a plan that includes the exact same speeds you're currently enjoying , but for a lower price of [$x] per month. Does that work for you?`
      },
      {
        type: 'Same-lesser',
        product: 'BB',
        script: `Your business is extremely important to us, and we're determined to find a solution here. I'm working to find a customized offering that will be a perfect fit for you, both in terms of internet speed and monthly payments. We can continue to provide the same speeds you're currently getting, but at an even better price of [$x] per month. How does that sound?`
      },
      {
        type: 'Same-floor',
        product: 'BB',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you.

        The very best pricing we can offer at the moment is the exact same speeds you're currently receiving, but for the much lower price of [$x] per month. How does that work for you?`
      }
    ]
  },
  {
    id: '3',
    product: 'BB',
    offers: [
      {
        type: 'Same-less',
        product: 'BB',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.
      `
      },
      {
        type: 'Same-lesser',
        product: 'BB',
        script: `Your business is extremely important to us, and we're determined to find a solution here. I'm working to find a customized offering that will be a perfect fit for you, both in terms of internet speed and monthly payments. We can continue to provide the same speeds you're currently getting, but at an even better price of [$x] per month. How does that sound?`
      },
      {
        type: 'Same-floor',
        product: 'BB',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you.

        The very best pricing we can offer at the moment is the exact same speeds you're currently receiving, but for the much lower price of [$x] per month. How does that work for you?`
      }
    ]
  },
  {
    id: '4',
    product: 'BB',
    offers: [
      {
        type: 'More-little more',
        product: 'BB',
        script: `Based on what you told me about your usage, it probably makes sense for you to upgrade your speed. You'll have even faster speeds and more reliable internet for just a slightly higher monthly cost. We can offer you [X MB] for [$X] per month. Does that work?`
      },
      {
        type: 'More-same',
        product: 'BB',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you. We can go ahead and offer you that higher speed for the exact same price you're currently paying, [$x] per month. How does that sound?`
      },
      {
        type: 'Same-less',
        product: 'BB',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.

        We can offer you a plan that includes the exact same speeds you're currently enjoying , but for a lower price of [$x] per month. Does that work for you?`
      },
      {
        type: 'Same-lesser',
        product: 'BB',
        script: `Your business is extremely important to us, and we're determined to find a solution here. I'm working to find a customized offering that will be a perfect fit for you, both in terms of internet speed and monthly payments. We can continue to provide the same speeds you're currently getting, but at an even better price of [$x] per month. How does that sound?`
      },
      {
        type: 'Less-less',
        product: 'BB',
        script: `Based on what I'm hearing, it sounds like it might make sense for us to take a different approach here to find the lowest possible cost for you. How about if we were to set you up with a plan with slightly reduced speeds, ensuring we can continue to deliver quality broadband internet to you for an even lower price of [$x] per month?`
      }
    ]
  },
  {
    id: '5',
    product: 'BB',
    offers: [
      {
        type: 'More-same',
        product: 'BB',
        script: `Based on what you told me about your usage, it probably makes sense for you to upgrade your speed. You'll have even faster speeds and more reliable internet, and you'll be paying the exact same amount you're paying now. We can offer you [X MB] for [$X] per month. Does that work for you?`
      },
      {
        type: 'Same-less',
        product: 'BB',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.`
      },
      {
        type: 'Same-lesser',
        product: 'BB',
        script: `Your business is extremely important to us, and we're determined to find a solution here. I'm working to find a customized offering that will be a perfect fit for you, both in terms of internet speed and monthly payments. We can continue to provide the same speeds you're currently getting, but at an even better price of [$x] per month. How does that sound?`
      },
      {
        type: 'Same-floor',
        product: 'BB',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you.

        The very best pricing we can offer at the moment for the exact same speeds you're currently receiving, but for the much lower price of [$x] per month. How does that work for you?`
      },
      {
        type: 'Less-less',
        product: 'BB',
        script: `Based on what I'm hearing, it sounds like it might make sense for us to take a different approach here to find the lowest possible cost for you. How about if we were to set you up with a plan with slightly reduced speeds, ensuring we can continue to deliver quality broadband internet to you for an even lower price of [$x] per month?`
      }
    ]
  },
  {
    id: '6',
    product: 'BB',
    offers: [
      {
        type: 'Same-less',
        product: 'BB',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.`
      },
      {
        type: 'Same-lesser',
        product: 'BB',
        script: `Your business is extremely important to us, and we're determined to find a solution here. I'm working to find a customized offering that will be a perfect fit for you, both in terms of internet speed and monthly payments. We can continue to provide the same speeds you're currently getting, but at an even better price of [$x] per month. How does that sound?`
      },
      {
        type: 'Same-floor',
        product: 'BB',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you.`
      },
      {
        type: 'Less-less',
        product: 'BB',
        script: `Based on what I'm hearing, it sounds like it might make sense for us to take a different approach here to find the lowest possible cost for you. How about if we were to set you up with a plan with slightly reduced speeds, ensuring we can continue to deliver quality broadband internet to you for an even lower price of [$x] per month?`
      }
    ]
  },
  {
    id: '7',
    product: 'Video',
    offers: [
      {
        type: 'Higher tier-little more',
        product: 'Video',
        script: `Based on what you told me about your usage, it probably makes sense for you to upgrade to a higher tier of video. You'll have more channels and a much improved viewing experience for just a slightly higher monthly cost. We can offer you [X tier] for [$X] per month. Does that work?`
      },
      {
        type: 'Higher tier-same',
        product: 'Video',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you. We can go ahead and offer you that same higher tier for a slightly lower price of [$x]. How does that sound?`
      },
      {
        type: 'Same tier-less',
        product: 'Video',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.`
      },
      {
        type: 'Youtube TV',
        product: 'Video',
        script: `I understand you are certain you'd like to cancel your Video plan. Have you considered YouTube TV? Many people who have recently decided to get rid of their Video plans have opted to sign up for YouTube TV. YouTube TV is a subscription streaming service that lets you watch live TV from 100+ cable, broadcast, and regional sports networks.`
      }
    ]
  },
  {
    id: '8',
    product: 'Video',
    offers: [
      {
        type: 'Higher tier-same',
        product: 'Video',
        script: `Based on what you told me about your usage, it probably makes sense for you to upgrade to a higher tier of video. You'll have more channels and a much improved viewing experience, and you'll be paying the exact same price you're paying now. We can offer you [X tier] for [$X] per month. Does that work?`
      },
      {
        type: 'Same tier-less',
        product: 'Video',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.`
      },
      {
        type: 'Same tier-floor',
        product: 'Video',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you.`
      },
      {
        type: 'Youtube TV',
        product: 'Video',
        script: `I understand you are certain you'd like to cancel your Video plan. Have you considered YouTube TV? Many people who have recently decided to get rid of their Video plans have opted to sign up for YouTube TV. YouTube TV is a subscription streaming service that lets you watch live TV from 100+ cable, broadcast, and regional sports networks.`
      }
    ]
  },
  {
    id: '9',
    product: 'Video',
    offers: [
      {
        type: 'Same tier-less',
        product: 'Video',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a solution that better fits your needs.`
      },
      {
        type: 'Same tier-floor',
        product: 'Video',
        script: `Let me see what I can do here. We greatly value your loyalty as a customer and it's our top priority to find a solution that works for you.`
      },
      {
        type: 'Youtube TV',
        product: 'Video',
        script: `I understand you are certain you'd like to cancel your Video plan. Have you considered YouTube TV? Many people who have recently decided to get rid of their Video plans have opted to sign up for YouTube TV. YouTube TV is a subscription streaming service that lets you watch live TV from 100+ cable, broadcast, and regional sports networks.`
      }
    ]
  },
  {
    id: '10',
    product: 'Voice',
    offers: [
      {
        type: '$5 discount',
        product: 'Voice',
        script: `For your Voice account, how would it sound if we're able to provide a discount of $5 per month? That comes out to [$x] per month. You'll get all the same benefits you're currently enjoying, now for a lower price.`
      },
      {
        type: '$10 discount',
        product: 'Voice',
        script: `I completely understand how important it is to you that we find a price that fits your needs. We are determined to find a customized solution for you. Given your loyalty to us as a customer, I can push it a little lower. We can continue to offer you the same Voice package you're enjoying now, but for an even greater discount of $10 per month. That comes out to [$x] per month.`
      }
    ]
  },
  {
    id: '11',
    product: 'BB Migration',
    offers: [
      {
        type: 'Migration-More-LotMore',
        product: 'BB',
        script: `I'm looking at your current plan, and based on what you told me about your usage, it sounds like you would really benefit from upgrading your speed to Fiber 1GB. You'll have much faster speeds and much more reliable internet. We can offer you a 1MB plan for [$X] per month. Does that work?`
      },
      {
        type: 'Migration-More-LittleMore',
        product: 'BB',
        script: `I completely understand how important it is to you that we find a price that fits your needs. We are determined to find a customized solution for you. Given your loyalty to us as a customer, I can push it a little lower. We can offer you that 1MB plan for [$X] per month. Does that work?`
      },
      {
        type: 'Migration-Same-Same',
        product: 'BB',
        script: `Let me take a look at see what we can do for you here. We will work with you to find an even better solution. We can go ahead and upgrade you to 500MB, which still represents a significant upgrade in speeds relative to your current plan, and we can offer that for the same price you're currently paying, [$x] per month. How does that sound?`
      },
      {
        type: 'Migration-Same-Less',
        product: 'BB',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a better solution for you.

        We can offer you a plan that includes the 500MB speeds, but for a lower price of [$x] per month. Does that work for you?`
      }
    ]
  },
  {
    id: '12',
    product: 'BB Migration',
    offers: [
      {
        type: 'Migration-More-LittleMore',
        product: 'BB',
        script: `I'm looking at your current plan, and based on what you told me about your usage, it sounds like you would really benefit from upgrading your speed to Fiber 1GB. You'll have much faster speeds and much more reliable internet. We can offer you a 1MB plan for [$X] per month. Does that work?`
      },
      {
        type: 'Migration-Same-Same',
        product: 'BB',
        script: `Let me take a look at see what we can do for you here. We will work with you to find an even better solution. We can go ahead and upgrade you to 500MB, which still represents a significant upgrade in speeds relative to your current plan, and we can offer that for the same price you're currently paying, [$x] per month. How does that sound?`
      },
      {
        type: 'Migration-Same-Less',
        product: 'BB',
        script: `I completely understand your desire to have a lower monthly payment. Let me take a moment to find a better solution for you.

      We can offer you a plan that includes the 500MB speeds, but for a lower price of [$x] per month. Does that work for you?`
      },
      {
        type: 'Migration-Same-Lesser',
        product: 'BB',
        script: `I am personally determined to make sure we can find a price that works for you. Given your loyalty to us a customer, we can push the price down a little further.

        How does it sound if we offer you the 500MB speeds, but for an even lower price of [$x] per month. Does that work for you?`
      }
    ]
  }
  // {
  //   id: '13',
  //   product: 'VAS',
  //   offers: [
  //     {
  //       type: 'Add Whole Home WiFi',
  //       script: ''
  //     }
  //   ]
  // },
  // {
  //   id: '14',
  //   product: 'VAS',
  //   offers: [
  //     {
  //       type: 'Add Security VAS',
  //       script: ''
  //     }
  //   ]
  // },
  // {
  //   id: '15',
  //   product: 'VAS',
  //   offers: [
  //     {
  //       type: 'Add My Premium Tech Pro',
  //       script: ''
  //     }
  //   ]
  // },
  // {
  //   id: '16',
  //   product: 'VAS',
  //   offers: [
  //     {
  //       type: 'Remove All VAS',
  //       script: `Based on what I'm hearing, it sounds like a key priority for you is finding the lowest possible price for our services. One additional way we can help you do that is by removing monthly charges for non-core, value-added services. For example, you're currently paying [$x] a month for [VAS]. Would you like for me to go ahead and remove that service from your account?`
  //     }
  //   ]
  // }
]
