import React, { CSSProperties } from 'react'
import WarningIcon from '@mui/icons-material/Warning'

export interface LegacyTagProps {
  title: string
}

export const LegacyTag = (props: LegacyTagProps) => {
  const style: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'red',
    padding: '5px 10px',
    borderRadius: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    border: '1px solid red',
    maxHeight: '40px',
    maxWidth: '100%',
    width: 'fit-content'
  }

  return (
    <div style={style}>
      <WarningIcon
        style={{ marginRight: '5px', color: 'red', fontSize: '20px' }}
      />{' '}
      {props.title}
    </div>
  )
}
