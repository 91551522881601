import { type IAPIParams } from '../types'
import ApiRequest from '../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IProduct, IProductState } from '../types/Product'

const basePath = '/product/'

export class ProductAPI {
  public list = async (
    params?: IAPIParams,
    signal?: AbortSignal | null
  ): Promise<IProduct[]> => {
    const result = await ApiRequest.get<IProduct[]>(basePath + 'list/', signal)
    return result.body
  }
}

const productAPI = new ProductAPI()

export const fetchProducts = createAsyncThunk(
  'product/fetchProducts',
  async (): Promise<IProduct[]> => {
    return await productAPI.list()
  }
)

const initialState: IProductState = {
  products: [],
  loading: false,
  error: null
}

const productAPISlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state: IProductState) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchProducts.fulfilled, (state: IProductState, action) => {
        state.loading = false
        state.error = null
        state.products = action.payload
      })
      .addCase(fetchProducts.rejected, (state: IProductState, action) => {
        state.loading = false
        state.error = action.payload as string
        state.products = []
      })
  }
})

export default productAPISlice
