import React from 'react'
import { defaultFont } from '../../components/ScriptBlock'
import { Box, List, ListItem } from '@mui/material'

interface InstructionsRowProps {
  generalInstruction?: string
  bulletInstructions: string[]
}

export const InstructionsRow = ({
  generalInstruction,
  bulletInstructions
}: InstructionsRowProps) => {
  return (
    <Box
      sx={{
        ...defaultFont,
        background: '#F8F8F8',
        padding: '10px',
        border: '1px solid #D8D8D8',
        textTransform: 'none',
        fontSize: '13px',
        fontFamily: 'Open Sans'
      }}
    >
      <Box sx={{ width: '77%' }}>
        <Box sx={{ fontWeight: 'bold' }}>{generalInstruction}</Box>
        <Box>
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            {bulletInstructions.map((instruction, idx) => (
              <ListItem sx={{ display: 'list-item', p: 0, pl: 1 }} key={idx}>
                {instruction}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  )
}
