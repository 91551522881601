import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICustomerCoreService,
  ICustomerCoreServiceState
} from '../../types/customer/CustomerCoreService'
import { Repository } from 'utils/enums'

const basePath = '/customer-core-service/'

export interface GetCustomerCoreServiceParams extends IAPIParams {
  account_id: string
  repository?: Repository
}

export class CustomerCoreServiceAPI {
  public get = async (
    params: GetCustomerCoreServiceParams,
    signal?: AbortSignal | null
  ): Promise<ICustomerCoreService> => {
    const result = await ApiRequest.get<ICustomerCoreService>(
      basePath + params.account_id + '/',
      signal,
      params
    )
    return result.body
  }
}

const customerCoreServiceAPI = new CustomerCoreServiceAPI()

export const getCustomerCoreService = createAsyncThunk(
  'getCustomerCoreService',
  async (
    params: GetCustomerCoreServiceParams
  ): Promise<ICustomerCoreService> => {
    return await customerCoreServiceAPI.get(params)
  }
)

const initialState: ICustomerCoreServiceState = {
  core_services: null,
  loading: false,
  error: null,
  no_core_services: false
}

export const customerCoreServiceAPISlice = createSlice({
  name: 'customer_core_service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCustomerCoreService.pending,
        (state: ICustomerCoreServiceState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        getCustomerCoreService.fulfilled,
        (state: ICustomerCoreServiceState, action) => {
          state.loading = false
          state.error = null
          state.core_services = action.payload

          state.no_core_services =
            !state.core_services.has_data &&
            !state.core_services.has_video &&
            !state.core_services.has_voice
        }
      )
      .addCase(
        getCustomerCoreService.rejected,
        (state: ICustomerCoreServiceState) => {
          state.loading = false
          state.error = 'Error fetching customer core services.'
          state.core_services = null
          state.no_core_services = true
        }
      )
  }
})

export default customerCoreServiceAPISlice
