import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Column, Row } from 'ui'
import ControlledAccordion from './CollapsableSection'
import ControlledAccordionOffer from './CollapsableSectionOffer'
import { useTranslation } from 'react-i18next'
import {
  CallFlowSection,
  closeAllSections,
  openAvailableSections
} from '../../../store/callflow/collapsableSections'
import { IntroductionSection } from './sections/Introduction'
import { ClosingRemarksSection } from './sections/ClosingRemarks'
import { useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from '../../../store/ConfigureStore'
import { Paper, Snackbar } from '@mui/material'
import { ContextSetting } from './sections/ContextSetting'
import { Actions } from './sections/Actions/Actions'
import { closeErrorSnackBar } from '../../../store/callflow/validation/validation'
import { fetchOffers } from 'buddy-api/api/OfferAPI'
import {
  buildOfferString,
  updateAgentCallSessionOffers,
  updateWinbackOffers
} from 'store/callsession/callsessionSlice'
import { useIsInactiveCustomer } from 'utils/useIsDisconnectedCustomer'
import { OffersSection } from './sections/Offers/Offers'
import SessionOutcomePanel from '../CallSessionOutcomePanel'

export const CallFlow: React.FC<{
  setShowSummary: Dispatch<SetStateAction<boolean>>
  showSummary: boolean
}> = () => {
  const { t } = useTranslation('callFlow')

  const theme = useTheme()
  const dispatch = useAppDispatch()

  const {
    canPerformCall,
    no_core_services,
    root_cause,
    customer,
    offers,
    selectedDoesFrontierOfferService,
    winbackSpeed,
    plan,
    device,
    data_usage,
    lines
  } = useAppSelector((state) => ({
    canPerformCall: state.agentCallSession.sessionCanBePerformed,
    no_core_services: state.customerCoreServiceAPI.no_core_services,
    root_cause: state.agentCallSession.call_session_root_cause,
    customer: state.agentCallSession.customer,
    offers: state.offersAPI.offers,
    selectedDoesFrontierOfferService:
      state.agentCallSession.selected_does_frontier_offer_service,
    winbackSpeed: state.agentCallSession.selectedWinbackSpeed,
    plan: state.agentCallSession.plan,
    device: state.agentCallSession.device,
    data_usage: state.agentCallSession.data_usage,
    lines: state.agentCallSession.lines
  }))

  const { errorSnackbarOpen, snackBarMessage } = useAppSelector(
    (state) => state.callFlowValidation
  )

  const isInactiveCustomer = useIsInactiveCustomer()

  const displayIntroduction = !isInactiveCustomer
  const displayActions = !isInactiveCustomer

  const flowDisabled = () => {
    if (useIsInactiveCustomer()) return false

    return (
      no_core_services ||
      !canPerformCall ||
      selectedDoesFrontierOfferService?.name ===
        'customer_refused_to_share_address'
    )
  }

  const canFetchOffersInactiveCustomer = () => {
    return Boolean(customer) && Boolean(winbackSpeed?.id)
  }

  const fetchOffersInactiveCustomer = () => {
    if (canFetchOffersInactiveCustomer() && customer) {
      dispatch(
        fetchOffers({
          inactive_account: true,
          winback_speed_id: winbackSpeed?.id,
          account_id: customer.account_id,
          account_number: customer.account_number
        })
      )
    }
  }

  const fetchOffersGeneral = () => {
    const canFetchOffers = plan && (device || data_usage || lines)
    if (!root_cause || !canFetchOffers) return

    const offerCombo = buildOfferString(
      root_cause.name,
      plan,
      device,
      data_usage,
      lines
    )
    dispatch(
      fetchOffers({
        offer_combo: offerCombo,
        root_cause_name: root_cause?.name
        // account_id: customer.account_id,
        // account_number: customer.account_number
        // callsession_intent_id: call_session_intent?.id,
        // root_cause_id: root_cause?.id,
        // dropped_product_id: dropped_product?.id,
        // new_tech_option_id: new_tech?.id,
        // customer_move_type_id: customer_move_type?.id
      })
    )
  }

  // Update offers on callsessionSlice store
  useEffect(() => {
    if (isInactiveCustomer) {
      fetchOffersInactiveCustomer()
    } else {
      fetchOffersGeneral()
    }
  }, [
    root_cause,
    plan,
    device,
    data_usage,
    lines
    // call_session_intent,
    // dropped_product,
    // customer_move_type,
    // new_tech,
    // winbackSpeed
  ])

  useEffect(() => {
    if (isInactiveCustomer) {
      dispatch(updateWinbackOffers(offers))
    } else {
      dispatch(updateAgentCallSessionOffers(offers))
    }
  }, [offers])

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        padding: '16px',
        margin: '10px',
        height: 'auto'
      }}
    >
      <Row
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
          alignItems: 'center'
        }}
      >
        <Column sx={{ flex: 1, fontSize: 16 }}>
          <strong>{t('callFlowTitle')}</strong>
        </Column>
        <Column sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          <div style={{ textAlign: 'right' }}>
            <button
              style={{
                cursor: 'pointer',
                color: theme.palette.primary.main,
                background: 'none',
                border: 'none'
              }}
              onClick={() => dispatch(openAvailableSections())}
            >
              {t('expand')}
            </button>
            <span> | </span>
            <button
              style={{
                cursor: 'pointer',
                color: theme.palette.primary.main,
                background: 'none',
                border: 'none'
              }}
              onClick={() => dispatch(closeAllSections())}
            >
              {t('collapse')}
            </button>
          </div>
        </Column>
      </Row>
      {/* INTRODUCTION SECTION - displayed only for active customers */}
      {displayIntroduction && (
        <Row sx={{ padding: '4px 10px' }}>
          <ControlledAccordion
            title={t('introduction')}
            section={CallFlowSection.INTRODUCTION}
          >
            <IntroductionSection />
          </ControlledAccordion>
        </Row>
      )}

      {/* CONTEXT SETTING SECTION */}
      <Row sx={{ padding: '4px 10px' }}>
        <ControlledAccordion
          title={t('contextSetting')}
          section={CallFlowSection.CONTEXT_SETTING}
          disabled={
            (no_core_services || !canPerformCall) && !useIsInactiveCustomer()
          }
        >
          <ContextSetting />
        </ControlledAccordion>
      </Row>

      {/* OFFERS SECTION - displayed only for active customers  */}
      <Row sx={{ padding: '4px 10px' }}>
        <ControlledAccordionOffer
          title={t('offers')}
          section={CallFlowSection.OFFERS}
          disabled={flowDisabled()}
        >
          <OffersSection />
        </ControlledAccordionOffer>
      </Row>

      {/* ACTIONS SECTION - displayed only for active customers */}
      {displayActions && (
        <Row sx={{ padding: '4px 10px' }}>
          <ControlledAccordion
            title={t('actions')}
            section={CallFlowSection.ACTIONS}
            disabled={flowDisabled()}
          >
            <Actions />
          </ControlledAccordion>
        </Row>
      )}

      {/* SESSION OUTCOME SECTION */}
      <Row sx={{ padding: '4px 10px' }}>
        <ControlledAccordion
          title={t('sessionOutcome')}
          section={CallFlowSection.SESSION_OUTCOME}
          disabled={flowDisabled()}
        >
          <SessionOutcomePanel />
        </ControlledAccordion>
      </Row>

      {/* CLOSING REMARKS SECTION */}
      <Row sx={{ padding: '4px 10px' }}>
        <ControlledAccordion
          title={t('closingRemarks')}
          section={CallFlowSection.CLOSING_REMARKS}
          disabled={flowDisabled()}
        >
          <ClosingRemarksSection />
        </ControlledAccordion>
      </Row>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        autoHideDuration={10000}
        message={<span style={{ color: 'white' }}>{t(snackBarMessage)}</span>}
        open={errorSnackbarOpen}
        onClose={() => {
          dispatch(closeErrorSnackBar())
        }}
        sx={{
          minWidth: '50%',
          '& .MuiSnackbarContent-root': {
            fontSize: '1.2em',
            backgroundColor: '#f00',
            padding: '20px'
          }
        }}
      />
    </Paper>
  )
}
