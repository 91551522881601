import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAppDispatch, useAppSelector } from '../../../store/ConfigureStore'
import {
  CallFlowSection,
  completeSection,
  openSection
} from '../../../store/callflow/collapsableSections'
import { openSectionIfValidated } from '../../../store/callflow/validation/validation'
import { Column, Row } from '../../../ui'
import { LegacyTag } from '../../../components/CustomerTopBar/CustomerInformation/AccountItems/LegacyTag'
import { useShowLegacyProductWarning } from './useShowLegacyProductWarning'
import { SkippedDialog } from './sections/Offers/SkippedDialog'
import { useCanOpenActions } from './sections/Offers/useCanOpenActions'

export type CollapsableSectionProps = {
  section: CallFlowSection
  title: string
  children?: React.ReactNode
  disabled?: boolean
}

export const ControlledAccordion: React.FC<CollapsableSectionProps> = (
  props: CollapsableSectionProps
) => {
  const [displayDialog, setDisplayDialog] = useState(false)

  const dispatch = useAppDispatch()
  const { openSections } = useAppSelector((state) => state.collapsableSections)
  const expanded = openSections[props.section].open

  const showLegacyProductWarning = useShowLegacyProductWarning()
  const canOpenOffer = useCanOpenActions()

  const onYes = () => {
    setDisplayDialog(false)
    dispatch(openSectionIfValidated(props.section))
  }

  const onNo = () => {
    setDisplayDialog(false)
  }

  useEffect(() => {
    if (expanded) {
      if (props.section === CallFlowSection.ACTIONS) {
        dispatch(completeSection(CallFlowSection.ACTIONS))
        dispatch(openSection(CallFlowSection.SESSION_OUTCOME))
      }
      if (props.section === CallFlowSection.CLOSING_REMARKS) {
        dispatch(completeSection(CallFlowSection.CLOSING_REMARKS))
      }
      if (props.section === CallFlowSection.INTRODUCTION) {
        dispatch(openSection(CallFlowSection.INTRODUCTION))
      }
    }
  }, [expanded])

  const handleChange = () => {
    if (
      !expanded &&
      !canOpenOffer &&
      props.section === CallFlowSection.ACTIONS
    ) {
      setDisplayDialog(true)
    } else {
      dispatch(openSectionIfValidated(props.section))
    }
  }

  return (
    <>
      <Accordion
        disabled={props.disabled}
        expanded={expanded && !props.disabled}
        onChange={handleChange}
        sx={{
          width: '100%',
          boxShadow: 'none'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${props.section}-content`}
          id={`${props.section}-header`}
          sx={{
            backgroundColor: '#F8F8F8'
          }}
        >
          <Row
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Column
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'left'
              }}
            >
              <Typography>{props.title}</Typography>
            </Column>
            <Column
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100px'
              }}
            >
              {showLegacyProductWarning &&
              props.section === CallFlowSection.ACTIONS ? (
                <LegacyTag
                  title={
                    'Due to legacy products and pricing, some actions might not be best suited for this customer.'
                  }
                />
              ) : null}
            </Column>
          </Row>
        </AccordionSummary>
        <AccordionDetails sx={{ background: 'white' }}>
          {props.children}
        </AccordionDetails>
      </Accordion>
      <SkippedDialog open={displayDialog} onYes={onYes} onNo={onNo} />
    </>
  )
}

export default ControlledAccordion
