import { type IAPIParams } from '../types'
import ApiRequest from '../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IBattleCard, IBattleCardState } from '../types/BattleCard'

const basePath = '/battle-card/'

export interface BattleCardParams extends IAPIParams {
  state_code?: string
  competitor_id: number
}

export class BattleCardAPI {
  public get = async (
    params: BattleCardParams,
    signal?: AbortSignal | null
  ): Promise<IBattleCard> => {
    const result = await ApiRequest.get<IBattleCard>(basePath, signal, params)
    return result.body
  }
}

const battleCardAPI = new BattleCardAPI()

export const getBattleCard = createAsyncThunk(
  'battleCard/getBattleCard',
  async (params: BattleCardParams): Promise<IBattleCard> => {
    return await battleCardAPI.get(params)
  }
)

const initialState: IBattleCardState = {
  battleCard: null,
  loading: false,
  error: null
}

const battleCardApiSlice = createSlice({
  name: 'battleCard',
  initialState,
  reducers: {
    resetBattleCard: (state: IBattleCardState) => {
      state.battleCard = null
      state.loading = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBattleCard.pending, (state: IBattleCardState) => {
        state.loading = true
        state.error = null
      })
      .addCase(getBattleCard.fulfilled, (state: IBattleCardState, action) => {
        state.loading = false
        state.error = null
        state.battleCard = action.payload
      })
      .addCase(getBattleCard.rejected, (state: IBattleCardState, action) => {
        state.loading = false
        state.error = action.payload as string
        state.battleCard = null
      })
  }
})

export const { resetBattleCard } = battleCardApiSlice.actions
export default battleCardApiSlice
