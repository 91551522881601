import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICallSessionUsageQuestionPerCategory,
  ICallSessionUsageQuestionPerCategoryState
} from '../../types/callsession/CallSessionUsageQuestions'

const basePath = '/callsession-usage-question/'

export interface CallSessionUsageQuestionPerCategoryParams extends IAPIParams {
  product_id: number
}

export class CallSessionUsageQuestionPerCategoryAPI {
  public list = async (
    params?: CallSessionUsageQuestionPerCategoryParams,
    signal?: AbortSignal | null
  ): Promise<ICallSessionUsageQuestionPerCategory[]> => {
    const result = await ApiRequest.get<ICallSessionUsageQuestionPerCategory[]>(
      basePath + 'list-per-category/',
      signal,
      params
    )
    return result.body
  }
}

const callSessionUsageQuestionPerCategoryAPI =
  new CallSessionUsageQuestionPerCategoryAPI()

export const fetchCallSessionUsageQuestionPerCategory = createAsyncThunk(
  'callSessionUsageQuestionPerCategory/fetchCallSessionUsageQuestionPerCategory',
  async (
    params: CallSessionUsageQuestionPerCategoryParams
  ): Promise<ICallSessionUsageQuestionPerCategory[]> => {
    return await callSessionUsageQuestionPerCategoryAPI.list(params)
  }
)

const initialState: ICallSessionUsageQuestionPerCategoryState = {
  usage_question_per_category: [],
  loading: false,
  error: null
}

const callSessionUsageQuestionPerCategoryAPISlice = createSlice({
  name: 'callsessionUsageQuestionPerCategory',
  initialState,
  reducers: {
    resetCallSessionUsageQuestionPerCategory: (
      state: ICallSessionUsageQuestionPerCategoryState
    ) => {
      state.usage_question_per_category = []
      state.loading = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCallSessionUsageQuestionPerCategory.pending,
        (state: ICallSessionUsageQuestionPerCategoryState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        fetchCallSessionUsageQuestionPerCategory.fulfilled,
        (state: ICallSessionUsageQuestionPerCategoryState, action) => {
          state.loading = false
          state.error = null
          state.usage_question_per_category = action.payload
        }
      )
      .addCase(
        fetchCallSessionUsageQuestionPerCategory.rejected,
        (state: ICallSessionUsageQuestionPerCategoryState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.usage_question_per_category = []
        }
      )
  }
})

export const { resetCallSessionUsageQuestionPerCategory } =
  callSessionUsageQuestionPerCategoryAPISlice.actions
export default callSessionUsageQuestionPerCategoryAPISlice
