import React from 'react'
import {
  ContextContainerBox,
  ContextItemBox
} from './ContextSettingActiveCustomer'
import { Dropdown } from 'components/input/Dropdown'
import { InputRow } from 'components/input/InputRow'
import { useAppDispatch, useAppSelector } from 'store/ConfigureStore'
import { SelectChangeEvent } from '@mui/material'
import { updateWinbackSpeed } from 'store/callsession/callsessionSlice'

export const ContextSettingInactiveCustomer = () => {
  const dispatch = useAppDispatch()
  const speeds = useAppSelector(
    (state) => state.callSessionWinbackSpeedsAPI.speeds
  )
  const selectedSpeed = useAppSelector(
    (state) => state.agentCallSession.selectedWinbackSpeed
  )

  const handleSpeedChange = (event: SelectChangeEvent) => {
    const selectedSpeed = speeds.find(
      (speed) => speed.id === (event.target.value as unknown as number)
    )
    if (selectedSpeed) {
      dispatch(updateWinbackSpeed(selectedSpeed))
    }
  }

  return (
    <ContextContainerBox>
      <ContextItemBox>
        <ul>
          <li>
            We see that you were a customer with us, can I interest you in our
            latest offer. We will see if you&apos;re eligible for our new
            customer pricing.
          </li>
        </ul>
        <strong>
          If customer is interested, check customer address through Verizon web
          and/or Hub
        </strong>
        <InputRow
          label='is Verizon available in Customer address'
          required={true}
          inputWidth='300px'
          inputElem={
            <Dropdown
              id='frontier-available-winback'
              value={selectedSpeed?.id.toString()}
              items={speeds}
              onChange={handleSpeedChange}
            />
          }
        ></InputRow>
      </ContextItemBox>
    </ContextContainerBox>
  )
}
