import { type IAPIParams } from '../../types'
import ApiRequest from '../../ApiRequest'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ICallSessionIntent,
  ICallSessionIntentState
} from '../../types/callsession/CallSessionIntent'

const basePath = '/callsession-intent/'

export class CallSessionIntentAPI {
  public list = async (
    params?: IAPIParams,
    signal?: AbortSignal | null
  ): Promise<ICallSessionIntent[]> => {
    const result = await ApiRequest.get<ICallSessionIntent[]>(
      basePath + 'list/',
      signal
    )
    return result.body
  }
}

const callSessionIntentAPI = new CallSessionIntentAPI()

export const fetchCallSessionIntents = createAsyncThunk(
  'callSessionIntent/fetchCallSessionIntents',
  async (): Promise<ICallSessionIntent[]> => {
    return await callSessionIntentAPI.list()
  }
)

const initialState: ICallSessionIntentState = {
  callSessionIntents: [],
  loading: false,
  error: null
}

const callSessionIntentsAPISlice = createSlice({
  name: 'callsessionIntent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCallSessionIntents.pending,
        (state: ICallSessionIntentState) => {
          state.loading = true
          state.error = null
        }
      )
      .addCase(
        fetchCallSessionIntents.fulfilled,
        (state: ICallSessionIntentState, action) => {
          state.loading = false
          state.error = null
          state.callSessionIntents = action.payload
        }
      )
      .addCase(
        fetchCallSessionIntents.rejected,
        (state: ICallSessionIntentState, action) => {
          state.loading = false
          state.error = action.payload as string
          state.callSessionIntents = []
        }
      )
  }
})

export default callSessionIntentsAPISlice
