import React from 'react'
import { Navigate } from 'react-router-dom'

export interface ProtectedRouteProps {
  isAuthenticated: boolean
  authenticationPath: string
  outlet: JSX.Element
}

const ProtectedRoute = ({
  isAuthenticated,
  authenticationPath,
  outlet
}: ProtectedRouteProps): React.ReactElement => {
  if (isAuthenticated) {
    return outlet
  }
  return (
    <Navigate
      to={{
        pathname: authenticationPath
      }}
    />
  )
}

export default ProtectedRoute
