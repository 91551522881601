import { IOffer, IProduct as IProductType } from 'buddy-api/types/Offer'
import { ICallSessionOffer } from 'buddy-api/types/callsession/CallSessionOffer'
import { roundPrice } from './utils'
import { IProduct } from 'buddy-api/types/Product'

const convertToCallSessionOffer = (offers: IOffer[]): ICallSessionOffer[] => {
  return offers.map((offer: IOffer) => {
    return {
      ...offer,
      offer_amount: roundPrice(offer.offer_amount),
      notOffered: false,
      accepted: false,
      rejected: false,
      display: true,
      expanded: false
    }
  })
}

const filterOffersByProduct = (
  offers: ICallSessionOffer[],
  product: string
): ICallSessionOffer[] => {
  return offers.filter((offer) => offer.product === product)
}

const getDropOfferId = (product: IProductType) => {
  if (product === 'BB') return 1001
  if (product === 'Video') return 1002
  if (product === 'Voice') return 1003

  return 1000
}

const getDropOffertitle = (product: IProductType) => {
  if (product === 'BB') return 'Drop broadband?'
  if (product === 'Video') return 'Drop Video?'
  if (product === 'Voice') return 'Drop Voice?'

  return 'Drop product?'
}

const getDropOffer = (
  product: IProductType,
  offers: ICallSessionOffer[]
): ICallSessionOffer => {
  return {
    id: getDropOfferId(product),
    title: getDropOffertitle(product),
    script: '',
    offer_amount: 0,
    product: product,
    name: `drop-${product}`,
    accepted: false,
    rejected: false,
    notOffered: false,
    type: 'drop',
    display: offers.length === 0, // if drop offer has no drop offers, set display as true
    expanded: false,
    hasNoOffers: offers.length === 0
  }
}

const addDropOffers = (
  offers: ICallSessionOffer[],
  droppedProduct: IProduct
) => {
  let retval: ICallSessionOffer[] = []

  if (droppedProduct.video) {
    const videoOffers = filterOffersByProduct(offers, 'Video')
    retval = retval.concat(videoOffers)
    retval.push(getDropOffer('Video', videoOffers))
  }

  if (droppedProduct.voice) {
    const voiceOffers = filterOffersByProduct(offers, 'Voice')
    retval = retval.concat(voiceOffers)
    retval.push(getDropOffer('Voice', voiceOffers))
  }

  if (droppedProduct.data) {
    const bbOffers = filterOffersByProduct(offers, 'BB')
    retval = retval.concat(bbOffers)
    retval.push(getDropOffer('BB', bbOffers))
  }

  return retval
}

const addOfferNumber = (offers: ICallSessionOffer[]) => {
  let offerNo = 1
  for (const offer of offers) {
    if (offer.type !== 'drop' || (offer.type === 'drop' && offer.hasNoOffers)) {
      // Drop offers without any offers avaiable to selected should be numbered
      // and displayed on offer ladder as "regular" offers
      offer.offerNo = offerNo
      offerNo += 1
    }
  }

  return offers
}

const orderOffers = (offers: ICallSessionOffer[]) => {
  let retval: ICallSessionOffer[] = []
  retval = retval.concat(filterOffersByProduct(offers, 'Video'))
  retval = retval.concat(filterOffersByProduct(offers, 'Voice'))
  retval = retval.concat(filterOffersByProduct(offers, 'BB'))

  return retval
}

export const buildCallSessionOffers = (
  offers: IOffer[],
  droppedProduct: IProduct
): ICallSessionOffer[] => {
  let callSessionOffers = convertToCallSessionOffer(offers)
  callSessionOffers = orderOffers(callSessionOffers)
  callSessionOffers = addDropOffers(callSessionOffers, droppedProduct)
  callSessionOffers = addOfferNumber(callSessionOffers)

  if (callSessionOffers.length > 0) {
    callSessionOffers[0].expanded = true
  }

  return callSessionOffers
}
